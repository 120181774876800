import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Users } from '../../models';
import { Auth } from 'aws-amplify';

export default function Managers() {
	const [values, setValues] = useState();
	// Grab the values from the form.
	const handle = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	useEffect(() => {
		directManager();
	}, []);

	const directManager = async () => {
		const authenticatedUser = await Auth.currentAuthenticatedUser();

		const userEmail = authenticatedUser.attributes.email;
		const currentUser = await DataStore.query(Users, (c) =>
			c.email('eq', userEmail)
		);

		try {
			if (currentUser[0].title === 'Managers')
				return (window.location.href = 'Manager-EmployeeView');
		} catch (error) {
			alert('error signing in', error);
		}
	};

	// Grabs the email from the form and queries the user from the model and redirects them to the managers portal.
	const signInButton = async (e) => {
		e.preventDefault();

		let username = e.target.elements.email?.value;
		const currentUser = await DataStore.query(Users, (c) =>
			c.email('eq', username)
		);

		try {
			if (currentUser[0].title === 'Managers')
				return (window.location.href = 'Manager-Routes');
		} catch (error) {
			alert('error signing in', error);
		}
	};

	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 '>
				<div className='h-screen flex bg-gray-bg1'>
					<div className='w-full max-w-md m-auto bg-gray-100 rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
						<h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
							Log in to your account 🔐
						</h1>

						<form onSubmit={signInButton}>
							<div>
								<label htmlFor='email'>Email</label>
								<input
									type='email'
									name='username'
									className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
									id='email'
									placeholder='Your Email'
									onChange={handle}
								/>
							</div>
							<div>
								<label htmlFor='password'>Password</label>
								<input
									type='password'
									className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
									id='password'
									name='password'
									placeholder='Your Password'
									onChange={handle}
								/>
							</div>

							<div className='flex justify-center items-center mt-6 text-black'>
								<button
									className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
									type='submit'>
									Login
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
