import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import MissionCommand from "./mc2.png";
import Collapse from "react-bootstrap/Collapse";

export default function Mission() {
  const [logisticsDetails, setLogisticsDetails] = useState(false);

  return (
    <div className="md:-mt-36 text-black">
      <div className="mb-6 bg-white">
        <row>
          <div>
            <Image src={MissionCommand}></Image>
          </div>
          <div className="col-md-6 mx-auto pb-12">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900 mt-5">
                    Mission Command & Control
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    The Mission Command & Control (MC2) division provides
                    systems analysis, engineering, integration, and architecture
                    Subject Matter Expert (SME) support for the DoD. MC2 has
                    expertise supporting the DoD's process for developing,
                    testing, and deploying Command and Control (C2) and non-C2
                    systems. MC2 operates at the cutting edge of DoD's C2
                    programs using coordinated test events both in simulated and
                    live environments.
                  </p>
                  <p className="text-left text-base">
                    The System and Task Operational Assessment for Risk
                    Management (STORM) describes Cervello's methodology for
                    planning, executing, and reporting Operational Assessments
                    (OA) and/or Quick Reaction Tests (QRT). STORM's
                    comprehensive assessment approach is applicable to new
                    capabilities and Tactics, Techniques and Procedures (TTPs)
                    or any short duration assessment. This methodology leverages
                    an Exploratory Sequential Mixed Method with a Modified
                    Grounded Theory (MGT) approach to data collection, providing
                    additional rigor to qualitative assessment through
                    confirmatory quantitative data. The result is rapid,
                    affordable, and thorough operational assessment that reduces
                    risk to testing, acquisition, and deployment of new
                    capabilities and TTP.
                  </p>
                  <div className="pb-1">
                    <div
                      onClick={() => setLogisticsDetails(!logisticsDetails)}
                      aria-controls="example-collapse-text"
                      aria-expanded={logisticsDetails}
                      className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                    >
                      <div className="col-10 text-left text-white">
                        Core Capabilities
                      </div>
                      {!logisticsDetails ? (
                        <div className="col-2 text-right text-white">+</div>
                      ) : (
                        <div className="col-2 text-right text-white">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={logisticsDetails}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-black text-left"
                        >
                          <ul className=" list-disc text-base text-white">
                            <li>
                              Systems Analysis, Engineering Integration, and
                              Systems Architecture SMEs
                            </li>
                            <li>
                              Joint Interface Control Officer (JICO) / Tactical
                              Data Link SMEs
                            </li>
                            <li>
                              Interoperability at Strategic, Operational, and
                              Tactical Levels in support of DoD's Joint All
                              Domain Command and Control (JADC2) strategy and
                              capabilities
                            </li>
                            <li>
                              Experience facilitating and providing project
                              management supporting DoD organizations navigating
                              the DoD Development, Testing, and Joint
                              Interoperability Certification for C2 and non-C2
                              systems
                            </li>
                            <li>
                              Support DoD C2 programs with coordinating test
                              events both in the lab and live events to conduct
                              data collection in support of follow-on analysis
                            </li>
                            <li>
                              Cervello's patented test methodology facilitates
                              analysis that ensures the DoD is fielding the best
                              equipment, users have the requisite knowledge, and
                              Tactics, Techniques, and Procedures (TTP) are in
                              place to fight and win the Nation's battles
                            </li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </row>
      </div>
    </div>
  );
}
