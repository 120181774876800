import { React, useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Current, Future, Announcements, Users } from '../../models';
import { Col, Row, Container, Card, Table } from 'react-bootstrap/';
// import Alert from '../../Alert/Alert';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import checkUser from '../../Auth/auth';
import { Auth } from 'aws-amplify';

export default function InternalHome() {
	const [current, setCurrent] = useState([]);
	const [future, setFuture] = useState([]);
	const [announcement, setAnnouncement] = useState([]);
	const { data, isLoading, errorMessage } = useOpenWeather({
		key: `${process.env.REACT_APP_WEATHER_API}`,
		lat: '-25',
		lon: '135',
		lang: 'en',
		unit: 'metric',
	});

	useEffect(() => {
		const fetchUsers = async () => {
			const authUser = await Auth.currentAuthenticatedUser();

			const usersEmail = authUser.attributes.email;

			const currentUser = await DataStore.query(Users, (c) =>
				c.email('eq', usersEmail)
			);

			//! Code to filter out managers
			// const filteredUsers = (await DataStore.query(Users)).filter(
			// 	(c) =>
			// 		c.managersID === currentUser[0].managersID &&
			// 		c.email === currentUser[0].email
			// );

			// const filteredManagers = (await DataStore.query(Managers)).filter(
			// 	(c) => c.email === currentUser[0].email
			// );

			try {
				checkUser(currentUser);
			} catch (error) {
				
				return error.message;
			}
		};

		fetchUsers();
		fetchAnnouncements();
		getData();
		getFutureData();
	}, []);

	const fetchAnnouncements = async () => {
		const announcementsModels = await DataStore.query(Announcements);
		try {
			return setAnnouncement(announcementsModels);
		} catch (error) {
			
			return;
		}
	};

	const getData = async () => {
		const currentModels = await DataStore.query(Current);

		try {
			return setCurrent(currentModels);
		} catch (error) {
			
			return;
		}
	};

	const getFutureData = async () => {
		const futureModels = await DataStore.query(Future);

		try {
			return setFuture(futureModels);
		} catch (error) {
			
			return;
		}
	};

	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 '>
				<Container>
					<Row>
						{/* <Alert /> */}
						<Col>
							<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
								{announcement.length > 0 ? (
									announcement.map((x, i) => {
										return (
											<Card
												key={i}
												text='dark'
												className='mb-2'
												border='primary'
												style={{ width: '18rem' }}>
												<Card.Header>Announcements </Card.Header>
												<Card.Body>
													<Card.Title> {x.title} </Card.Title>
													<Card.Text className='text-center'>
														{x.description}
													</Card.Text>
												</Card.Body>
											</Card>
										);
									})
								) : (
									<p className='text-black'>{`${process.env.REACT_APP_DATABASE_ERROR}`}</p>
								)}
							</div>
						</Col>
						<Col>
							<div className='bg-white text-black rounded-sm border-black border-opacity-100 border-solid border-4'>
								<p className='text-center text-black'>Route Advisories</p>
								<p className='text-center text-black'>Weather Routes</p>

								<Col>
									<ReactWeather
										isLoading={isLoading}
										errorMessage={errorMessage}
										data={data}
										lang='en'
										locationLabel='Americas'
										unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
										showForecast
									/>
								</Col>
							</div>
						</Col>
						<Col>
							<div>
								<h1 className='text-center font-black text-3xl text-white'>
									Current Operations
								</h1>
								<form>
									<Table className='bg-white' striped bordered hover>
										<thead>
											<tr>
												<th>Driver</th>
												<th>Vehicle Number</th>
												<th>HAZMAT</th>
												<th>Load Description</th>
											</tr>
										</thead>
										<tbody>
											{current.length > 0 ? (
												current.map((x, i) => {
													return (
														<tr key={i}>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.driver}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.vehicle_number}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.hazmat}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.load_description}
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td className='border border-black border-opacity-100 border-solid'>
														{process.env.REACT_APP_DATABASE_ERROR}
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</form>

								<form>
									<h1 className='text-center font-black text-3xl text-white'>
										Future Operations
									</h1>
									<Table className='bg-white' striped bordered hover>
										<thead>
											<tr>
												<th>Date Scheduled(YEAR-MM-DATE)</th>
												<th>Date Due (YEAR-MM-DATE)</th>
												<th>Load Description</th>
											</tr>
										</thead>
										<tbody>
											{future.length > 0 ? (
												future.map((x, i) => {
													return (
														<tr key={i}>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.date_scheduled}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.date_due}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.load_description}
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td className='border border-black border-opacity-100 border-solid'>
														{process.env.REACT_APP_DATABASE_ERROR}
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
