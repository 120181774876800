import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import "./style.css";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import ContainerShip from "./container2.jpg";
import Aerial from "./aerial2.jpg";
import Missile from "./missile2.jpg";
import Soldier from "./soldier.jpg";
import VOSB from "./vosb.png";
import ITAR from "./itar.png";
import VIP from "./vipGrad.png";
import Marine from "./marine.png";
import NDTA from "./ndta.jpg";
import AUSA from "./ausa.png";
import NDIA from "./NDIA.jpg";

export default function Main() {
  return (
    <div className="mx-auto text-black">
      <Carousel fade>
        <Carousel.Item>
          <Link to="/Logistics">
            <img
              className="d-block w-100"
              src={ContainerShip}
              alt="First slide"
            />
            <Carousel.Caption>
              <div className="text-white lg:text-4xl text-left font-bold">
                Global Logistics
              </div>
              <div className="text-white lg:text-2xl text-left">
                Performance-based methodology. Visibility across the supply
                chain.
              </div>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
          <Link to="/Mission">
            <img className="d-block w-100" src={Aerial} alt="Second slide" />

            <Carousel.Caption>
              <div className="text-white lg:text-4xl text-left font-bold">
                Mission Command & Control
              </div>
              <div className="text-white lg:text-2xl text-left">
                Supporting Warfighters at the cutting edge of DoD's Command and
                Control programs.
              </div>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
          <Link to="/Future">
            <img className="d-block w-100" src={Soldier} alt="Third slide" />

            <Carousel.Caption>
              <div className="text-white lg:text-4xl text-left font-bold">
                Future Force Modernization
              </div>
              <div className="text-white lg:text-2xl text-left">
                Expertise for rapidly developing the future force.
              </div>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
          <Link to="/Hypersonics">
            <img className="d-block w-100" src={Missile} alt="Fourth Slide" />

            <Carousel.Caption>
              <div className="text-white lg:text-4xl text-left font-bold">
                Hypersonics & Emerging Technologies
              </div>
              <div className="text-white lg:text-2xl text-left">
                Warfighter-driven processes supporting hypersonic strike (HSW)
                decisions.
              </div>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      </Carousel>

      <div className="mb-6 content-center bg-white border-1 border-solid">
        <div className="col-8 mx-auto">
          <Row>
            <Col>
              <div className="text-center lg:text-5xl text-3xl uppercase blueText mt-5">
                Supporting the Future Force
              </div>
            </Col>
          </Row>
          <div className="mt-5 pb-5">
            <Row>
              <Col>
                <div className="text-left text-black text-base md:text-lg mt-2">
                  Cervello Global Corporation is a veteran-owned small business
                  with more than 15 years of experience developing a global
                  workforce of professionals committed to the operating forces.
                  More than 90 percent of Cervello's team are former military
                  leaders with expertise in specific strategic, operational, and
                  tactical operations.
                  <br /> <br />
                  Currently, we are registered to do business with employees in
                  16 states throughout the U.S.; branch offices in Singapore,
                  Japan, Guam, and Hawaii; wholly owned subsidiaries in
                  Australia, Germany, and Italy; and preparing to launch new
                  wholly owned subsidiaries in the United Kingdom, Poland,
                  Norway, Sweden, Finland, Belgium, Bahrain, and the
                  Philippines.
                  <br /> <br />
                  As a defense contractor, we keep our eye on the future and
                  remain in alignment with military efforts like “Force Design
                  2030,” which aims to re-design the Marine Corps to better
                  align with the National Defense Strategy and to build a force
                  that is “combat credible.”
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container mx-auto">
          <div className="row">
            <div className="mt-5 pb-5 grid grid-cols-1 gap-4 place-content-center md:grid md:grid-cols-7 md:gap-4">
              <div>
                <img
                  className="md:h-24 h-36 mx-auto"
                  alt="VOSB logo"
                  src={VOSB}
                />
              </div>
              <div>
                <img
                  className="md:h-24 h-36 mx-auto"
                  alt="ITAR logo"
                  src={ITAR}
                />
              </div>
              <div>
                <img
                  className="md:h-24 h-36 mx-auto"
                  alt="Marine Assoc logo"
                  src={Marine}
                />
              </div>{" "}
              <div>
                <img
                  className="md:h-24 h-36 mx-auto"
                  alt="AUSA logo"
                  src={AUSA}
                />
              </div>
              <div>
                <img
                  className="md:h-24 h-36 mx-auto"
                  alt="NDTA logo"
                  src={NDTA}
                />
              </div>{" "}
              <div>
                <img
                  className="md:h-24 h-36 mx-auto"
                  alt="VIP Grad logo"
                  src={VIP}
                />
              </div>
              <div>
                <img
                  className="md:h-24 h-36 mx-auto"
                  alt="NDIA logo"
                  src={NDIA}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto blueOne pt-12 pb-12 lg:pt-24 lg:pb-24">
          <div className="row mx-auto">
            <div className="col-lg-6 text-center pt-8 pb-2 lg:text-4xl text-2xl uppercase text-white font-bold">
              High Performance <br /> Mission Driven
            </div>

            <div className="col-lg-6 text-left text-white text-base lg:text-2xl">
              Cervello Global Corporation's mission is to exceed our customers'
              expectations by providing quality solution-focused consulting and
              technology engineering services; on time and within scope and
              budget. We are High Performance, Mission Driven.
            </div>
          </div>
        </div>

        <div className="col-8 mx-auto">
          <Row>
            <Col>
              <div className="text-left text-4xl uppercase blueText font-bold mt-5">
                Our Customers
              </div>
            </Col>
          </Row>
          <div className="mt-5 pb-5">
            <Row>
              <Col>
                <div className="text-left text-black text-base mt-2">
                  Cervello is currently providing or has provided valuable
                  contract support to the Defense Information Systems Agency
                  (DISA), Office Secretary of Defense Joint Staff (OSD), US Navy
                  Naval Air Systems Command (NAVAIR), US Central Command
                  (USCENTCOM), US European Command (USEUCOM), United States
                  Indo-Pacific Command (USINDOPACOM); US Special Operations
                  Command (USSOCOM), US Northern Command (USNORTHCOM), US
                  Strategic Command (USSTRATCOM), National Reconnaissance Office
                  (NRO), United States Marine Corps (USMC), and the Department
                  of Veterans Affairs (VA); Cervello and its leadership's
                  supports many stakeholders worldwide.
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="col-8 mx-auto">
          <div className="text-left text-4xl uppercase blueText font-bold mt-5">
            Our Values
          </div>

          <div className="mt-5 pb-5">
            <CardGroup>
              <Card className="lg:mx-4 border" bg="light">
                <Card.Header className="text-2xl text-blue-900">
                  Integrity
                </Card.Header>
                <Card.Body>
                  <Card.Text className="text-base">
                    Cervello adheres to high moral values and ethical
                    guidelines. We are committed to keeping our customers' best
                    interests at the forefront. We foster a culture of
                    professional conduct and mutual respect with our employees
                    and our customers.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="lg:mx-4 border" bg="light">
                <Card.Header className="text-2xl text-blue-900">
                  Innovation
                </Card.Header>
                <Card.Body>
                  <Card.Text className="text-base">
                    Cervello strives to keep pace with modernization efforts
                    across the DoD communities. We develop solutions in the
                    present to support our future force. Our goal is high
                    velocity performance and innovation.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="lg:mx-4 border" bg="light">
                <Card.Header className="text-2xl text-blue-900">
                  Service
                </Card.Header>
                <Card.Body>
                  <Card.Text className="text-base">
                    Cervello prides itself on being customer-focused. We
                    collaborate with our customers to understand their needs and
                    exceed their expectations. We build long-term, positive
                    relationships with each client.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardGroup>
          </div>
        </div>
      </div>
    </div>
  );
}
