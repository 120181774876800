import React from "react";
import CervelloHyper from "./Assets/cervello_hypersonic-coe.png";
import Precise from "./Assets/precisionstrikelogo.png";
import "./Hyper.css";
export default function HyperCOE() {
  return (
    <div>
      <h1 className="font-black text-3xl text-white">
        Hypersonics Center Of Excellence
      </h1>
      <div className="flex">
        <div className="flex-auto">
          <img className="logo px-10" src={CervelloHyper} alt="" />
        </div>
        <div className="flex-auto">
          <p className="text-center">
            The Cervello Hypersonics CoE is an ecosystem of interdependent
            contributors to a continuously evolving innovative Technology,
            Command Control (C2), and Concept of Operations (CONOPS) data
            repository for stakeholders responsible for a Globally Integrated
            Operations (GIO) mission set. Utilizing decades of experience in
            R&D, Experimentation (Live, Virtual, and Constructive) The Cervello
            models for a centralized Hypersonics CoE functions in several parts:
          </p>
          <ul>
            <li>
              Our support organization works with Academia and Industry to
              gather research data, establish facts, develop testable theories,
              instantiate theories into models, and publish findings for
              Academia and Industry use.
            </li>
            <li>
              Our support organization works with government and Industry using
              immense experience in the Test and Evaluation (T&E) community to
              help source technology, design and conduct experiments, support
              operational testing, support non-material testing of tactics,
              techniques, and procedures (TTP), and publish classified
              documentation for use by authorized personnel and related entities
            </li>
            <li>
              Our C2 experts possess the mastery of C2 fractal concepts, and
              provide continuous assessment of relevant threats, operating
              environments, technologies available, and a range of human factors
              in order to effectively support a Globally Integrated Operations
              (GIO) mission set.
            </li>
          </ul>
        </div>
        <div className="flex-auto ...">
          <img className=" logo px-10" src={Precise} alt="" />
        </div>
      </div>
    </div>
  );
}
