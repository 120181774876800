import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { DataStore } from "@aws-amplify/datastore";
import { Users, Managers } from "../models";
import { Auth } from "aws-amplify";

export default function NotificationAlert() {
  const [alertState, setAlertState] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const getUserName = async () => {
      const authorize = await Auth.currentAuthenticatedUser();
      const userEmail = authorize.attributes.email;
      const usersModel = await DataStore.query(Users, (c) =>
        c.email("eq", userEmail)
      );

      try {
        if (usersModel[0].title === "Managers") {
          const managersModel = await DataStore.query(Managers, (c) =>
            c.email("eq", userEmail)
          );
          setAlertState(managersModel[0].notifications.length);
        } else {
          setAlertState(0)
          return;}
      } catch (error) {
        // return;
        console.log(error)
        setAlertState(0)
        return
      }
    };
    getUserName();
  }, []);

  const goNotifications = (e) => {
    e.preventDefault();
    window.open(`/Internal-Notifications`);
  };

  if (show) {
    return (
      <Alert variant="primary" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          {alertState > 0 && alertState < 2 ? (
            <span onClick={goNotifications} style={{ cursor: "pointer" }}>
              {`You have ${alertState} new notification!`}
            </span>
          ) : alertState > 1 ? (
            <span onClick={goNotifications} style={{ cursor: "pointer" }}>
              {`You have ${alertState} new notifications!`}
            </span>
          ) : (
            <span onClick={goNotifications} style={{ cursor: "pointer" }}>
              No new notifications.
            </span>
          )}
        </Alert.Heading>
      </Alert>
    );
  }
  return null;
}
