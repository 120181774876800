import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import World from "./world.jpg";
import { Link } from "react-router-dom";

export default function CervelloGlobal() {
  const [australiaDetails, setAustraliaDetails] = useState(false);
  const [germanyDetails, setGermanyDetails] = useState(false);

  return (
    <div className="md:-mt-36 text-black">
      <div className="mb-6 bg-white">
        <row>
          <div>
            <Image src={World}></Image>
          </div>
          <div className="col-md-6 mx-auto">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900 mt-5">
                    Global
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    With subsidiaries in Germany and Australia, branch offices
                    in Japan and Singapore, and personnel stationed at United
                    States Marine Corps commands around the world, Cervello has
                    an undeniable global presence. As the primary provider of
                    global logistics management support to the USMC, Cervello
                    has successfully developed and deployed a global team of
                    professionals who possess the in-depth knowledge and
                    experience necessary to ensure our customer and its
                    stakeholders will continuously fulfill their role as a naval
                    expeditionary force-in-readiness, while being able to
                    simultaneously modernize their forces. Cervello's chief
                    objective is to enable our capabilities and control
                    contested areas with an agile, lethal, and resilient
                    fighting force. Cervello and its leadership's supports many
                    stakeholders worldwide.
                  </p>
                </Col>
              </Row>
              <div className="mt-5 pb-5">
                <Row>
                  <Col>
                    <div className="text-left text-2xl text-blue-900">
                      Americas
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col>
                    <p className="text-left text-black text-base">
                      Within the United States, Cervello is currently providing
                      or has provided valuable contract support to the Defense
                      Information Systems Agency (DISA), Office Secretary of
                      Defense Joint Staff (OSD), US Navy Naval Air Systems
                      Command (NAVAIR), US Central Command (USCENTCOM), US
                      European Command (USEUCOM); US Special Operations Command
                      (USSOCOM), US Northern Command (USNORTHCOM), US Strategic
                      Command (USSTRATCOM), National Reconnaissance Office
                      (NRO), United States Marine Corps (USMC), and the
                      Department of Veterans Affairs (VA).
                    </p>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <div className="text-left text-2xl text-blue-900 pt-5">
                      Indo-Pacific
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col>
                    <p className="text-left text-black text-base">
                      Cervello Global of Australia Pty Ltd is dedicated to
                      providing support to the Australian Government and Defence
                      Industry with logistic support to maintain Australia's
                      sovereign resilience. We provide a high standard of
                      communication and logistic support to our customers and
                      create cost-effect logistic solutions to meet our
                      customer's specific requirement in delivery. With the
                      latest global tracking capabilities, the customer has
                      visibility to track their consignment.
                    </p>
                    <div className="pb-1">
                      <div
                        onClick={() => setAustraliaDetails(!australiaDetails)}
                        aria-controls="example-collapse-text"
                        aria-expanded={australiaDetails}
                        className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                      >
                        <div className="col-10 text-left text-white">
                          Subsidiary Contact Information
                        </div>
                        {!australiaDetails ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse in={australiaDetails}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-black text-left"
                          >
                            <ul className="text-base text-white">
                              <li className="font-bold">
                                Cervello Global of Australia, Pty Ltd
                              </li>
                              <li>
                                <span className="font-bold">Director:</span>{" "}
                                Karen McMillan, kmcmillan@go-ct.com
                              </li>
                              <li>Level 14 440 Collins Street</li>
                              <li> Melbourne Vic 3000</li>
                              <li> Australia</li>
                              <br />
                              <li className="font-bold">Darwin Office:</li>
                              <li>
                                Serenity Views 404D 65 Progress Drive Nightcliff
                                NT 0810 Australia
                              </li>
                            </ul>
                            <br />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <div className="text-left text-2xl text-blue-900 pt-5">
                      Africa / Europe
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col>
                    <p className="text-left text-black text-base">
                      Cervello Global of Germany GmbH offers comprehensive
                      support and extensive experience to commercial and
                      government organizations across Europe and Africa. As a
                      subsidiary of Cervello Global Corporation, our services
                      include acquisition and logistics process improvements,
                      technology testing and evaluation, modeling and
                      simulation, and research, development, and human resource
                      management. Together with our global partners, we work
                      tirelessly to innovate, communicate, and deliver
                      cost-effective solutions to meet our customers' unique
                      logistics needs.
                    </p>
                    <div className="pb-1">
                      <div
                        onClick={() => setGermanyDetails(!germanyDetails)}
                        aria-controls="example-collapse-text"
                        aria-expanded={germanyDetails}
                        className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                      >
                        <div className="col-10 text-left text-white">
                          Subsidiary Contact Information
                        </div>
                        {!germanyDetails ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse className="pb-1" in={germanyDetails}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-black text-left"
                          >
                            <ul className="text-base text-white">
                              <li className="font-bold">
                                Cervello Global - Germany GmbH
                              </li>
                              <li>
                                <span className="font-bold">Director: </span>
                                John Gerlach, jgerlach@go-ct.com
                              </li>
                              <li>Alsterwiete 3</li>
                              <li>20099 Hamburg</li>
                              <li>Germany</li>
                              <br />
                              <li>
                                <span className="font-bold">NCAGE: </span>CNAH9
                              </li>
                              <br />
                              <Link className="text-white" to="Transportation">
                                Transportation Services
                              </Link>
                              <br />
                            </ul>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </row>
      </div>
    </div>
  );
}
