import React from 'react';
import WaltPic from './Walt Gartner - Edited.jpg';
import { Row, Col, Container, Card } from 'react-bootstrap/';
export default function Walter() {
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 md:h-auto '>
				<Container className='mt-10'>
					<Row>
						<Col>
							<Card className='mt-6' style={{ width: '18rem' }}>
								<Card.Img variant='top' src={WaltPic} />
								<Card.Body className='text-black'>
									<Card.Title>Walter Gartner</Card.Title>
									<Card.Text className='text-center'>
										Chief Information Officer/Vice President, Corporate Systems
										Development & National Security
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col xs={6} className='mr-24 pt-24'>
							<p className='text-xl text-black'>
								Walter Gartner is a Military and Intelligence Liaison
								Officer with over 34 years of experience working for the
								Department of Defense as a Design Engineer, U.S. Air Force
								Officer, and Signals Intelligence (SIGINT)
								Professional. Walter’s in-depth knowledge of SIGINT postured him
								to serve as NSA’s Signals Intelligence Directorate’s (SID)
								advocate for U.S. European Command, North Atlantic Treaty
								Organization, U.S. Joint Forces Command, and the Joint Warfare
								Analysis Center.
							</p>{' '}
							<p className='text-xl text-black'>
								Walter is an employee at Cervello and is serving as the Joint
								Interoperability through Data Centricity Joint Test Liaison to
								U.S. Central Command (USCENTCOM). In his role as Chief
								Information Officer/Vice President, Corporate Systems
								Development & National Security, Walter ensures that Cervello is
								at the height of cybersecurity.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
