import AboutUs from "../About/AboutUs";
import Excellence from "../CenterOfExcellence/Excellence";
import Contracts from "../Contracts/Contracts";
import Careers from "../Careers/Careers";
import Contact from "../Contact/Contact";
import Mahan from "../About/Mahan/Mahan";
import Valerie from "../About/Valerie/Valerie";
import Walter from "../About/Walter/Walter";
import Elline from "../About/Binner/Binner";
import Karen from "../About/Karen/Karen";
import Login from "../SignUp/SignUp";
import Boyd from "../About/Boyd/Boyd";
import Daniel from "../About/Daniel/Daniel";
import Ingram from "../About/Ingram/Ingram";
import Emil from "../About/Emil/Emil";
import Hunter from "../About/Hunter/Hunter";
import Jennifer from "../About/Jennifer/Jennifer";
import Aspiazu from "../About/Aspiazu/Aspiazu";
import Parker from "../About/Parker/Parker";
import MissionCommand from "../Capabilities/MissionCommand/MissionCommand";
import FutureForce from "../Capabilities/FutureForce/FutureForce";
import HyperCOE from "../CenterOfExcellence/HyperCOE/HyperCOE";
import GlobalCOE from "../CenterOfExcellence/GlobalCOE/GlobalCOE";
import SignIn from "../AWS-Sign-In/SignIn";
import InternalHome from "../InternalSite/InternalHome/InternalHome";
import Calculator from "../InternalSite/InternalCalculator/Calculator";
import Reference from "../InternalSite/Reference/Reference";
import Routing from "../InternalSite/Routing/Routing";
import Managers from "../InternalSite/Managers/Manager";
import Manager_Routes from "../InternalSite/Managers/Routes/Manager_Routes";
import Library from "../InternalSite/Managers/Library/Library";
import Customer_Home from "../CustomerSite/Home/Customer_Home";
import Customer_Capabilities from "../CustomerSite/Capabilities/Customer_Capabilities";
import Customer_Logistics from "../CustomerSite/Logistics/Customer_Logistics";
import Transit from "../CustomerSite/Transit_Calculator/Transit";
import NewPass from "../SignUp/NewPass";
import Announcement from "../InternalSite/Managers/Announcements/Announcements";
import Main from "../Main/Main";
import Thomas from "../About/Thomas/Thomas";
import Gerlach from "../About/Gerlach/Gerlach";
import Home from "../Home/Home";
import CervelloGlobal from "../Global/CervelloGlobal";
import DashboardRev from "../Dashboard/DashboardRev";
import Checklist from "../Dashboard/Checklist";
import ManagerEmployeeView from "../InternalSite/Managers/Employees/ManagerEmployeeView";
import ManagerChecklist from "../Dashboard/ManagerChecklist";
import AmericasTeam from "../Americas/AmericasTeam";
import Nikki from "../MiddleEast/Nikki/Nikki";
import Notifications from "../InternalSite/Notifications/Notifications";
import Logistics from "../CenterOfExcellence/Logistics";
import Mission from "../CenterOfExcellence/Mission";
import Future from "../CenterOfExcellence/Future";
import Hypersonics from "../CenterOfExcellence/Hypersonics";
import Success from "../Contact/Success";
import Leadership from "../Leadership/Leadership";
import NewsRoom from "../NewsRoom/NewsRoom";
import NewsPost from "../NewsRoom/NewsPost";
import NewsManage from "../NewsRoom/NewsManage";
import EditNews from "../NewsRoom/EditNews";
import DeleteNews from "../NewsRoom/DeleteNews";
import Distribution from "../CenterOfExcellence/Distribution";
import Transportation from "../Transportation/Transportation";
import Request from "../Transportation/Request";
import HR from "../HR/HR";
import Papers from "../Papers/Papers";
import WP1 from "../Papers/WP1";
import WP2 from "../Papers/WP2";
import CapabilityRequest from "../Papers/Capability-Request";
import HyperSonicsStatement from "../Papers/CapabilityPapers/HypersonicsCapability";
import MissionCommandStatement from "../Papers/CapabilityPapers/MissionCapability";
import FutureForceStatement from "../Papers/CapabilityPapers/FutureForceCapability";
import DistStatement from "../Papers/CapabilityPapers/DistCapability";
import LogisticStatement from "../Papers/CapabilityPapers/LogisticCapability";
import Construction from "../Construction/Construction";
import Tech from "../Tech/Tech";

const NavRoutes = [
  {
    component: Main,
    path: "/Home",
  },
  {
    component: Home,
    path: "/Logo",
  },
  {
    component: AboutUs,
    path: "/Company",
  },
  {
    component: Excellence,
    path: "/Capabilities",
  },
  {
    component: Logistics,
    path: "/Logistics",
  },
  {
    component: Mission,
    path: "/Mission",
  },
  {
    component: Future,
    path: "/Future",
  },
  {
    component: Hypersonics,
    path: "/Hypersonics",
  },
  {
    component: Contracts,
    path: "/Contracts",
  },
  {
    component: Careers,
    path: "/Careers",
  },
  { component: CervelloGlobal, path: "/Global" },
  {
    component: Contact,
    path: "/Contact",
  },
  { component: Success, path: "/Success" },
  { component: Leadership, path: "/Leadership" },
  { component: NewsRoom, path: "/NewsRoom" },
  { component: NewsPost, path: "/NewsPost/:slug" },
  { component: EditNews, path: "/NewsManage-Edit" },
  { component: DeleteNews, path: "/NewsManage-Delete" },
  { component: Distribution, path: "/Distribution" },
  { component: Transportation, path: "/Transportation" },
  { component: Request, path: "/Request" },
  { component: HR, path: "/Human-Resources" },
  { component: Papers, path: "/White-Papers" },
  { component: WP1, path: "/WP1" },
  { component: WP2, path: "/WP2" },
  { component: CapabilityRequest, path: "/Capability-Request" },
  { component: HyperSonicsStatement, path: "/HyperSonics-Statement" },
  { component: FutureForceStatement, path: "/Future-Force-Statement" },
  { component: MissionCommandStatement, path: "/Mission-Command-Statement" },
  { component: DistStatement, path: "/Distribution-Statement" },
  { component: LogisticStatement, path: "/Logistics-Statement" },
  { component: Construction, path: "/Construction" },
  { component: Tech, path: "/TechManagement" },
  { component: Aspiazu, path: "/Jorge-Aspiazu" },
  { component: Parker, path: "/Kim-Parker" },
  {
    component: SignIn,
    path: "/Login",
  },
  {
    component: Login,
    path: "/Manager-SignUp",
  },
  {
    component: Mahan,
    path: "/John-Mahan",
  },
  {
    component: Valerie,
    path: "/Valerie-Mahan",
  },
  {
    component: Walter,
    path: "/Walter",
  },
  {
    component: Elline,
    path: "/Elline-Binner",
  },
  {
    component: Karen,
    path: "/Karen-McMillan",
  },
  {
    component: Boyd,
    path: "/Boyd-McMurtrey",
  },
  {
    component: Daniel,
    path: "/Daniel-Grenier",
  },
  {
    component: Ingram,
    path: "/David-Ingram",
  },
  {
    component: Gerlach,
    path: "/John-Gerlach",
  },
  {
    component: Emil,
    path: "/Emil",
  },
  {
    component: Hunter,
    path: "/Hunter-Haltom",
  },
  {
    component: Jennifer,
    path: "/Jennifer-King",
  },
  {
    component: Thomas,
    path: "/Thomas-Desiderio",
  },
  {
    component: MissionCommand,
    path: "/MissionCommand",
  },
  {
    component: FutureForce,
    path: "/Future",
  },
  {
    component: HyperCOE,
    path: "/HyperCOE",
  },
  {
    component: GlobalCOE,
    path: "/GlobalCOE",
  },
  {
    component: SignIn,
    path: "/SignIn",
  },
  {
    component: InternalHome,
    path: "/Internal",
  },
  {
    component: Calculator,
    path: "/Internal-Calculator",
  },
  {
    component: Reference,
    path: "/Internal-Reference",
  },
  {
    component: Routing,
    path: "/Internal-Routes",
  },
  {
    component: Managers,
    path: "/Internal-Managers",
  },
  {
    component: Manager_Routes,
    path: "/Manager-Routes",
  },
  {
    component: Library,
    path: "/Manager-Library",
  },
  {
    component: Announcement,
    path: "/Manager-Announcements",
  },
  {
    component: Customer_Home,
    path: "/Customer",
  },
  {
    component: Customer_Capabilities,
    path: "/Customer-Capabilities",
  },
  {
    component: Transit,
    path: "/Customer-Calculator",
  },
  {
    component: Customer_Logistics,
    path: "/Customer-Logistics",
  },
  {
    component: NewPass,
    path: "/PasswordReset",
  },
  {
    component: DashboardRev,
    path: "/Internal-Dashboard",
  },
  {
    component: Checklist,
    path: "/Internal-Checklist",
  },
  {
    component: ManagerEmployeeView,
    path: "/Manager-EmployeeView",
  },
  {
    component: ManagerChecklist,
    path: "/Manager-Checklist",
  },
  {
    component: AmericasTeam,
    path: "/ExecutiveTeam",
  },
  {
    component: Nikki,
    path: "/Nikisha-Bibbs",
  },
  {
    component: Notifications,
    path: "/Internal-Notifications",
  },
  { component: NewsManage, path: "/NewsManage" },
];

export default NavRoutes;
