import { React, useEffect, useState } from 'react';
import Cervello from './Assets/LCOE.png';
import './style.css';
import CervelloCenter from '../SplashSideNav/New Cervello Global Logo - No Background.png';
import { Auth } from 'aws-amplify';



export default function Home(prop) {
	const [logged, setLogged] = useState('Log In');

	useEffect(() => {
		if (
			window.location.href.includes('/Internal') ||
			window.location.href.includes('/Manager') ||
			window.location.href.includes('/Customer')
		) {
			setLogged('Log Out');
		}


	}, []);




	return (
		<div className='grid grid-cols-3 gap-4 bg-white pb-2'>
			<div>
				{' '}
				<img className='ml-10 mt-4 w-64' src={Cervello} alt='Cervello' />
			</div>
			<div className="container">
				{' '}
				<img
					className='h-auto w-72 mx-auto object-contain object-center'
					src={CervelloCenter}
					alt='centerLogo'
				/>
			</div>
			<div className='topright mt-10'>
				<button
					onClick={() => {
						Auth.signOut()
							.then((data) => {
								window.location.href = `/${prop.slug}/Login`;
							})
							.catch((err) => console.log(err));
					}}
					className=' button w-24 h-10  bg-blue-500 hover:bg-blue-700 text-white font-bold py-.5 px-2 rounded shadow-2xl'>
					{logged}
				</button>
			</div>
		</div>
	);
}
