import John from './Mahan/Mahan';
import Karen from './Karen/Karen';
import Dave from './Ingram/Ingram';
import DavPic from './Ingram/Dave Ingram - Edited.jpg';
import JohnPic from './Mahan/John Mahan 2 - Edited.jpg';
import KazPic from './Karen/Karen McMillan - Edited.jpg';
import ThomasPic from './Thomas/Thomas.jpg';
import Boyd from './Boyd/Boyd';
import BoydPic from './Boyd/Boyd McMurtrey - Edited.jpg';
import Elline from './Binner/Binner';
import EllinePic from './Binner/Elline Binner - Edited.jpg';
import Val from './Valerie/Valerie';
import ValPic from './Valerie/Valerie Mahan - Edited.jpg';
import Daniel from './Daniel/Daniel';
import DanilePic from './Daniel/Dan Headshot - Edited.jpg';
import Hunter from './Hunter/Hunter';
import HunterPic from './Hunter/Hunter Haltom - Edited.jpg';
import NikkisPic from '../MiddleEast/Nikki/Nikki Bibbs.png';
import Nikki from '../MiddleEast/Nikki/Nikki';
import GerlachsHeadShot from '../Assets/Employees/John Gerlach.png';
import Gerlach from '../About/Gerlach/Gerlach';

const Indopacific = [
	{
		id: 1,
		photo: JohnPic,
		name: 'John Mahan',
		title: 'President & CEO',
		link: John,
	},
	{
		id: 2,
		photo: KazPic,
		name: 'Karen McMillan',
		title:
			'Director of Business & Workforce Development Cervello Global of Australia Pty Ltd ',
		link: Karen,
	},
	{
		id: 3,
		photo: DavPic,
		name: 'Dave Ingram',
		title: 'Chief Global Workforce Development Officer ',
		link: Dave,
	},
	{
		id: 4,
		photo: ThomasPic,
		name: 'Thomas Desiderio',
		title: 'Distribution Process Advocate for Australia',
		link: Elline,
	},
];

const Americas = [
	{
		id: 1,
		photo: JohnPic,
		name: 'John Mahan',
		title: 'President & CEO',
		link: John,
	},
	{
		id: 2,
		photo: EllinePic,
		name: 'Elline Binner',
		title: 'Director, Quality Management, Acquisition, & Logistics',
		link: Elline,
	},
	{
		id: 3,
		photo: ValPic,
		name: 'Valerie Mahan',
		title: 'Director, Finance, Accounting, & Human Capital Management',
		link: Val,
	},
	{
		id: 4,
		photo: DavPic,
		name: 'Dave Ingram',
		title: 'Chief Global Workforce Development Officer ',
		link: Dave,
	},
];

const MiddleEast = [
	{
		id: 1,
		photo: JohnPic,
		name: 'John Mahan',
		title: 'President & CEO',
		link: John,
	},
	{
		id: 2,
		photo: DavPic,
		name: 'Dave Ingram',
		title: 'Chief Global Workforce Development Officer ',
		link: Dave,
	},
	{
		id: 3,
		photo: NikkisPic,
		name: 'Nikisha Bibbs',
		title: 'Distribution Management Consultant / Interim DPA for Kuwait',
		link: Nikki,
	},
];

const Europe = [
	{
		id: 1,
		photo: JohnPic,
		name: 'John Mahan',
		title: 'President & CEO',
		link: John,
	},
	{
		id: 2,
		photo: DavPic,
		name: 'Dave Ingram',
		title: 'Chief Global Workforce Development Officer ',
		link: Dave,
	},
	{
		id: 3,
		photo: GerlachsHeadShot,
		name: 'John Gerlach',
		title: 'Director Cervello Global Gmbh And Distribution Process Advocate',
		link: Gerlach,
	},
];

const ExecutiveAmericas = [
	{
		id: 1,
		photo: JohnPic,
		name: 'John Mahan',
		title: 'President & CEO',
		link: John,
	},
	{
		id: 2,
		photo: EllinePic,
		name: 'Elline Binner',
		title: 'Director, Quality Management, Acquisition, & Logistics',
		link: Elline,
	},
	{
		id: 3,
		photo: ValPic,
		name: 'Valerie Mahan',
		title: 'Director, Finance, Accounting, & Human Capital Management',
		link: Val,
	},
	{
		id: 4,
		photo: DavPic,
		name: 'Dave Ingram',
		title: 'Chief Global Workforce Development Officer ',
		link: Dave,
	},
	{
		id: 5,
		photo: DanilePic,
		name: 'Daniel Grenier',
		title:
			'Vice President, Mission Command and Control for Critical Mission Systems C5ISR T&E ',
		link: Daniel,
	},
	{
		id: 6,
		photo: BoydPic,
		name: 'Boyd McMurtrey',
		title: 'Vice President, Global Logistics Center of Excellence',
		link: Boyd,
	},
	{
		id: 7,
		photo: HunterPic,
		name: 'Hunter Haltom',
		title: 'Vice President, Hypersonics C2 & CONOPS Center of Excellence',
		link: Hunter,
	},
];

export { Indopacific, Americas, ExecutiveAmericas, Europe, MiddleEast };
