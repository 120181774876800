import { React, useEffect, useState } from 'react';
import { Form, Button, Col, Row, Card } from 'react-bootstrap/';
import { DataStore } from '@aws-amplify/datastore';
import { Announcements, News } from '../../../models';


export default function Announcement() {
	const [announcement, setAnnouncement] = useState([]);
	const [news, setNews] = useState([]);

	const fetchNews = async () => {
		const newsModel = await DataStore.query(News);

		try {
			return setNews(newsModel);
		} catch (error) {
			return;
		}
	};

	const fetchAnnouncements = async () => {
		const announcementsModels = await DataStore.query(Announcements);

		try {
			return setAnnouncement(announcementsModels);
		} catch (error) {
			return;
		}
	};
	useEffect(() => {
		fetchAnnouncements();
		fetchNews();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		let title = e.target.elements.title?.value;
		let description = e.target.elements.description?.value;
		let region = 'Americas';

		await DataStore.save(
			new Announcements({
				title: title,
				description: description,
				region: region
			})
		);

		fetchAnnouncements();
	};

	const handleNewsSubmit = async (e) => {
		e.preventDefault();

		let title = e.target.elements.title?.value;
		let description = e.target.elements.description?.value;
		let region = 'Americas';


		await DataStore.save(
			new News({
				title: title,
				description: description,
				region: region
			})
		);

		fetchNews();
	};
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 '>
				<div className='h-auto flex bg-gray-bg1 mt-10'>
					<Row>
						<Col>
							<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
								<h1>Announcements</h1>

								<Form onSubmit={handleSubmit}>
									<Form.Group className='mb-3' controlId='formBasicEmail'>
										<Form.Label>Title</Form.Label>
										<Form.Control
											name='title'
											type='text'
											placeholder='Enter title'
										/>
									</Form.Group>

									<Form.Group className='mb-3' controlId='formBasicPassword'>
										<Form.Label>Description</Form.Label>
										<Form.Control
											name='description'
											type='text'
											placeholder='description'
										/>
									</Form.Group>
									<Button variant='primary' type='submit'>
										Submit
									</Button>
								</Form>
							</div>
						</Col>
						<Col>
							<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
								{announcement.map((x, i) => {
									return (
										<Card
											key={i}
											text='dark'
											className='mb-2'
											border='primary'
											style={{ width: '18rem' }}>
											<Card.Header>
												Announcements{' '}
												<a
													href='# '
													onClick={async (e) => {
														const modelToDelete = await DataStore.query(
															Announcements,
															x.id
														);
														DataStore.delete(modelToDelete);
														return fetchAnnouncements();
													}}>
													<i className='fa fa-trash' aria-hidden='true'></i>
												</a>
											</Card.Header>
											<Card.Body>
												<Card.Title> {x.title} </Card.Title>
												<Card.Text className='text-center'>
													{x.description}
												</Card.Text>
											</Card.Body>
										</Card>
									);
								})}
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
								<h1>News</h1>

								<Form onSubmit={handleNewsSubmit}>
									<Form.Group className='mb-3' controlId='formBasicEmail'>
										<Form.Label>Title</Form.Label>
										<Form.Control
											name='title'
											type='text'
											placeholder='Enter title'
										/>
									</Form.Group>

									<Form.Group className='mb-3' controlId='formBasicPassword'>
										<Form.Label>Description</Form.Label>
										<Form.Control
											name='description'
											type='text'
											placeholder='description'
										/>
									</Form.Group>
									<Button variant='primary' type='submit'>
										Submit
									</Button>
								</Form>
							</div>
						</Col>
						<Col>
							<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
								{news.map((x, i) => {
									return (
										<Card
											key={i}
											text='dark'
											className='mb-2'
											border='primary'
											style={{ width: '18rem' }}>
											<Card.Header>
												News{' '}
												<a
													href='# '
													onClick={async (e) => {
														const modelToDelete = await DataStore.query(
															News,
															x.id
														);
														DataStore.delete(modelToDelete);
														return fetchNews();
													}}>
													<i className='fa fa-trash' aria-hidden='true'></i>
												</a>
											</Card.Header>
											<Card.Body>
												<Card.Title> {x.title} </Card.Title>
												<Card.Text className='text-center'>
													{x.description}
												</Card.Text>
											</Card.Body>
										</Card>
									);
								})}
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
}
