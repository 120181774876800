import React, { useState, useEffect } from "react";
import { checkUser } from "../Auth/auth";
import { DataStore } from "@aws-amplify/datastore";
import { Users, OnBoarding } from "../models";
import { Auth } from "aws-amplify";
import Spinner from "react-bootstrap/Spinner";
import { stores } from "./StoreInfo";
import { ListGroup } from "react-bootstrap";
import FormDrop from "../FormDrop/FormDrop";
import BenefitsDrop from "./BenefitsDrop";
import { app } from "../base";
const db = app.firestore();

export default function DashboardRev() {
  const [userName, setUserName] = useState("");
  const [timeOfDay, setTimeOfDay] = useState("");
  const [currentStore, setCurrentStore] = useState("");
  const [currentDesc, setCurrentDesc] = useState("");
  const [sortedStores, setSortedStores] = useState("");
  const [requestForms, setRequestForms] = useState([]);
  const [sharepointLink, setSharepointLink] = useState("");
  const [storeSearch, setstoreSearch] = useState([]);

  // const queryMe = async () =>{
  //     const getUser = await DataStore.query(Users, (c) => c.email('eq', 'pdicarlo@go-ct.com'))
  //     console.log(getUser)
  //     const getOnboard = await DataStore.query(OnBoarding, (x) => x.id('eq', '07f6153d-31c8-4132-8193-185539cd3ca4'))
  //     const specificOnboard = getOnboard[0]
  //     console.log(specificOnboard)
  //     await DataStore.save(OnBoarding.copyOf(specificOnboard, (x) => {x.isConfirmed = true} ))
  //     console.log(getOnboard)
  // }

  useEffect(() => {
    const getUsersName = async () => {
      try {
        const authorize = await Auth.currentAuthenticatedUser();
        const usersEmail = authorize.attributes.email;
        const usersModel = await DataStore.query(Users, (c) =>
          c.email("eq", usersEmail)
        );
        setUserName(usersModel[0].first_name);
        checkUser(usersModel);
      } catch (error) {
        checkUser(error);
        console.log(error);
      }
    };
    getUsersName();

    const getDocs = async () => {
      const docCollection = await db.collection("files").get();
      try {
        const specificDocs = docCollection.docs.map((doc) => {
          return doc.data();
        });
        setstoreSearch(specificDocs);
      } catch (error) {
        return error;
      }
    };

    let today = new Date();
    let time = today.getHours();
    setTimeOfDay(time);
    const sorted = stores.sort((a, b) => (a.name > b.name ? 1 : -1));
    setSortedStores(sorted);
    getDocs();
  }, [storeSearch]);

  const storeChoice = (e) => {
    e.preventDefault();
    const result = stores.filter((store) => store.name === e.target.value);

    setCurrentStore(e.target.value);
    setCurrentDesc(result[0].description);
    result.map((y) => {
      setRequestForms(y.request);
      return y;
    });
    setSharepointLink(result[0].sharepointLink);
  };

  return (
    <div className=" text-black">
      {!userName ? (
        <div className="mb-6 bg-white border-2 border-solid pb-24">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-6 bg-white border-2 border-solid pb-24">
          <div className="blueOne p-16 text-white">
            <div className="text-center uppercase text-4xl">
              <div className="container text-left text-3xl text-white font-bold">
                {timeOfDay >= 0 && timeOfDay < 12
                  ? "Good morning, "
                  : timeOfDay >= 12 && timeOfDay < 17
                  ? "Good afternoon, "
                  : timeOfDay >= 17
                  ? "Good evening, "
                  : "Hello, "}
                {userName}.
              </div>
            </div>
          </div>
          {/* <button onClick={queryMe}>Hello world</button> */}
          <div className="container">
            <div className="container p-4">
              <div className="row">
                <div className="col-md-4">
                  <div className="border-2 border-blue-900 rounded p-4">
                    <div className="font-bold text-2xl text-blue-900">
                      Cervello Global Storefronts
                    </div>
                    <div>
                      <ListGroup className="pt-4">
                        {sortedStores.map((x, i) => {
                          return (
                            <ListGroup.Item className="hover:bg-blue-900 hover:text-white">
                                <button
                                  key={i}
                                  value={x.name}
                                  onClick={storeChoice}
                                >
                                  {x.name}
                                </button>                       
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </div>
                  </div>
                  <div>
                    <div className="pt-4">
                      <div className="border-2 border-blue-900 rounded  p-4">
                        <div className="font-bold text-blue-900 text-2xl pb-4">
                          Helpful Links
                        </div>
                        <div>
                          <ListGroup className=" text-center text-base">
                            <a
                              href="https://cervtech.sharepoint.com/sites/PersonalSites/Shared%20Documents/Forms/AllItems.aspx"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <ListGroup.Item className="hover:bg-blue-900 hover:text-white">
                                Your Personnel Folder
                              </ListGroup.Item>
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.paychex.com/login"
                            >
                              <ListGroup.Item
                                className="hover:bg-blue-900 hover:text-white"
                                key="paychex"
                              >
                                Paychex
                              </ListGroup.Item>
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://go-ct.unanet.biz/go-ct/action/home"
                            >
                              <ListGroup.Item
                                className="hover:bg-blue-900 hover:text-white"
                                key="techItemUnanet"
                              >
                                Unanet
                              </ListGroup.Item>
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://services.cosential.com/index.cfm"
                            >
                              <ListGroup.Item
                                className="hover:bg-blue-900 hover:text-white"
                                key="techItemCRM"
                              >
                                Unanet CRM
                              </ListGroup.Item>
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.office.com/"
                            >
                              <ListGroup.Item
                                className="hover:bg-blue-900 hover:text-white"
                                key="techItemMSO"
                              >
                                MS Office 365
                              </ListGroup.Item>
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.ringcentral.com/"
                            >
                              <ListGroup.Item
                                className="hover:bg-blue-900 hover:text-white"
                                key="techItemRC"
                              >
                                Ring Central
                              </ListGroup.Item>
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.empower.com/"
                            >
                              <ListGroup.Item
                                className="hover:bg-blue-900 hover:text-white"
                                key="techItemEmpower"
                              >
                                Empower
                              </ListGroup.Item>
                            </a>
                          </ListGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="border-2 border-blue-900 p-4 rounded text-left">
                    {!currentStore ? (
                      <div>
                        <div className="text-blue-900 font-bold text-2xl text-center">
                          Select a store
                        </div>
                        <div className="p-4">
                          <div>
                            Welcome to the Cervello Global Employee Intranet.
                            From here you can:
                          </div>
                          <div className="pt-2">
                            <ol className="list-disc">
                              <li>Access storefront Sharepoint folders</li>
                              <li>Download company documents, such as Business Card Requests, employee benefits documents, leave of absence request, etc.</li>
                              <li>Request forms for all storefronts</li>
                              <li>Descriptions of each storefront's mission and scope of work</li>
                              <li>Easy access to company resources in the Helpful Links section, such as Paychex, Unanet (timesheets), Empower (retirement), etc.</li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="text-blue-900 text-2xl font-bold text-center">
                          {currentStore}
                        </div>
                        <div className="p-4">{currentDesc}</div>
                        <div className="container text-left text-blue-900 font-bold text-xl">
                          Storefront Resources
                        </div>
                        {!sharepointLink ? (
                          <div>No Sharepoint Link available</div>
                        ) : (
                          <div className="p-4">
                            <a
                              href={sharepointLink}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Storefront Sharepoint Link
                            </a>
                          </div>
                        )}
                        <div className="container">
                          <div className="overscroll-auto">
                            {!currentStore ? null : currentStore ===
                              "Human Resources" ? (
                              <div>
                                <FormDrop store={currentStore} />
                                <BenefitsDrop />
                              </div>
                            ) : (
                              <FormDrop store={currentStore} />
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="p-4">
                            <div className="font-bold text-xl text-blue-900">
                              Request Forms
                            </div>
                            <div className="pt-4">
                              {!requestForms ? (
                                <div>No request forms for this store</div>
                              ) : (
                                requestForms.map((x) => {
                                  return (
                                    <div className="text-left">
                                      <a
                                        href={x.link}
                                        rel="noreferrer"
                                        target="_blank"
                                        className=""
                                      >
                                        {x.name}
                                      </a>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
