import React, { useState, useEffect } from "react";
import { app } from "../../base";
import { DataStore } from "@aws-amplify/datastore";
import { Users } from "../../models";
import checkUser from "../../Auth/auth";
const db = app.firestore();

export default function Reference() {
  const [search, setSearch] = useState("");
  const [users, setUsers] = React.useState([]);
  const onchange = (event) => setSearch(event.target.value);

  const filterFunction = (library) =>
    library.name.toUpperCase().indexOf(search.toUpperCase()) > -1 ||
    library.storefront.toUpperCase().indexOf(search.toUpperCase()) > -1;

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = await db.collection("files").get();
      try {
        const specificDocs = usersCollection.docs.map((doc) => {
          return doc.data();
        });
        setUsers(specificDocs);
      } catch (error) {
        return;
      }
    };
    const fetchUsersConfirmed = async () => {
      const models = await DataStore.query(Users);
      try {
        return checkUser(models);
      } catch (error) {
        return;
      }
    };

    fetchUsersConfirmed();
    fetchUsers();
  }, [users]);

  return (
    <div className="mx-auto text-black">
      <div className="pb-12 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl ">
        <div className="text-2xl font-bold text-blue-900 mt-12 text-center">
          Reference Library
        </div>
        <div className="p-3">
          <input
            type="text"
            placeholder="Search.."
            className="border-blue-900 border-2 text-center rounded"
            value={search}
            onChange={onchange}
          />
        </div>

        <div className="max-w-lg m-auto pt-8 pb-24">
          <div className="h-80 border-1 border-blue-900 overflow-auto">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="w-1/2 border-2 border-blue-900 text-blue-900 text-lg">
                    Document
                  </th>
                  <th className="w-1/2 border-2 border-blue-900 text-blue-900 text-lg">
                    Storefront
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0
                  ? users.filter(filterFunction).map((x, index) => {
                      return (
                        <tr key={index}>
                          <td className="border text-black text-left">
                            <a href={x.link}>{x.name}</a>
                          </td>
                          <td className="border text-black">
                            {x.storefront}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
