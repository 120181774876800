import { React, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap/';
import { Distance, Priority } from './Data';
import moment from 'moment';
export default function Transit() {
	const [values, setValues] = useState();
	const [response, setResponse] = useState('');
	const [delivery, setDelivery] = useState('');


	const onChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};



	const handleSubmit = (e) => {
		e.preventDefault();
		let distance = e.target.elements.distance?.value;

		if (distance === '< 500') {
			setResponse(
				<ul>
					<li>Single Driver/Truck Load - 1 day</li>
					<li>Single Driver/Less Than Truck Load- 3</li>
					<li>Dual Driver/Truck Load- 1 day</li>
					<li>Dual Driver/Less than Truck Load - 5 days</li>
				</ul>
			);

			setDelivery(
				`${moment().add(1, 'days').format('dddd, MMMM Do YYYY')} - ${moment()
					.add(5, 'days')
					.format('dddd, MMMM Do YYYY')}`
			);
		} else if (distance === '501-1000') {
			setResponse(
				<ul>
					<li>Single Driver/Truck Load - 2 day</li>
					<li>Single Driver/Less Than Truck Load- 4</li>
					<li>Dual Driver/Truck Load- 2 days</li>
					<li>Dual Driver/Less than Truck Load - 5 days</li>
				</ul>
			);

			setDelivery(
				`${moment().add(2, 'days').format('dddd, MMMM Do YYYY')} - ${moment()
					.add(5, 'days')
					.format('dddd, MMMM Do YYYY')}`
			);
		} else if (distance === '1001-1500') {
			setResponse(
				<ul>
					<li>Single Driver/Truck Load - 3 day</li>
					<li>Single Driver/Less Than Truck Load- 5 days</li>
					<li>Dual Driver/Truck Load- 3 days</li>
					<li>Dual Driver/Less than Truck Load - 6 days</li>
				</ul>
			);

			setDelivery(
				`${moment().add(3, 'days').format('dddd, MMMM Do YYYY')} - ${moment()
					.add(6, 'days')
					.format('dddd, MMMM Do YYYY')}`
			);
		} else if (distance === '1501-2000') {
			setResponse(
				<ul>
					<li>Single Driver/Truck Load - 4 day</li>
					<li>Single Driver/Less Than Truck Load- 6 days</li>
					<li>Dual Driver/Truck Load- 4 days</li>
					<li>Dual Driver/Less than Truck Load - 7 days</li>
				</ul>
			);

			setDelivery(
				`${moment().add(4, 'days').format('dddd, MMMM Do YYYY')} - ${moment()
					.add(7, 'days')
					.format('dddd, MMMM Do YYYY')}`
			);
		} else if (distance === '2001-2500') {
			setResponse(
				<ul>
					<li>Single Driver/Truck Load - 5 day</li>
					<li>Single Driver/Less Than Truck Load- 7 days</li>
					<li>Dual Driver/Truck Load- 5 days</li>
					<li>Dual Driver/Less than Truck Load - 8 days</li>
				</ul>
			);

			setDelivery(
				`${moment().add(5, 'days').format('dddd, MMMM Do YYYY')} - ${moment()
					.add(8, 'days')
					.format('dddd, MMMM Do YYYY')}`
			);
		} else if (distance === '> 2500') {
			setResponse(
				<ul>
					<li>Single Driver/Truck Load - 6 day</li>
					<li>Single Driver/Less Than Truck Load- 7 days</li>
					<li>Dual Driver/Truck Load- 5 days</li>
					<li>Dual Driver/Less than Truck Load - 9 days</li>
				</ul>
			);

			setDelivery(
				`${moment().add(6, 'days').format('dddd, MMMM Do YYYY')} - ${moment()
					.add(9, 'days')
					.format('dddd, MMMM Do YYYY')}`
			);
		}
	};
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 pb-10'>
				<Container>
					<Row className='pt-10'>
						<Col>
							<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
								<form className='w-full max-w-lg' onSubmit={handleSubmit}>
									<div className='flex flex-wrap -mx-3 mb-6'>
										<div className='w-full px-3 mt-6'>
											<label
												className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
												for='grid-state'>
												ORIGIN
											</label>
											<div className='relative'>
												<select
													name='origin'
													onChange={onChange}
													className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
													id='grid-state'>
													<option>CONUS</option>
												</select>
												<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
													<svg
														className='fill-current h-4 w-4'
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 20 20'>
														<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
													</svg>
												</div>
											</div>
										</div>
										<div className='w-full px-3 mt-6'>
											<label
												className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
												for='grid-state'>
												DISTANCE
											</label>
											<div className='relative'>
												<select
													onChange={onChange}
													name='distance'
													className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
													id='grid-state'>
													{Distance.map((x, i) => {
														return <option key={i}>{x}</option>;
													})}
												</select>
												<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
													<svg
														className='fill-current h-4 w-4'
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 20 20'>
														<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
													</svg>
												</div>
											</div>
										</div>
										<div className='w-full px-3 mt-6'>
											<label
												className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
												for='grid-state'>
												SUPPLY PRIORITY
											</label>
											<div className='relative'>
												<select
													className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
													id='grid-state'>
													{Priority.map((x, i) => {
														return <option key={i}>{x}</option>;
													})}
												</select>
												<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
													<svg
														className='fill-current h-4 w-4'
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 20 20'>
														<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
													</svg>
												</div>
											</div>
										</div>
									</div>
									<button className='text-black'>Submit</button>
								</form>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className='md:container pt-24 md:ml-28'>
							<Card className='text-black' style={{ width: '18rem' }}>
								<Card.Body>
									<Card.Title>Feasible Delivery Date:</Card.Title>
									<Card.Text>{delivery}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col className='pt-24'>
							<Card className='text-black' style={{ width: '18rem' }}>
								<Card.Body>
									<Card.Title>Logistics Response Time:</Card.Title>
									<Card.Text>{response}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
