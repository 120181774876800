import React from "react";
import "./john.css";
import JohnPic from "./mahan_pic.png";
import { Row, Col, Card } from "react-bootstrap/";
export default function Mahan() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            John Mahan - President & CEO
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={JohnPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                John Mahan, an U.S. Army veteran, founded Cervello in 2006.
                Outside of his military service, Mr. Mahan has worked in the
                Government arena for nearly three decades with customers such as
                the Department of Defense, the Department of Veteran Affairs,
                and the U.S. Army Corps of Engineers. Since the inception of
                Cervello, he has worked tirelessly to build a thriving defense
                contracting business which has expanded exponentially over the
                years. Mr. Mahan believes that Cervello's number one asset is
                its people, and our team's joint priority is customer
                satisfaction.
              </p>
              <p className="text-base text-black">
                As President and CEO, Mr. Mahan works to foster an environment
                where both personnel and customers feel important and heard.
                With the majority of Cervello's workforce being former military
                leaders, Mr. Mahan has ensured a high level of knowledge and
                professionalism within the corporation. Ultimately and most
                importantly, by supporting our customers effectively and
                efficiently, we are supporting the operating forces. As a
                veteran and an employer of many veterans, keeping the end users
                current, equipped, and ready is the heart of Mr. Mahan's driving
                force.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
