import React from "react";
import { Link } from "react-router-dom";

export default function Leadership() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Leadership
          </div>
        </div>
        <div className="container mt-5 pt-8">
          <div className="mx-auto row">
            <div className="text-2xl text-left underline">
              Executive Leadership Team
            </div>
          </div>
          <div className="mx-auto row mt-3">
            <ul className="text-left text-base">
              <li>
                <span className="text-blue-900 font-bold">John Mahan</span> -
                President & CEO
                <span className="ml-4">
                  <Link to="/John-Mahan">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">Jorge Aspiazu</span> -
                Vice President, Government & Business Alliances{" "}
                <span className="ml-4">
                  <Link to="/Jorge-Aspiazu">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">Kim Parker</span> -
                Vice President, Business, Capture & Proposal Development{" "}
                <span className="ml-4">
                  <Link to="/Kim-Parker">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">Elline Binner</span> -
                Vice President, Contracts, Pricing, & Quality Assurance
                <span className="ml-4">
                  <Link to="/Elline-Binner">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">Valerie Mahan</span> -
                Vice President, Finance, Accounting & Human Capital Management{" "}
                <span className="ml-4">
                  <Link to="/Valerie-Mahan">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">David Ingram</span> -
                Director, Global Logistics Center of Excellence
                <span className="ml-4">
                  <Link to="/David-Ingram">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">Daniel Grenier</span>{" "}
                - Vice President, Engineering & Applied Sciences
                <span className="ml-4">
                  <Link to="/Daniel-Grenier">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">Hunter Haltom</span> -
                Vice President, Hypersonics C2/CONOPS Center of Excellence and
                Future Force Modernization
                <span className="ml-4">
                  <Link to="/Hunter-Haltom">(Bio)</Link>
                </span>
              </li>
              <li>
                <span className="text-blue-900 font-bold">Jennifer King</span> -
                Director, Technical Writing & Publications
                <span className="ml-4">
                  <Link to="/Jennifer-King">(Bio)</Link>
                </span>
              </li>
            </ul>
          </div>
          <div className="mx-auto row">
            <div className="text-xl text-left underline">Indopacific</div>
          </div>
          <div className="mx-auto row mt-3">
            <ul className="text-left text-base">
              <li>
                <span className="text-blue-900 font-bold">Karen McMillan</span>{" "}
                - Operations Manager, Cervello Global of Australia Pty Ltd
                <span className="ml-4">
                  <Link to="/Karen-McMillan">(Bio)</Link>
                </span>
              </li>
            </ul>
          </div>
          <div className="mx-auto row">
            <div className="text-xl text-left underline">Europe</div>
          </div>
          <div className="mx-auto row mt-3">
            <ul className="text-left text-base">
              <li>
                <span className="text-blue-900 font-bold">John Gerlach</span> -
                Director, Cervello Global - Germany GmbH
                <span className="ml-4">
                  <Link to="/John-Gerlach">(Bio)</Link>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
