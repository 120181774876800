import React, { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Managers, Users } from '../../models';
import checkUser from '../../Auth/auth';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';
Amplify.configure(awsconfig);
export default function Notifications() {
	const [notification, setNotification] = useState([]);
	const [authorizedUser, setAuthorizedUser] = useState([]);

	const getNotifications = async () => {
		const authorize = await Auth.currentAuthenticatedUser();
		const authorizeEmail = authorize.attributes.email;
		const managersEmail = await DataStore.query(Managers, (c) =>
			c.email('eq', authorizeEmail)
		);

		try {
			setAuthorizedUser(managersEmail[0]);
			setNotification(managersEmail[0].notifications);
		} catch (error) {
			return;
		}
	};

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const authorize = await Auth.currentAuthenticatedUser();
				const authorizeEmail = authorize.attributes.email;

				const usersEmail = await DataStore.query(Users, (c) =>
					c.email('eq', authorizeEmail)
				);

				checkUser(usersEmail);
			} catch (error) {
				return;
			}
		};
		fetchUsers();
		getNotifications();
	}, []);

	return (
		<div className='md:container md:mx-auto  md:px-28 text-black -mt-48 '>
			<div className='md:container md:mx-auto md:px-28  mb-6 bg-gray-200 border-2 border-opacity-100 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-48 md:h-auto '>
				<div className=' mb-32 mt-16  md:mx-auto bg-white rounded-lg border border-primaryBorder  shadow-default py-10 px-16 '>
					<div className='flex flex-col'>
						<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
							<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
								<div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
									<table className='min-w-full divide-y divide-gray-200'>
										<thead className='bg-gray-50'>
											<tr>
												<th
													scope='col'
													className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-screen'>
													Notification
												</th>
												<th
													scope='col'
													className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-screen'>
													Delete All
													<button
														onClick={async () => {
															await DataStore.save(
																Managers.copyOf(authorizedUser, (updated) => {
																	updated.notifications = [];
																})
															);

															getNotifications();
														}}
														className='ml-4 text-indigo-600 hover:text-indigo-900'>
														<i className='fa fa-trash' aria-hidden='true'></i>
													</button>
												</th>
											</tr>
										</thead>
										<tbody className='bg-white divide-y divide-gray-200'>
											{notification.length > 0 ? (
												notification.map((notify, i) => (
													<tr key={i}>
														<td className='px-6 py-4 whitespace-nowrap'>
															<div className='text-sm text-left text-gray-900 text-right text-xl ml-32'>
																{notify}
															</div>
														</td>
														<td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'></td>
													</tr>
												))
											) : (
												<tr>
													<td>
														<div className='text-right text-xl ml-32 font-bold'>
															No new notifications
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
