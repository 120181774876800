import React from 'react';
import EmilPic from './Emil Reynolds.jpg';
import { Row, Col, Container, Card } from 'react-bootstrap/';
export default function Emil() {
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 md:h-auto '>
				<Container className='mt-10'>
					<Row>
						<Col>
							<Card className='mt-6' style={{ width: '18rem' }}>
								<Card.Img variant='top' src={EmilPic} />
								<Card.Body className='text-black'>
									<Card.Title>Emil Reynolds</Card.Title>
									<Card.Text className='text-center'>
										Director, Product Development, Sustainment, and Training
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col xs={6} className='mr-24 pt-24'>
							<p className='text-xl text-black'>
								Emil Reynolds is a former Soldier and contractor with an 18-year
								track record of achieving mission critical results in
								fast-paced, austere environments through dynamic action,
								teamwork, and leadership. Upon hanging up his boots, his focus
								in the civilian workforce supporting war-fighters has centered
								on management and advanced concepts and capabilities
								development.
							</p>{' '}
							<p className='text-xl text-black'>
								Emil leads within Cervello Global as the Director of Product
								Development. Innovation is his passion, and his in-depth and
								personal experience with Project Management, Experimentation,
								Test & Evaluation, and tactical operations combined with his
								military experience enables him to understand gaps, seams, and
								redundancies to visualize and conceptualize innovative
								technologies.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
