import { React, useState, useEffect } from 'react';
import convert from 'convert-units';
import { Button, InputGroup, FormControl } from 'react-bootstrap/';
import './Calculator.css';
import { DataStore } from '@aws-amplify/datastore';
import {  Users } from '../../models';
import checkUser from '../../Auth/auth';
export default function Calculator() {
	const [Pounds, setPounds] = useState();
	const [Convert, setConvert] = useState();
	const [Option1, setOption1] = useState('lb');
	const [Option2, setOption2] = useState('kg');

	useEffect(() => {
		const fetchUsers = async () => {
			const models = await DataStore.query(Users);
			checkUser(models);
		};

		fetchUsers();
	}, []);

	const kiloweightConvert = (e) => {
		setPounds(Math.round(convert(Convert).from(Option1).to(Option2)));
	};

	const handle = (e) => {
		setConvert(e.target.value);
	};

	const handleOption1 = (e) => {
		setOption1(e.target.name);
	};

	const handleOption2 = (e) => {
		setOption2(e.target.name);
	};
	return (
		<div className='h-screen flex bg-gray-bg1 shadow-2xl'>
			<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16 shadow-2xl'>
				<h2 className='font-black text-3xl text-black'>Weight Converter</h2>
				<h1 className='font-black text-3xl text-black'>
					{' '}
					Conversion:{' '}
					<span name='pounds' id='Pounds'>
						{Pounds}
						{Option2}
					</span>
				</h1>

				<p>Enter a value in the field to convert :</p>
				<InputGroup className='mb-3'>
					<FormControl
						placeholder='Enter'
						aria-label='Enter'
						onChange={handle}
						aria-describedby='basic-addon2'
					/>
					<Button
						onClick={kiloweightConvert}
						variant='outline-secondary'
						id='button-addon2'>
						Calculate
					</Button>
				</InputGroup>

				<div className='dropdown inline-block relative'>
					<button className='bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center'>
						<span className='mr-1'>{Option1}</span>
						<svg
							className='fill-current h-4 w-4'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'>
							<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />{' '}
						</svg>
					</button>
					<ul className='dropdown-menu absolute hidden text-gray-700 pt-1'>
						<li className=''>
							<a
								href='# '
								className='rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='kg'
								onClick={handleOption1}>
								Kg
							</a>
						</li>
						<li>
							<a
								href='# '
								className='bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='lb'
								onClick={handleOption1}>
								Lbs
							</a>
						</li>
						<li>
							<a
								href='# '
								className='bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='km'
								onClick={handleOption1}>
								Km
							</a>
						</li>
						<li>
							<a
								href='# '
								className='bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='mi'
								onClick={handleOption1}>
								Miles
							</a>
						</li>
					</ul>
				</div>

				<h1 className='font-black text-3xl text-white'>TO:</h1>

				<div className='dropdown inline-block relative'>
					<button className='bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center'>
						<span className='mr-1'>{Option2}</span>
						<svg
							className='fill-current h-4 w-4'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'>
							<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />{' '}
						</svg>
					</button>
					<ul className='dropdown-menu absolute hidden text-gray-700 pt-1'>
						<li>
							<button
								className='rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='kg'
								onClick={handleOption2}>
								Kg
							</button>
						</li>
						<li>
							<button
								className='bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='lb'
								onClick={handleOption2}>
								Lbs
							</button>
						</li>
						<li>
							<button
								className='bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='km'
								onClick={handleOption2}>
								Km
							</button>
						</li>
						<li>
							<button
								className='bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap'
								name='mi'
								onClick={handleOption2}>
								Miles
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
