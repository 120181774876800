// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Blog, Managers, Users, OnBoarding, BenefitForms, News, PerformanceTracker, ReferenceLibrary, Announcements, Future, Current, Errors, ToDo } = initSchema(schema);

export {
  Blog,
  Managers,
  Users,
  OnBoarding,
  BenefitForms,
  News,
  PerformanceTracker,
  ReferenceLibrary,
  Announcements,
  Future,
  Current,
  Errors,
  ToDo
};