import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Users } from '../models';

export const checkUser = async (userModel) => {
	try {
		if (!userModel[0].OnBoarding.isConfirmed)
			return (window.location.href = `/Internal-Checklist`);
	} catch (err) {
		return (window.location.href = `/Login`);
	}
};

export const handleError = async (errorMessage) => {
	var today = new Date();

	var todaysDate =
		today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

	var todaysTime =
		today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
	try {
		const authorize = await Auth.currentAuthenticatedUser();
		const usersEmail = authorize.attributes.email;
		const usersModel = await DataStore.query(Users, (c) =>
			c.email('eq', usersEmail)
		);

		const original = await DataStore.query(Users, usersModel[0].id);

		await DataStore.save(
			Users.copyOf(original, (updated) => {
				updated.errors = [
					{
						message: errorMessage,
						page: window.location.href,
						email: usersModel[0].email,
						date: todaysDate,
						time: todaysTime,
					},
				];
			})
		);
	} catch (error) {
		console.log(error);
	}
};

export default checkUser;
