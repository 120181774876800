export default function FAQ() {
  return (
    <section className="col-md-8 text-left text-base">
      <div className="text-left text-2xl text-blue-900 font-bold">
        Frequently Asked Questions
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: What is the purpose of this page?
      </div>
      <div classname="text-base">
        A: This is an administration screen designed to help managers sign-up
        new employees, track new employees' onboarding progress, and authorize
        employees for Intranet access.
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: How do I sign up a new employee for Intranet access?
      </div>
      <div classname="text-base">
        A: Click the Sign Up New Employee button at the top of this page. Fill
        out the Sign-Up Form and submit. The employee will receive an
        Authentication code via email. Ask the employee to give you this code.
        Go to the Authenticate tab, enter the code in the Authentication Code
        field and click Submit. The user can then reset their password and log
        in.
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: What is employee Authentication?
      </div>
      <div classname="text-base">
        A: Before a new employee can be granted access to the Intranet, they
        need to be authenticated by their manager. After you sign up a new
        employee, the new employee will receive an email with an authentication
        code. Ask them for this code and enter the code in the Authentication
        Code field in the Authenticate tab. For security purposes, new employees
        cannot authenticate themselves.
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: The new employee's Authentication Code isn't working. What can I do?
      </div>
      <div classname="text-base">
        A: Authentication Codes are valid for 24 hours, after which they expire
        and are invalid. If the employee's authentication code has expired,
        navigate to the Authenticate tab, and click the Resend Code button for
        that user.
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: The employee I'm signing up is not a new employee and has already
        been onboarded, but they don't have Intranet access yet. What can I do?
      </div>
      <div classname="text-base">
        A: The New Usr Sign-Up form includes an option to 'Skip Onboarding.' You
        can mark this option on the form to skip onboarding for the new
        employee. You will still need to authenticate their account. When they
        reset their password and login for the first time, they will have
        immediate access to the Intranet Dashboard.
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: How do I see if an employee has completed the Onboarding Checklist?
      </div>
      <div classname="text-base">
        A: Click on the Verifying Onboarding tab to view employees in your
        department who are in the process of onboarding. Here you can view which
        steps each employee has completed and which steps they have yet to
        complete. Once the employee has completed all steps in the Onboarding
        Checklist, click the Verify button to grant access to the Intranet
        Dashboard for the employee.
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: What does Completed mean?
      </div>
      <div classname="text-base">
        A: Employees in the Completed tab have been authenticated and completed
        onboarding. They can access the Intranet Dashboard, and if they are
        managers, they can also access the Manager Administration screen.
      </div>
      <div className="pt-4 text-base font-bold text-blue-900">
        Q: When will the Orioles win the World Series?
      </div>
      <div classname="text-base">A: My money is on 2025.</div>
    </section>
  );
}
