import { React } from 'react';
import '../About/About.css';
import { ExecutiveAmericas } from '../About/CardInfo';
import { BrowserRouter as Router } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Card, Button, CardGroup } from 'react-bootstrap/';

export default function AmericasTeam() {
	return (
		<Router>
			<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
				<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 '>
					<Container className='mb-6 bg-white mt-10 border-2 border-opacity-100 border-solid rounded-lg shadow-inner-2xl shadow-2xl '>
						<Row>
							<h1 className='text-left'>Executive Leadership</h1>
							{ExecutiveAmericas.map((employee) => {
								return (
									<Col key={employee.id}>
										<CardGroup
											className='h-full pt-6 pb-16  '
											style={{ width: '18rem', marginTop: '20px' }}>
											<Button
												value={employee.name}
												type='button'
												variant='light'
												onClick={() => {
													let str = employee.name;

													let splitted = str.split(' ');
													let first = splitted[0];
													let second = splitted[1];

													return (window.location.href = `${first}-${second}`);
												}}>
												<Card
													className='h-full pt-6 bg-gray-300 '
													style={{ width: '17rem' }}
													border='light'>
													<Card.Img
														className='expand bg-gray-100'
														variant='top'
														src={employee.photo}
													/>
													<Card.Body className='text-black bg-gray-100 '>
														<Card.Title>{employee.name}</Card.Title>
														<Card.Text className='text-center'>
															{employee.title}
														</Card.Text>
													</Card.Body>
												</Card>
											</Button>
										</CardGroup>
									</Col>
								);
							})}
						</Row>
					</Container>
				</div>
			</div>
		</Router>
	);
}
