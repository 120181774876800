import React from "react";
import "./karen.css";
import { Row, Col, Card } from "react-bootstrap/";
export default function Karen() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Karen McMillan - Operations Manager, Cervello Global of Australia
            Pty Ltd
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            {/* <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" />
              </Card>
            </Col> */}
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                Karen McMillan proudly served 32 years in the Australian Defence
                Forces retiring as a Logistics Warrant Officer in the Australian
                Army for the US Marine Support Coordination Team. Karen is a
                highly respected leader in the field of global supply chain
                management specializing in the areas of global logistics
                distribution, marine-air-ground task force planning, purchasing,
                and mobility.
              </p>
              <p className="text-base text-black">
                Karen's role within Cervello Global of Australia is to develop
                and manage our growing customer base for Cervello Global of
                Australia Pty Ltd and to effectively recruit, train, and support
                the company's workforce throughout Australia and nearby nations.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
