import React from "react";
import "./hunter.css";
import { Row, Col, Card } from "react-bootstrap/";
import HunterPic from "./hunter_pic.png";
export default function Hunter() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Hunter Haltom Vice President, Hypersonics C2/CONOPS Center of
            Excellence and Future Force Modernization
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={HunterPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                J. Hunter Haltom is a Military and Liaison Officer with over 28
                years of experience working for the Department of Defense as a
                Wargaming and Research Analyst, Executive/Action Officer, and a
                Global Strike Subject Matter Analyst/Expert (SMA/SME).
              </p>
              <p className="text-base text-black">
                Hunter is Vice President of Hypersonics Command and Control (C2)
                /Concept of Operations (CONOPS) Center of Excellence (COE) and
                Future Force Modernization at Cervello. This Hypersonic COE will
                be an ecosystem of interdependent contributors to a continuously
                evolving C2 and CONOPS data repository for stakeholders
                responsible for a Globally Integrated Operations (GIO) mission
                set. Future Force Modernization is to enhance our forces to be
                adaptive and innovative while training as they fight with
                emerging technologies for successful mission outcomes.
              </p>
              <p className="text-base text-black">
                Hunter currently leads the Quick Reaction Test (Joint-Deployment
                of Hypersonic Weapons Employment) for USSTRATCOM J37 and J81
                developing the Tactics, Techniques and Procedures for
                USSTRATCOM. He led the Joint-HyperSonic Strike Weapons Planning
                and Execution Command and Control (J-HyperSPEC2) Joint Test (JT)
                as a SMA/SME providing engineering, test, and analysis services
                to the Joint Test Director in the conduct of J-HyperSPEC2 JT.{" "}
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
