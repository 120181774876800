import React from "react";
import "./valerie.css";
import { Row, Col, Card } from "react-bootstrap/";
export default function Valerie() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Valerie Mahan - Vice President, Finance, Accounting, & Human Capital
            Management
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            {/* <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" />
              </Card>
            </Col> */}
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                Valerie Mahan has more than 15 years of specialized experience
                with expert knowledge in Talent Management, HRIS, Payroll,
                Project Management, and more. She is a Certified SAP
                SuccessFactors Senior Consultant with over 10+ years of concrete
                functional and technical experience across multiple modules with
                the proven and effective ability to analyze, support, implement
                and deliver SuccessFactors cloud solutions successfully as per
                business requirements. Dedicated, dependable and adaptable
                individual who works well under pressure.
              </p>
              <p className="text-base text-black">
                Valerie's role within Cervello has her wearing many hats. She
                cares for every aspect of each employee's working life as they
                become part of the Cervello family. She develops comprehensive
                strategic recruiting and retention plans to meet Cervello's
                human capital needs and provides overall leadership and guidance
                to the HR function. Valerie is dedicated to her Cervello family
                and is a dependable team leader.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
