import { React, useState } from 'react';
import {
	Container,
	Spinner,
	Row,
	Col,
	Table,
	Form,
	Button,
} from 'react-bootstrap/';
import { API } from 'aws-amplify';
import './style.css';

export default function Customer_Logistics() {
	const [datas, setDatas] = useState([]);
	const [number, setNumber] = useState('');
	const [status, setStatus] = useState('Search');

	const updatedDateAndTime = (dateTime) => {
		let today = new Date(dateTime);
		let dateFormat = today.toISOString().split('T')[0];
		let timeFormat = today.toISOString().split('T')[1];
		let newTimeFormat = timeFormat.substring(0, timeFormat.length - 5);

		return `${dateFormat} ${newTimeFormat}`;
	};

	const click = async (e) => {
		e.preventDefault();
		if (number === '') {
			alert('Please Input a Tracking Number');
		}

		try {
			setStatus(
				<Spinner variant='danger' animation='border' role='status'>
					<span className='visually-hidden'>Loading...</span>
				</Spinner>
			);
			const data = await API.get('track17', `/packages/${number}`);

			if (data.success.data.trackings[0].events.length === 0) {
				alert(
					'Sorry could not locate tracking number please enter a valid tracking number'
				);
			} else {
				setStatus('Search');
				setDatas(data.success.data.trackings[0].events);
			}
		} catch (error) {
			setStatus('Search');
			return;
		}
	};

	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 '>
				<h1 className='font-black text-3xl text-black pb-5'>
					LOGISTICS TRACKING
				</h1>

				<div className='flex items-center justify-center'>
					<Form>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label className='font-black text-2xl text-black'>
								Track Package
							</Form.Label>
							<Form.Control
								onChange={(e) => setNumber(e.target.value)}
								type='text'
								placeholder='Tracking Number'
								name='number'
							/>
						</Form.Group>
						{!status && (
							<Spinner animation='border' role='status'>
								<span className='visually-hidden'>Loading...</span>
							</Spinner>
						)}

						<Button onClick={click} variant='primary'>
							{status}
						</Button>
					</Form>
				</div>

				<Container className='pt-6'>
					<Row>
						<Col className='mr-6 max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default'>
							<Table striped bordered hover>
								<thead>
									<tr>
										<th className='border border-black border-opacity-100 border-solid'>
											Tracking Number
										</th>

										<th className='border border-black border-opacity-100 border-solid'>
											Status
										</th>

										<th className='border border-black border-opacity-100 border-solid'>
											Date
										</th>
										<th className='border border-black border-opacity-100 border-solid'>
											Location
										</th>
									</tr>
								</thead>
								<tbody>
									{datas.map((x, i) => {
										return (
											<tr key={i}>
												<td>{x.trackingNumber}</td>
												<td>{x.status}</td>
												<td>{updatedDateAndTime(x.datetime)}</td>
												<td>{x.location}</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
