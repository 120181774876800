import React, { useState, useEffect } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { app } from '../base';
const db = app.firestore();

export default function FormDrop({ store }) {
	const [docNames, setDocNames] = useState([]);

	useEffect(() => {
		const getDocs = async () => {
			const docCollection = await db.collection(store).get();
			try {
				const specificDocs = docCollection.docs.map((doc) => {
					return doc.data();
				});
				setDocNames(specificDocs);
			} catch (error) {
				return;
			}
		};
		getDocs();
	}, [store]);

	return (
		<div>
			<div className="content-center">
				{docNames.length < 1 ? (
					<DropdownButton
						id='dropdown-basic-button'
						title={store + ' Documents'}
						className='mt-10 w-auto mx-auto'>
						<div>No documents</div>
					</DropdownButton>
				) : (
					<DropdownButton
						id='dropdown-basic-button'
						title={store + ' Documents'}
						className=''>
						<div className='h-40 overflow-auto'>
							{docNames.map((data) => {
								return (
									<DropdownItem
										target='_blank'
										href={data.link}
										key={data.name}>
										{data.name}
									</DropdownItem>
								);
							})}
						</div>
					</DropdownButton>
				)}
			</div>
		</div>
	);
}
