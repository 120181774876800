import { React } from "react";
import FooterLogo from "./footerLogo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="md:container md:h-auto md:mx-auto md:px-28 text-black flex flex-col mt-12">
      <footer className="foot md:container ">
        <div className="grid sm:flex space-x-10 justify-center md:flex space-x-20 justify-center lg:flex space-x-20 justify-center ">
          <div className="grid grid-cols-1">
            <div className="mx-auto">
              <img src={FooterLogo} className="h-20 mb-4" alt="Cervello Logo" />
            </div>
            <div className="flex-1 space-x-4">
              <a href="https://www.facebook.com/CervelloT/">
                <i className="fab fa-facebook-square text-3xl "></i>
              </a>
              <a href="https://twitter.com/cervelloglobal?lang=en">
                <i className="fab fa-twitter-square text-3xl "></i>
              </a>
              <a href="https://www.linkedin.com/company/cervellotech">
                <i className="fab fa-linkedin text-3xl "></i>
              </a>
            </div>
          </div>

          <div className="grid gap-2 mt-6 mb-4 text-s">
            <h5 className="text-left font-bold text-blue-900">Capabilities</h5>
            <ul className="text-left">
              <li>
                <Link to="Hypersonics" className="text-blue-900 no-underline">
                  Hypersonics
                </Link>
              </li>
              <li>
                <Link className="text-blue-900 no-underline" to="Logistics">
                  Global Logistics
                </Link>
              </li>
              <li>
                <Link to="Future" className="text-blue-900 no-underline">
                  Future Force Modernization
                </Link>
              </li>
              <li>
                <Link to="Mission" className="text-blue-900 no-underline">
                  Mission Command & Control
                </Link>
              </li>
            </ul>
          </div>

          <div className="grid gap-2 mt-6 text-s">
            <h5 className="text-left font-bold text-blue-900">Resources</h5>
            <ul className="text-left mb-16">
              <li className="text-blue-900">CAGE Code: 53PR6</li>
              <li className="text-blue-900">Unique Entity ID: FYXEY32QL7K6</li>
              <li>
                <Link to="Contact" className="text-blue-900 no-underline">
                  Contact Us
                </Link>
              </li>

              <li>
                <Link className="text-blue-900 no-underline" to="Careers">
                  Careers
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-xs text-blue-900 text-center">
          &copy;{new Date().getFullYear()} Cervello Global Corporation. All
          Rights Reserved. No access, use or modification without Cervello's
          express consent in writing.
        </p>
      </footer>
    </div>
  );
}
