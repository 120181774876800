import React from "react";
import "./Contact.css";
import USOffice from "./Offices";
import { POBox, GermanyOffice, AustraliaOffice, DarwinOffice, StuttgartOffice } from "./Offices";
import GoogleMap from "../Map/Map";

export default function Contact() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className=" mb-12 pb-12 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Contact Us
          </div>
        </div>
        <div className="container mt-5">
          <div className="mx-auto">
            <div className="row">
              <div className="col pl-44 ">
                {" "}
                <div className="w-full max-w-lg blueText font-bold text-3xl text-center pb-4">
                  Send us a message
                </div>
                <form
                  className="w-full max-w-lg"
                  action="https://formspree.io/f/mjvledol"
                  method="POST"
                >
                  <label
                    className="tracking-wide text-black font-bold mb-2"
                    htmlFor="grid-name"
                    id="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="John Doe"
                    className="appearance-none text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                  />
                  <label
                    className="tracking-wide text-black font-bold mb-2"
                    htmlFor="grid-password"
                    id="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="email@example.com"
                    className="appearance-none text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                  />
                  <label
                    htmlFor="subject"
                    className="tracking-wide text-black font-bold mb-2"
                    id="subject"
                  >
                    Request Type: <i className="arrow down"></i>
                  </label>
                  <select
                    name="subject"
                    id="subjectOptions"
                    className="text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                  >
                    <option value="Default">Select a Request Type</option>
                    <option value="Business">Business Inquiry</option>
                    <option value="Logistics">Global Logistics</option>
                    <option value="MissionCommand">
                      Mission Command & Control
                    </option>
                    <option value="Hypersonics">
                      Hypersonics & Emerging Technologies
                    </option>
                    <option value="FutureForce">
                      Future Force Modernization
                    </option>
                    <option value="ProductDev">Product Development</option>
                    <option value="HR">Human Resources</option>
                  </select>
                  <label
                    className="tracking-wide text-black font-bold mb-2"
                    htmlFor="grid-message"
                    id="message"
                  >
                    Message
                  </label>
                  <textarea
                    className="no-resize text-black appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                    id="message"
                    name="message"
                    placeholder="How can we help?"
                  />
                  <br />
                  <input
                    className="mt-2 mb-4 border-black blueOne text-white font-bold py-2 px-4 rounded"
                    type="submit"
                    value="Submit"
                  ></input>
                </form>
              </div>

              <div className="mx-auto container col">
                <div className="row">
                  <div className="col">
                    <GoogleMap />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <USOffice />
                  </div>
                  <div className="col">
                    <POBox />
                  </div>
                </div>

                <div className="col text-blue-900 underline font-bold">
                    Australia Subsidiary
                  </div>
                <div className="row">

                  <div className="col">
                    <AustraliaOffice />
                  </div>
                  <div className="col">
                    <DarwinOffice />
                  </div>
                </div>
                <div className="col text-blue-900 underline font-bold">
                    Europe Subsidiary
                  </div>
                <div className="row">

                  <div className="col">
                    <GermanyOffice />
                  </div>
                  <div className="col">
                    <StuttgartOffice />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
