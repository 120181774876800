export default function Request() {
  return (
    <div className="mx-auto">
      <div className="mb-6 bg-white border-2 border-opacity-300 border-solid shadow-inner-2xl shadow-2xl">
        <div className="text-2xl font-bold text-blue-900 mt-12 mb-12 text-center ">
          Request Transportation Services
        </div>
        <div className="container w-2 mx-auto">
          <div className="text-blue-900 font-bold text-xl text-center pb-4">
            Please complete all fields.
          </div>
          <form
            className="row mb-48"
            action="https://formspree.io/f/xzbwzvrl"
            method="POST"
          >
            <div className="col-md-6">
              <div className="row pb-6">
                <label>
                  <span className="font-bold text-blue-900">First Name: </span>
                  <input
                    className="border-2 w-64 text-center rounded-lg"
                    type="first-name"
                    name="first-name"
                    placeholder="John"
                    required
                  />
                </label>
              </div>

              <div className="row pb-6">
                <label>
                  <span className="font-bold text-blue-900">Last Name: </span>
                  <input
                    className="border-2 w-64 text-center rounded-lg"
                    type="last-name"
                    name="last-name"
                    placeholder="Doe"
                    required
                  />
                </label>
              </div>

              <div className="row pb-6">
                <label>
                  <span className="font-bold text-blue-900">Email: </span>
                  <input
                    className="border-2 w-64 text-center rounded-lg"
                    type="email"
                    name="email"
                    placeholder="john@example.com"
                    required
                  />
                </label>
              </div>

              <div className="row pb-6">
                <label for="phone">
                  <span className="font-bold text-blue-900">
                    Phone Number:{" "}
                  </span>
                  <input
                    className="border-2 w-64 text-center rounded-lg"
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="123-456-7890"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    required
                  />
                </label>
              </div>

              <div className="row pb-6">
                <div className="grid-cols-2">
                  <label for="service">
                    <span className="font-bold text-blue-900 pr-2">
                      Service:{" "}
                    </span>
                  </label>
                  <select
                    id="service"
                    className="w-26 border-2 rounded-lg bg-blue-900 text-white p-3"
                    name="service"
                  >
                    <option value="Army">Army</option>
                    <option value="Navy">Navy</option>
                    <option value="Air Force">Air Force</option>
                    <option value="Marines">Marines</option>
                    <option value="Civilian">Civilian</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="row pb-6">
                <div className="grid-cols-2">
                  <label for="pickup">
                    <span className="font-bold text-blue-900 pr-2">
                      Pickup Location:{" "}
                    </span>
                  </label>
                  <select
                    id="pickup"
                    className="w-26 border-2 rounded-lg bg-blue-900 text-white p-3"
                    name="pickup"
                  >
                    <option value="Stuttgart-Local">USAG Stuttgart</option>
                    <option value="Frankfurt">Frankfurt Airport</option>
                    <option value="Ramstein">Ramstein AB</option>
                    <option value="Munich">Munich</option>
                  </select>
                </div>
              </div>
              <div className="row pb-6">
                <div className="grid-cols-2">
                  <label for="dropoff">
                    <span className="font-bold text-blue-900 pr-2">
                      Dropoff Location:{" "}
                    </span>
                  </label>
                  <select
                    id="dropoff"
                    className="w-26 border-2 rounded-lg bg-blue-900 text-white p-3"
                    name="dropoff"
                  >
                    <option value="Stuttgart-Local">USAG Stuttgart</option>
                    <option value="Frankfurt">Frankfurt Airport</option>
                    <option value="Ramstein">Ramstein AB</option>
                    <option value="Munich">Munich</option>
                  </select>
                </div>
              </div>
              <div className="row pb-6">
                <label for="service-date">
                  <span className="font-bold text-blue-900 pr-2">
                    Date of Service:
                  </span>
                  <input
                    className="text-center border-2 rounded-lg"
                    type="date"
                    id="service-date"
                    name="service-date"
                  />
                </label>
              </div>
              <div className="row pb-6">
                {" "}
                <label for="cargo-weight">
                  <span className="font-bold text-blue-900 pr-2">Estimated Cargo Weight (lbs.): </span>
                  <input
                    type="number"
                    id="cargo-weight"
                    name="cargo-weight"
                    min="1"
                    max="500"
                    className="text-center border-2 rounded-lg"
                  ></input>
                </label>
              </div>
              <div className="row pb-6">
                <label>
                  <span className="font-bold text-blue-900 pr-2">
                    Additional Comments:
                  </span>
                  <textarea className="border" name="message"></textarea>
                </label>
              </div>
            </div>

            <div>
              {" "}
              <button
                className="border-2 rounded-lg bg-blue-900 text-white py-3 px-6"
                type="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

// Cargo description. Pets? Y/N