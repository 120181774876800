const healthInsuranceForms = (arrayData, index) => {
  return (
    <div className="text-left" key="1">
      <div className="font-bold text-blue-900 text-xl">Health Insurance</div>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData["BCBS Member Registration"].link}
          >
            {arrayData["BCBS Member Registration"].name}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.BlueCare_SG_HMO.link}
          >
            {arrayData.BlueCare_SG_HMO.name}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Health_Insurance.link}
          >
            {arrayData.Health_Insurance.name}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href={arrayData.PPO_Plan.link}>
            {arrayData.PPO_Plan.name}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Registration_Flyer.link}
          >
            {arrayData.Registration_Flyer.name}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href={arrayData.Teladoc.link}>
            {arrayData.Teladoc.name}
          </a>
        </li>
      </ul>
    </div>
  );
};

const dentalVisionForms = (arrayData, index) => {
  return (
    <div className="text-left" key="2">
      <div className="font-bold text-blue-900 text-xl">
        Humana (Dental & Vision) Insurance
      </div>
      <ul>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Cervello_Dental.link}
          >
            {arrayData.Cervello_Dental.name}
          </a>
        </li>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Cervello_Vison.link}
          >
            {arrayData.Cervello_Vison.name}
          </a>
        </li>
        <li>
          {" "}
          <a target="_blank" rel="noreferrer" href={arrayData.EEA.link}>
            Employment Enrollment Form
          </a>
        </li>
      </ul>
    </div>
  );
};

const retirementForms = (arrayData, index) => {
  return (
    <div className="text-left" key="3">
      <div className="font-bold text-blue-900 text-xl">401k Retirement Plan</div>
      <p className="text-black text-base">
        {" "}
        Automatic enrollment after 90 days of employment.
      </p>
      <ul>
        <li>
          {" "}
          <a target="_blank" rel="noreferrer" href={arrayData["401k"].link}>
            {arrayData["401k"].name}
          </a>
        </li>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData["401k_Highlights"].link}
          >
            {arrayData["401k_Highlights"].name}
          </a>
        </li>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Beneficiary_Forms.link}
          >
            {arrayData.Beneficiary_Forms.name}
          </a>
        </li>
        <li>
          {" "}
          <a target="_blank" rel="noreferrer" href={arrayData.Invest.link}>
            {arrayData.Invest.name}
          </a>
        </li>
      </ul>
    </div>
  );
};

const stdLifeForms = (arrayData, index) => {
  return (
    <div className="text-left" key={index}>
      <div className="font-bold text-blue-900 text-xl">UNUM (STD & Life Insurance)</div>
      <ul>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Beneficiary_Form.link}
          >
            {arrayData.Beneficiary_Form.name}
          </a>
        </li>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Cervello_BasicLife.link}
          >
            {arrayData.Cervello_BasicLife.name}
          </a>
        </li>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Cervello_STD.link}
          >
            {arrayData.Cervello_STD.name}
          </a>
        </li>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={arrayData.Enrollment_Group_Insurance.link}
          >
            {arrayData.Enrollment_Group_Insurance.name}
          </a>
        </li>
      </ul>
    </div>
  );
};

export {
  healthInsuranceForms,
  dentalVisionForms,
  retirementForms,
  stdLifeForms,
};
