export const stores = [
  {
    name: "Finance",
    description: `Finance manages every monetary aspect of the company.  From day-to-day accounting to financial controls, Finance maintains Cervello's fiscal health with the proper checks and balances in place to ensure accuracy.  Strategic financial planning aligns daily financial decisions with Cervello's long-term goals.  Finance provides corporate leaders with the data needed to make informed business decisions for the company.`,
    request: [
      {
        name: "Finance Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUOFVYUENPWTZRMUlOVVVFWDhBMTJPQ0NFWC4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FFinance&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
  {
    name: "Human Resources",
    description: `Human Resources assists with many employee-related needs such as recruiting, hiring, onboarding, compensation, benefits, organization development and culture, and training.  The goal is to keep employees satisfied, motivated, and retained by assisting them with any employment questions or concerns that they may have. Cervello's Human Resources strives to enhance an employee's satisfaction and performance while ensuring the company adheres to labor regulations and prevents impediments to a strong workforce.`,
    request: [
      {
        name: "Human Resources Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUM1dJUU1JUFE2QUVGMExSVFRGRkdXWDFNUi4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FHuman%20Resources&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
  {
    name: "Business Management",
    description:
      "The Business Management store drives process improvement initiatives throughout the Cervello organization and focuses on streamlining efficiencies utilizing a Better Buying Power (BBP) approach and lean six sigmaprocess efficiency. Process improvement areas include but are not limited to bid and proposal development, product support, supply chain, performance based logistics, and quality control. Our end-to-end contract management strategy streamlines contract processes from start to finish by generating data-driven contracts and routing them quickly and efficiently for review, redlining and approval.",
    request: [
      {
        name: "Business Management Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUQUJETFBDNU1ESzFTNFdBNk1TWVU4T1g0MS4u",
      },
      {
        name: "Corporate Badge Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUN0RYRDNYTUU2SEM5MlAyWkdMQUhRWE9ERy4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FBusiness%20Management&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
  {
    name: "Global Workforce Development",
    description:
      "Because Cervello Global Corporation has grown to be a worldwide entity, we must focus on growing our workforce both domestically and internationally.  Developing and growing our workforce requires professional networking in order to recruit the world-class talent that we seek. While recruitment may be a Human Resources capability, it often begins with Global Workforce Development. If you are aware of talent that fits our team and can help us grow, Global Workforce Development can pursue those leads.",
    request: [
      {
        name: "GWFD Supply Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUMEwwOFZXQkFPUjNIV1c5QldGOE02WDVRMi4u",
      },
      {
        name: "GWFD Distribution Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUM0VUSEs5OE9FSVkyVUkzTzcxSjRWTUE5Wi4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FGlobal%20Workforce%20Development&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
  {
    name: "Technology",
    description: [
      `The Technology Team provides support for Cervello Global's Information Technology(IT) Infrastructure to include new product initiatives, software and hardware capabilities, help desk support and, in the future, extenral customer requirements.`,
      "The Technology Team focuses on efforts to streamline operations through full stack product development utilizing an Agile Methodolgy.",
      " The Help Desk focuses on supporting user software and hardware needs to include new capability requests and trouble support.",
      " The extenral Customer Section focuses on hardware and software requirements for customers outside of Cervello Global.",
      " Technology areas include software development, cyvbersecurity and, in the future, physical equipment for Warfighter training. Out internal process, from end to end, allow maximum flexibilty to ensure our products meet or exceed gaps and requirements.",
    ],
    request: [
      {
        name: "Computer Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUQ1dHRUVFOUJMQldTNklUSTZUOE1JMlBDMC4u",
      },
      {
        name: "Helpdesk Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUNFpaRlE3SDdJTDdLNk5aT1U4UVFPTTJRNC4u",
      },
      {
        name: "Product Development Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUOVJZSUNWWEVVUkk5MEpSN1JUT1RORERDMy4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FTechnology&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
  {
    name: "Publications and Media",
    description:
      "The Publications and Media Store is a one-stop-shop for all written and graphic needs.  From editing services to building custom graphics, we strive to meet your needs.  We can assist with drafting verbiage based on any requirements. Media such as pamphlets, post cards, and beyond can be developed and edited in-house. The Publications and Media Store will take your ideas and turn them into realities.",
    request: [
      {
        name: "Publications and Media Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUNkRTWTExVVBZS1paUUM0QUZVUEZMOTFZOC4u",
      },
      {
        name: "Business Card Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUQ1dSSTBQMThEOTJZTkZaVEpBQVNBQlI5VC4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FPublications%20and%20Media&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
  {
    name: "Corporate Partnerships",
    description: `Current and future partnerships allow Cervello Global Corporation to grow and evolve with the right collaborations. Our team believes in partnering with cutting edge companies with a similar vision to our own. We look for symbiotic relationships that enhance our capabilities while also enhancing our partner's capabilities. Cervello's goal is to seek strategic and tactical solutions in every partnership that is formed. `,
    request: [
      {
        name: "Corporate Partnerships Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUNzdKOTFFWk4yM1hUME1RQ0NHTUEwQUFKUS4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FCorporate%20Partnerships&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
  {
    name: "Corporate Security",
    description:
      "At Cervello Global Corporation, security is of the utmost importance in every facet of our business. Corporate Security handles everything from procuring military base access to assisting with access to government systems requiring specific credentials. Our Corporate Security Director assists contract employees with many of their security needs.",
    request: [
      {
        name: "CAC Request",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUMDlHRE9CNzhZUDcyNzBaRzZEUFEwVjNTOC4u",
      },
      {
        name: "Corporate Security General Request Form",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUOUxGWVFGTDlDQTE3ODRLWTFOTVJZTlNHTS4u",
      },
      {
        name: "Visit Request Form (VAR)",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=IOE-JuOM9EC2xvK4xVi4WVWfRh2Fn_pHhQkZSL8rsixUQ1lUR1VXWlNaM0lXSUVHVFBKSFhMS1dWNy4u",
      },
    ],
    sharepointLink:
      "https://cervtech.sharepoint.com/sites/CervelloCorporateStorefronts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCervelloCorporateStorefronts%2FShared%20Documents%2FCorporate%20Security&viewid=d4c289b0%2Ddde2%2D49f2%2D9efd%2Db60de92a5d7a",
  },
];
