import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Users, OnBoarding } from '../models';
import { Auth } from 'aws-amplify';

export default function ManagerChecklist() {
	const [onBoarding, setOnBoarding] = useState('');
	const [data, setData] = useState([]);
	const [physical, setPhysical] = useState([]);
	const [benefits, setBenefits] = useState([]);
	const [user, setUser] = useState('');
	const disabledCard =
		'bg-gray-500 opacity-25 border-4 rounded-md border-gray-700 shadow-2xl mb-2 mx-auto container grid';

	const activeCard =
		'bg-white border-4 rounded-md border-gray-700 shadow-2xl mb-2 mx-auto container grid';

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const models = await DataStore.query(Users);

		try {
			const usersEmail = await Auth.currentAuthenticatedUser();

			const userLoggedIn = models.filter((model) => {
				return model.email === usersEmail.attributes.email;
			});

			setData(userLoggedIn);
			setUser(userLoggedIn[0].first_name);
			setOnBoarding(userLoggedIn[0].OnBoarding.id);
			setPhysical(userLoggedIn[0].OnBoarding.Physical_Items);
			setBenefits(userLoggedIn[0].OnBoarding.Benefits);
		} catch (error) {
			return;
		}
	};

	const completeOnboarding = () => {
		data.map(async (data) => {
			if (
				data.OnBoarding.Unanet.status === true &&
				data.OnBoarding.Paychex.status === true &&
				data.OnBoarding.Ring_Central.status === true &&
				data.OnBoarding.MS_Office.status === true
			) {
				const original = await DataStore.query(OnBoarding, onBoarding);

				await DataStore.save(
					OnBoarding.copyOf(original, (updated) => {
						if (updated.CheckList_Completion === false) {
							updated.CheckList_Completion = true;
						} else {
							updated.CheckList_Completion = false;
						}
					})
				);

				return alert(
					'Please notify your manager that you have completed your onboarding process to continue'
				);
			} else {
				alert('Please complete all the checklist');
			}
		});
	};

	const repeatData = (arrayData, index, nextName) => {
		return (
			<div className={arrayData.status ? disabledCard : activeCard}>
				<div className='text-black'>
					<h1 className='grid justify-start'>{arrayData.name}</h1>
					<div>
						<p>{arrayData.description}</p>
						<ul className='list-decimal'>
							{arrayData.tasks.map((task, index) => {
								return (
									<li key={index}>
										<p>{task}</p>
									</li>
								);
							})}
						</ul>
					</div>
					<div className='flex justify-center'>
						<button
							disabled={arrayData.status}
							onClick={async () => {
								const original = await DataStore.query(
									OnBoarding,
									index.OnBoarding.id
								);

								await DataStore.save(
									OnBoarding.copyOf(original, (updated) => {
										if (arrayData.name === 'Unanet') {
											updated[arrayData.objectName].status = true;
										} else {
											updated[nextName].status = false;
											updated[arrayData.objectName].status = true;
										}
									})
								);

								getData();
							}}
							className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mb-4 mt-5'>
							Click when all steps are completed
						</button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<h1 className='text-white'> {user}'s Manger's Checklist</h1>
			<div>
				{data.map((x, i) => {
					return (
						<div key={i}>
							{repeatData(x.OnBoarding.Paychex, x, 'MS_Office')}
							{repeatData(x.OnBoarding.MS_Office, x, 'Ring_Central')}
							{repeatData(x.OnBoarding.Ring_Central, x, 'Unanet')}
							{repeatData(x.OnBoarding.Unanet, x)}

							<div className='bg-white border-4 rounded-md  shadow-2xl mb-2 mx-auto container grid'>
								<h1 className='text-left'>Security (FSO)</h1>
								<div className='container'>
									<ul className='list-disc text-left text-black'>
										<li>
											Cervello (53PR6) as owning CAGE at TS Level (Corporate
											Security)
										</li>
										<li>
											Agreements and Attestations (SF-312), Attestation, NATO
											Read-in, Briefings
										</li>
										<ul>
											<li className='font-bold'>
												Request Videocall with Manager
											</li>
										</ul>
										<li>
											Security, Insider Threat, and Counterintelligence training
											course and briefing(s)
										</li>
										<ul>
											<li className='font-bold'>
												Complete training online through DoD sites; report to
												manager with certificates once training is complete
											</li>
										</ul>
										<li>
											VARs issues (must have SMO, Security POC/POC Phone /POC
											Email; Purpose of Visit; Access Level)
										</li>
										<ul>
											<li className='font-bold'>
												Once onboarding is complete, submit a VAR request from
												the employee dashboard
											</li>
										</ul>
										<li>Receipt of DD254 from customer (Corporate Security)</li>
										<li>Set up CAC appointment</li>
										<ul>
											<li className='font-bold'>
												Employee must set up her/his own CAC appointment after
												Corporate Security submits all paperwork to the
												government customer - if required
											</li>
										</ul>
										<li>Facility Access Badging</li>
										<ul>
											<li className='font-bold'>
												Must do this on site at designated DoD workplace
											</li>
										</ul>
									</ul>
									<button className='bg-white border-4 rounded-md  shadow-2xl mb-2 text-black mt-10'>
										Click when all steps are completed
									</button>
								</div>
							</div>
						</div>
					);
				})}
			</div>

			<div className='bg-white border-4 rounded-md  shadow-2xl mb-2 mx-auto container grid'>
				<h1 className='text-left'>Physical Items</h1>
				<ul className='list-disc'>
					{physical.map((x, i) => {
						return (
							<li key={i} className='text-black text-left'>
								{x}
							</li>
						);
					})}
				</ul>
			</div>

			<div className='bg-white border-4 rounded-md  shadow-2xl mb-2 mx-auto container grid'>
				<h1 className='text-left'>Benefits</h1>
				<ul className='list-disc'>
					{benefits.map((x, i) => {
						return (
							<li key={i} className='text-black text-left'>
								{x}
							</li>
						);
					})}
				</ul>
			</div>

			<button
				onClick={completeOnboarding}
				className='bg-white border-4 rounded-md  shadow-2xl mb-2 text-black mt-10'>
				Click here once you have completed all onboarding tasks
			</button>
		</div>
	);
}
