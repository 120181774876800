import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataStore } from "@aws-amplify/datastore";
import { Users, OnBoarding, Managers } from "../../../models";
import { handleError } from "../../../Auth/auth";
import { Auth } from "aws-amplify";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FAQ from "./FAQ";

export default function ManagerEmployeeView() {
  const [search, setSearch] = useState("");
  const [userCode, setCode] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [confirmedUser, setConfirmedUser] = useState([]);
  const [unauthenticated, setUnauthenticated] = useState([]);
  const [completeduser, setCompletedUser] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userEmail = authenticatedUser.attributes.email;

      const managersModel = await DataStore.query(Managers, (c) =>
        c.email("eq", userEmail)
      );

      const managersEmployees = (await DataStore.query(Users)).filter(
        (c) =>
          c.subsection === managersModel[0].subsection ||
          c.managersID === managersModel[0].id
      );

      const confirmedUser = managersEmployees.filter(
        (c) => c.OnBoarding.isConfirmed === false && c.authenticated === true
      );

      const completedUser = managersEmployees.filter(
        (c) =>
          c.OnBoarding.CheckList_Completion === true &&
          c.OnBoarding.isConfirmed === true &&
          c.email !== managersModel[0].email
      );

      const unauthenticatedUsers = managersEmployees.filter(
        (c) => c.authenticated === false
      );

      setConfirmedUser(confirmedUser);

      setUnauthenticated(unauthenticatedUsers);
      setCompletedUser(completedUser);
    } catch (error) {
      return;
    }
  };

  const onchange = (e) => setSearch(e.target.value);

  const authUser = (e) => setCode(e.target.value);

  return (
    <div className="mx-auto text-black">
      <div className="mb-6 py-12 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl">
        <div className="pb-12 text-blue-900 font-bold text-3xl">
          Manager Administration
        </div>
        <div className="pb-12">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-6 rounded-lg"
            onClick={() => (window.location.href = "Manager-SignUp")}
          >
            Sign-Up New Employee
          </button>
        </div>
        <div className="container overflow-x-auto rounded-md bg-white text-black pb-16">
          <div className="container text-black mb-32">
            <Tabs defaultActiveKey="welcome" id="tabs" className="mb-3">
              <Tab eventKey="welcome" title="Welcome">
                <div className="container">
                  <div className="col-md-8">
                    <div className="text-left text-2xl text-blue-900 font-bold">
                      Welcome
                    </div>
                    <div className="text-left">
                      <p className="text-base">
                        Welcome to the Manager Administration Screen. This
                        system is designed to make managing employee set-up and
                        onboarding seamless and intuitive. From here, you can:
                      </p>
                      <ul className="text-base list-disc">
                        <li>Sign up new users for Intranet access</li>
                        <li>Authenticate users for Intranet access</li>
                        <li>Track new users' onboarding progress</li>
                        <li>
                          View list of department members who have completed
                          onboarding
                        </li>
                      </ul>
                      <p className="text-base">
                        Check out the FAQ tab if you have questions about this
                        page or how to sign-up, authenticate, or verify
                        completed onboarding for a new employee.
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="authenticate" title="Authenticate">
                <div className="col-md-8">
                  <div className="text-left text-2xl text-blue-900 font-bold">
                    Authenticate New User
                  </div>
                  <div className="text-left">
                    <p className="text-base">
                      Users listed here require authentication before they can
                      reset their password and log in for the first time. When
                      you sign up a new user, they will receive an email with an
                      authorization code. Ask the employee for this
                      authorization code. Enter the authorization code in the
                      Authorization Code field and click Submit.
                    </p>

                    <p className="text-base">
                      If the code has expired, you can send the employee a new
                      code by clicking Resend Code.
                    </p>
                  </div>
                </div>
                {unauthenticated.length === 0 ? (
                  <div className="border-solid border-2 border-blue-900">
                    <div className="text-lg text-blue-900 font-bold">
                      All users are authenticated. Click on the Verify
                      Onboarding tab to see users in process.
                    </div>
                  </div>
                ) : (
                  <Row xs={1} md={3}>
                    {unauthenticated.map((employee, i) => {
                      return (
                        <div>
                          <Row>
                            <Col>
                              <Card className="rounded-lg">
                                <Card.Title className="p-2 text-blue-900 border">
                                  <span className="font-bold">
                                    {employee.first_name} {employee.last_name}
                                  </span>
                                  <div className="text-sm">
                                    {employee.email}
                                  </div>
                                </Card.Title>
                                <Card.Body>
                                  <Row>
                                    <Col sm={12} md={6}>
                                      <input
                                        type="text"
                                        placeholder="Authorization Code"
                                        className="text-center border-black border-2"
                                        onChange={authUser}
                                      />
                                    </Col>
                                    <Col sm={12} md={6}>
                                      <button
                                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg`}
                                        type="button"
                                        disabled={disabledButton}
                                        onClick={async (e) => {
                                          setDisabledButton(true);

                                          const currentManager =
                                            await Auth.currentSession();

                                          try {
                                            await Auth.confirmSignUp(
                                              employee.email,
                                              userCode
                                            );

                                            if (employee.title === "Managers") {
                                              await axios.post(
                                                `${process.env.REACT_APP_VERIFY_EMAIL}`,
                                                {
                                                  emailIdentity: employee.email,
                                                }
                                              );

                                              const selectedManagers =
                                                await DataStore.query(
                                                  Managers,
                                                  (x) =>
                                                    x.email(
                                                      "eq",
                                                      employee.email
                                                    )
                                                );

                                              await DataStore.save(
                                                Managers.copyOf(
                                                  selectedManagers[0],
                                                  (updated) => {
                                                    updated.authenticated = true;
                                                  }
                                                )
                                              );

                                              const selectedEmployee =
                                                await DataStore.query(
                                                  Users,
                                                  employee.id
                                                );

                                              await DataStore.save(
                                                Users.copyOf(
                                                  selectedEmployee,
                                                  (updated) => {
                                                    updated.authenticated = true;
                                                  }
                                                )
                                              );

                                              await axios.post(
                                                `${process.env.REACT_APP_SEND_EMAIL}`,
                                                {
                                                  emailTo: employee.email,
                                                  emailFrom:
                                                    currentManager.idToken
                                                      .payload.email,
                                                  subject:
                                                    "Cervello Global Account Set up",
                                                  message: `Hi, ${employee.first_name}. Welcome to Cervello Global. Your account was successfully registered.
																			Next, please do the following:
																			1. Go to the Cervello login screen here: https://www.cervello.global/Americas/Login
																			2. Click 'Reset Password' at the bottom of the form.
																			3. After you log in with your new password, go through your onboarding checklist and message your manager when you're done.`,
                                                }
                                              );
                                            } else {
                                              const selectedEmployee =
                                                await DataStore.query(
                                                  Users,
                                                  employee.id
                                                );
                                              await DataStore.save(
                                                Users.copyOf(
                                                  selectedEmployee,
                                                  (updated) => {
                                                    updated.authenticated = true;
                                                  }
                                                )
                                              );
                                              await axios.post(
                                                `${process.env.REACT_APP_SEND_EMAIL}`,
                                                {
                                                  emailTo: employee.email,
                                                  emailFrom:
                                                    currentManager.idToken
                                                      .payload.email,
                                                  subject:
                                                    "Cervello Global Account Set up",
                                                  message: `Hi, ${employee.first_name}. Welcome to Cervello Global. Your account was successfully registered.
																			Next, please do the following:
																			1. Go to the Cervello login screen here: https://www.cervello.global/Americas/Login
																			2. Click 'Reset Password' at the bottom of the form.
																			3. After you log in with your new password, go through your onboarding checklist and message your manager when you're done.`,
                                                }
                                              );
                                            }
                                            alert(
                                              `${employee.email} is fully authenticated`
                                            );
                                          } catch (error) {
                                            handleError(error.message);
                                            alert(error.message);
                                            setDisabledButton(false);
                                            return;
                                          }
                                          getUsers();
                                          setDisabledButton(false);
                                        }}
                                      >
                                        Submit
                                      </button>
                                    </Col>
                                  </Row>
                                </Card.Body>
                                <Card.Footer>
                                  {" "}
                                  <button
                                    className={`bg-gray-300 hover:bg-red-700 hover:text-white text-black font-bold py-2 px-4 rounded-lg`}
                                    type="button"
                                    onClick={async () => {
                                      try {
                                        const resendCode =
                                          await Auth.resendSignUp(
                                            employee.email
                                          );
                                        alert("Confirmation Code resent!");
                                        return resendCode;
                                      } catch (err) {
                                        console.log(
                                          "error resending code: ",
                                          err
                                        );
                                        return;
                                      }
                                    }}
                                  >
                                    Resend Code
                                  </button>
                                </Card.Footer>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </Row>
                )}
              </Tab>
              <Tab eventKey="verify-onboarding" title="Verify Onboarding">
                <div className="col-md-8">
                  <div className="text-left text-2xl text-blue-900 font-bold">
                    Onboarding Verification
                  </div>
                  <div className="text-left">
                    <p className="text-base">
                      You can track users' progress through the Onboarding
                      Checklist here. Click the Confirm User button once the
                      user has completed all Onboarding Checklist steps to
                      verify the user and grant Intranet Dashboard access.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="container">
                    {confirmedUser.length > 0 ? (
                      <Row xs={1} md={3}>
                        {confirmedUser.map((employee, i) => {
                          return (
                            <div>
                              <Row>
                                <Col>
                                  <Card className="rounded-lg">
                                    <Card.Title className="p-2 text-blue-900 border">
                                      <span className="font-bold">
                                        {employee.first_name}{" "}
                                        {employee.last_name}
                                      </span>
                                      <div className="text-sm">
                                        {employee.email}
                                      </div>
                                    </Card.Title>
                                    <Card.Text className="container">
                                      <div className="row text-base w-full">
                                        <div className="col-6 text-left text-blue-900 font-bold">
                                          Paychex:{" "}
                                        </div>
                                        {!employee.OnBoarding.Paychex.status &&
                                        employee.OnBoarding.MS_Office.status ? (
                                          <div className="col-6 bg-yellow-300 text-center">
                                            In Progress
                                          </div>
                                        ) : !employee.OnBoarding.Paychex
                                            .status &&
                                          !employee.OnBoarding.MS_Office
                                            .status ? (
                                          <div className="col-6 bg-green-500 text-white text-center">
                                            Complete
                                          </div>
                                        ) : (
                                          <div className="col-6 bg-red-500 text-white text-center">
                                            Incomplete
                                          </div>
                                        )}
                                      </div>

                                      <div className="row text-base w-full">
                                        <div className="col-6 text-left text-blue-900 font-bold">
                                          Microsoft Office:{" "}
                                        </div>
                                        {!employee.OnBoarding.MS_Office
                                          .status &&
                                        employee.OnBoarding.Ring_Central
                                          .status ? (
                                          <div className="col-6 bg-yellow-300 text-center">
                                            In Progress
                                          </div>
                                        ) : !employee.OnBoarding.MS_Office
                                            .status &&
                                          !employee.OnBoarding.Ring_Central
                                            .status ? (
                                          <div className="col-6 bg-green-500 text-white text-center">
                                            Complete
                                          </div>
                                        ) : (
                                          <div className="col-6 bg-red-500 text-white text-center">
                                            Incomplete
                                          </div>
                                        )}
                                      </div>

                                      <div className="row text-base w-full">
                                        <div className="col-6 text-left text-blue-900 font-bold">
                                          Ring Central:{" "}
                                        </div>
                                        {!employee.OnBoarding.Ring_Central
                                          .status &&
                                        employee.OnBoarding.Security.status ? (
                                          <div className="col-6 bg-yellow-300 text-center">
                                            In Progress
                                          </div>
                                        ) : !employee.OnBoarding.Ring_Central
                                            .status &&
                                          !employee.OnBoarding.Security
                                            .status ? (
                                          <div className="col-6 bg-green-500 text-white text-center">
                                            Complete
                                          </div>
                                        ) : (
                                          <div className="col-6 bg-red-500 text-white text-center">
                                            Incomplete
                                          </div>
                                        )}
                                      </div>

                                      <div className="row text-base w-full">
                                        <div className="col-6 text-left text-blue-900 font-bold">
                                          Security
                                        </div>
                                        {!employee.OnBoarding.Security.status &&
                                        employee.OnBoarding.Unanet.status ? (
                                          <div className="col-6 bg-yellow-300 text-center">
                                            In Progress
                                          </div>
                                        ) : !employee.OnBoarding.Security
                                            .status &&
                                          !employee.OnBoarding.Unanet.status ? (
                                          <div className="col-6 bg-green-500 text-white text-center">
                                            Complete
                                          </div>
                                        ) : (
                                          <div className="col-6 bg-red-500 text-white text-center">
                                            Incomplete
                                          </div>
                                        )}
                                      </div>

                                      <div className="row text-base w-full">
                                        <div className="col-6 text-left text-blue-900 font-bold">
                                          Unanet:{" "}
                                        </div>
                                        {!employee.OnBoarding.Unanet.status &&
                                        employee.OnBoarding.Benefits_Forms
                                          ?.status ? (
                                          <div className="col-6 bg-yellow-300 text-center">
                                            In Progress
                                          </div>
                                        ) : !employee.OnBoarding.Unanet
                                            .status &&
                                          !employee.OnBoarding.Benefits_Forms
                                            ?.status ? (
                                          <div className="col-6 bg-green-500 text-white text-center">
                                            Complete
                                          </div>
                                        ) : (
                                          <div className="col-6 bg-red-500 text-white text-center">
                                            Incomplete
                                          </div>
                                        )}
                                      </div>

                                      <div className="row text-base w-full">
                                        <div className="col-6 text-left text-blue-900 font-bold">
                                          Benefits:{" "}
                                        </div>
                                        {!employee.OnBoarding.Benefits_Forms
                                          ?.status &&
                                        !employee.OnBoarding
                                          .CheckList_Completion ? (
                                          <div className="col-6 bg-yellow-300 text-center">
                                            In Progress
                                          </div>
                                        ) : !employee.OnBoarding.Benefits_Forms
                                            ?.status &&
                                          employee.OnBoarding
                                            .CheckList_Completion ? (
                                          <div className="col-6 bg-green-500 text-white text-center">
                                            Complete
                                          </div>
                                        ) : (
                                          <div className="col-6 bg-red-500 text-white text-center">
                                            Incomplete
                                          </div>
                                        )}
                                      </div>

                                      <div className="row text-base w-full">
                                        <div className="col-6 text-left text-blue-900 font-bold">
                                          Onboarding Done?:{" "}
                                        </div>
                                        {employee.OnBoarding
                                          .CheckList_Completion ? (
                                          <div className="col-6 bg-green-500 font-bold text-white text-center">
                                            YES
                                          </div>
                                        ) : (
                                          <div className="col-6 bg-red-500 text-white font-bold text-center">
                                            NO
                                          </div>
                                        )}
                                      </div>
                                      <div className="row py-4 md:px-24">
                                        <Button
                                          className=" bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded"
                                          type="button"
                                          size="md"
                                          onClick={async () => {
                                            const original =
                                              await DataStore.query(
                                                OnBoarding,
                                                employee.OnBoarding.id
                                              );

                                            // await axios.get(
                                            // 	`${process.env.REACT_APP_SEND_EMAIL}`,
                                            // 	{
                                            // 		params: {
                                            // 			emailTo: process.env.REACT_APP_VALS_EMAIL,
                                            // 			emailFrom: currentManager,
                                            // 			subject: 'Cervello Global Account Set up',
                                            // 			message: `Hi Val, ${currentManager} just confirmed  ${employee.first_name} ${employee.last_name} just completed thier on boarding process`,
                                            // 		},
                                            // 	}
                                            // );

                                            await DataStore.save(
                                              OnBoarding.copyOf(
                                                original,
                                                (updated) => {
                                                  if (
                                                    employee.OnBoarding
                                                      .isConfirmed === false
                                                  ) {
                                                    updated.isConfirmed = true;
                                                  } else {
                                                    updated.isConfirmed = false;
                                                  }
                                                }
                                              )
                                            );

                                            getUsers();
                                          }}
                                        >
                                          Confirm User
                                        </Button>
                                      </div>
                                    </Card.Text>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </Row>
                    ) : (
                      <div className="border-solid border-2 border-blue-900">
                        <div className="text-lg text-blue-900 font-bold">
                          No unverified users. Click on the Completed tab to see
                          all verified users in your department.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="completed" title="Completed">
                <div className="col-md-8">
                  <div className="text-left text-2xl text-blue-900 font-bold">
                    Confirmed Users
                  </div>
                  <div>
                    <p className="text-left text-base">
                      These users are authenticated for Intranet login access,
                      completed Onboarding, and have been verified for Intranet
                      Dashboard access by their manager.
                    </p>
                  </div>
                  <div className="col-md-2 pb-2">
                    <input
                      type="text"
                      placeholder="Employee Search"
                      className="border-black text-center rounded-lg border-1 mt-4 px-4"
                      value={search}
                      onChange={onchange}
                    />
                  </div>
                </div>

                <table className="w-full mt-6 table-auto border-solid border-2 border-black">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {completeduser.length >= 0 ? (
                      completeduser.map((employee, i) => {
                        return (
                          <tr
                            className="border-solid border-2 border-black"
                            key={i}
                          >
                            <td>
                              {employee.first_name} {employee.last_name}
                            </td>
                            <td>{employee.email}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-solid border-2 border-black">
                        <td>{process.env.REACT_APP_DATABASE_ERROR}</td>
                      </tr>
                    )}{" "}
                  </tbody>
                </table>
              </Tab>
              <Tab eventKey="faq" title="FAQ">
                <FAQ />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
