import { React } from "react";

export default function Construction() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className=" mb-12 pb-12 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Pardon Our Dust...
          </div>
        </div>
        <div className="container col-md-4 mt-5">
          <div className="mx-auto">
            <div>
              <div className="w-full blueText font-bold text-3xl text-center pb-4">
                Thanks for visiting. This page is undergoing maintenance. Check
                back later!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
