import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Missile from "./hypersonicsMissile.jpg";

export default function Hypersonics() {
  const [logisticsDetails, setLogisticsDetails] = useState(false);

  return (
    <div className="md:-mt-36 text-black">
      <div className="mb-6 bg-white">
        <row>
          <div>
            <Image src={Missile}></Image>
          </div>
          <div className="col-md-6 mx-auto pb-12">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900 mt-5">
                    Hypersonics & Emerging Technologies
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    The 2022 National Defense Strategy noted that “the United
                    States' technological edge has long been a foundation of our
                    military advantage. The Department will support the
                    innovation ecosystem, both at home and in expanded
                    partnerships with our Allies and partners. We will fuel
                    research and development for advanced capabilities,
                    including in directed energy, hypersonics, integrated
                    sensing, and cyber.” Hypersonics ensure that we are able to
                    fight and win the wars of the future.
                  </p>
                  <p className="text-left text-base">
                    With the start of Joint Hypersonic Strike Planning and
                    Execution Command and Control (J-HyperSPEC2) Joint
                    Feasibility Study (JFS), Cervello provided engineering,
                    test, and analysis services to U.S. Strategic Command
                    (USSTRATCOM). Cervello contractors provide subject matter
                    expertise in the areas of Hypersonic Strike Weapon (HSW) C2,
                    Hypersonic integration, and Hypersonic Modeling and
                    Simulation writing a DoD level Operational HSW CONOPS for
                    which the supported directorates won the Cross Functional
                    Team Award for the 2nd Quarter of 2021.
                  </p>
                  <p className="text-left text-base">
                    Currently, Cervello is at the vanguard of support for the
                    Joint-Development of Hypersonic Weapons Employment (J-DoHE)
                    quick reaction tests (QRT) serving as follow-on efforts to
                    develop operational TTPs for the implementation of the
                    hypersonic weapons CONOPS that was developed from the
                    J-HyperSPEC2 Joint Test at USSTRATCOM.
                  </p>
                  <div className="pb-1">
                    <div
                      onClick={() => setLogisticsDetails(!logisticsDetails)}
                      aria-controls="example-collapse-text"
                      aria-expanded={logisticsDetails}
                      className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                    >
                      <div className="col-10 text-left text-white">
                        Core Capabilities
                      </div>
                      {!logisticsDetails ? (
                        <div className="col-2 text-right text-white">+</div>
                      ) : (
                        <div className="col-2 text-right text-white">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={logisticsDetails}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-black text-left"
                        >
                          <ul className=" list-disc text-base text-white">
                            <li>
                              Incorporate emerging deterrent strategies that are
                              synchronized with operational imperatives and
                              mission level concepts
                            </li>
                            <li>
                              Establish credible strategic deterrence early and
                              across multiple domains to meet strategic and
                              defensive objectives
                            </li>
                            <li>
                              Set conditions for success and/or dissuade an
                              adversary from escalation
                            </li>
                            <li>
                              Develop a Concept of Operations Plan (CONOPS) that
                              will assist in deterring specific adversaries
                            </li>
                            <li>
                              Address strategic efforts focusing on Dynamic
                              Timing, Cyber and Escalation Dynamics
                            </li>
                            <li>Support strategic communications</li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </row>
      </div>
    </div>
  );
}
