import React from "react";
import "./parker.css";
import { Row, Col, Card } from "react-bootstrap/";
import ParkerPic from "./parker_pic.png";
export default function Parker() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Kim Parker - Vice President, Business, Capture & Proposal
            Development
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={ParkerPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                Kim Parker has over 22 years of experience in overseeing
                business operations of private and publicly owned companies. She
                is a highly qualified Operations and Financial Officer with a
                demonstrated history in the full life cycle of U.S. Government
                contracting for over 12 years. Kim is a strong team player with
                an excellent work ethic and advanced complex problem-solving
                skills. She has extensive experience and knowledge in Service
                Contract Act (SCA) administration and has been responsible for
                the general management of more than 200 employees, independent
                contractors, and associates. Ms. Parker's previous positions
                before joining Cervello include Director of Business Operations
                and Chief Operating Officer. She is a proven operations and
                financial professional with a bachelor's degree focused on
                Business Administration and Management from Old Dominion
                University.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
