import React, { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Users, OnBoarding, Managers } from "../models";
import { Auth } from "aws-amplify";
import { app } from "../base";
import { useParams, useHistory } from "react-router-dom";
import {
  healthInsuranceForms,
  dentalVisionForms,
  retirementForms,
  stdLifeForms,
} from "./FormFunctions";
import { Form } from "react-bootstrap/";
const db = app.firestore();

export default function Checklist() {
  const [onBoarding, setOnBoarding] = useState("");
  const [data, setData] = useState([]);
  const [user, setUser] = useState("");
  const [forms, setForms] = useState([]);
  const [disabledButton, setdisabledButton] = useState("visible");
  const [securityForms, setSecurityForms] = useState([]);
  const [physicalForms, setPhysicalForms] = useState([]);
  const [currentUserLoggedIn, setcurrentUserLoggedIn] = useState([]);
  const [certify, setCertify] = useState(false);
  const [nameCert, setNameCert] = useState("");
  const [certButton, setCertButton] = useState(true);
  const { slug } = useParams();
  let history = useHistory();

  const [modalStyle1, setModalStyle1] = useState(
    "hidden fixed z-1 left-0 top-0 w-full h-full overflow-auto"
  );
  const [modalStyle2, setModalStyle2] = useState(
    "hidden fixed z-1 left-0 top-0 w-full h-full overflow-auto"
  );

  const disabledCard =
    "bg-gray-800 col-md-10 opacity-25 border-2 rounded-lg border-gray-700 mb-2 mx-auto container grid";

  const activeCard =
    "bg-white col-md-10 border-3 border-blue-900 rounded-lg mb-2 mx-auto container grid";

  useEffect(() => {
    const getUserConfirm = async () => {
      try {
        const usersEmail = await Auth.currentAuthenticatedUser();

        const usersModel = await DataStore.query(Users, (c) =>
          c.email("eq", usersEmail.attributes.email)
        );

        setcurrentUserLoggedIn(usersModel);
        console.log(usersModel);

        if (usersModel[0].OnBoarding.isConfirmed)
          return history.push(`/${slug}/Internal-Dashboard`);
      } catch (error) {
        return error;
      }
    };
    fetchForms();
    getData();
    fetchSecurityForms();
    fetchPhysicalForms();
    getUserConfirm();
  }, [slug, history]);

  const handleName = (e) => {
    setNameCert(e.target.value.toLowerCase());
  };

  const testName = async () => {
    const usersEmail = await Auth.currentAuthenticatedUser();
    const usersModel = await DataStore.query(Users, (c) =>
      c.email("eq", usersEmail.attributes.email)
    );
    const dataName = (
      usersModel[0].first_name +
      " " +
      usersModel[0].last_name
    ).toLowerCase();
    if (dataName === nameCert) {
      setCertify(true);
    }
  };
  testName();

  const fetchPhysicalForms = async () => {
    const physicalCollection = await db.collection("PhysicalForms").get();
    const physicalFormData = physicalCollection.docs.map((doc) => {
      return doc.data();
    });

    setPhysicalForms(physicalFormData);
  };

  const fetchSecurityForms = async () => {
    const securityCollection = await db.collection("Security").get();
    const securityFormData = securityCollection.docs.map((doc) => {
      return doc.data();
    });

    setSecurityForms(securityFormData);
  };

  const fetchForms = async () => {
    const usersCollection = await db.collection("BenefitForms").get();
    const returneData = usersCollection.docs.map((doc) => {
      return doc.data();
    });

    setForms([
      {
        health: [returneData[0]],
        dental: [returneData[1]],
        retirement: [returneData[2]],
        std: [returneData[3]],
      },
    ]);
  };

  const getData = async () => {
    try {
      const usersEmail = await Auth.currentAuthenticatedUser();

      const usersModel = await DataStore.query(Users, (c) =>
        c.email("eq", usersEmail.attributes.email)
      );
      setData(usersModel);
      setUser(usersModel[0].first_name);
      setOnBoarding(usersModel[0].OnBoarding.id);
    } catch (error) {
      return error;
    }
  };

  const completeOnboarding = () => {
    data.map(async (data) => {
      if (
        data.OnBoarding.Unanet.status === false &&
        data.OnBoarding.Paychex.status === false &&
        data.OnBoarding.Ring_Central.status === false &&
        data.OnBoarding.MS_Office.status === false &&
        certify === true
      ) {
        const original = await DataStore.query(OnBoarding, onBoarding);

        await DataStore.save(
          OnBoarding.copyOf(original, (updated) => {
            updated.CheckList_Completion = true;
          })
        );

        setModalStyle1(
          "block fixed z-1 left-0 top-0 w-full h-full overflow-auto"
        );

        setdisabledButton("invisible");
        getData();
      } else {
        setModalStyle2(
          "block fixed z-1 left-0 top-0 w-full h-full overflow-auto"
        );
        getData();
      }
    });
  };

  const repeatData = (arrayData, index, nextName, links) => {
    return (
      <div className={arrayData.status ? disabledCard : activeCard}>
        <div className="text-black">
          <div className="pt-4 text-2xl text-left font-bold text-blue-900">
            {arrayData.name}
          </div>
          <div>
            {arrayData.name === "Security (FSO)" ? (
              <div className="pt-4 text-left text-base">
                Please download and fill out both files below:
              </div>
            ) : null}
            <div>
              <ul>
                {arrayData.name === "Security (FSO)"
                  ? securityForms.map((x, i) => {
                      return (
                        <li key={i}>
                          <p>
                            <a
                              className="text-blue-900 text-xl font-bold no-underline"
                              href={x.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {x.name}
                            </a>
                          </p>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>

            <p className="text-base text-left">{arrayData.description}</p>

            {arrayData.name === "Benefits" ? (
              <div className="container text-white">
                <ul>
                  {physicalForms.map((x, i) => {
                    return (
                      <li key={i}>
                        <p>
                          <a
                            className="text-blue-900 text-base font-bold no-underline"
                            href={x.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {x.name}
                          </a>
                        </p>
                      </li>
                    );
                  })}
                </ul>
                {forms.map((x, i) => {
                  return (
                    <div className="text-base" key={i}>
                      {healthInsuranceForms(x.health[0], i)}
                      {dentalVisionForms(x.dental[0], i)}
                      {retirementForms(x.retirement[0], i)}
                      {stdLifeForms(x.std[0], i)}
                    </div>
                  );
                })}
              </div>
            ) : null}

            {links ? (
              <p>
                <a
                  href={links}
                  target="_blank"
                  rel="noreferrer"
                  className="text-left text-blue-900 text-xl font-bold no-underline"
                >
                  Click here to go to {arrayData.name}
                </a>
              </p>
            ) : null}
            <div>
              <div className="pb-2 font-bold text-blue-900 text-left">
                Tasks:
              </div>
              {arrayData.tasks.map((task, index) => {
                return (
                  <Form className="text-left" key={index}>
                    <Form.Check label={task} type="checkbox" />
                  </Form>
                );
              })}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={async () => {
                const queryOnboard = await DataStore.query(
                  OnBoarding,
                  currentUserLoggedIn[0].OnBoarding.id
                );

                if (nextName === null) {
                  setCertButton(false);
                } else {
                  await DataStore.save(
                    OnBoarding.copyOf(queryOnboard, (updated) => {
                      updated[nextName].status = false;
                    })
                  );
                }

                const usersManager = await DataStore.query(
                  Managers,
                  currentUserLoggedIn[0].managersID
                );

                await DataStore.save(
                  Managers.copyOf(usersManager, (updated) => {
                    updated.notifications = [
                      ...updated.notifications,
                      `${currentUserLoggedIn[0].first_name} ${currentUserLoggedIn[0].last_name} has completed ${arrayData.name}`,
                    ];
                  })
                );
                getData();
              }}
              className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg mb-4 mt-5"
            >
              Click when all steps are completed
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto">
      <div className="mb-6 bg-white py-12">
        <div className="container">
          <div className="row">
            <div className="md:col-8">
              <div className="text-3xl font-bold text-blue-900 mt-4 mb-12">
                {" "}
                Hi {user}. This is your Onboarding Checklist.
              </div>
              <div className="mx-auto col-md-8">
                <div className="text-xl text-left">
                  This checklist is designed to set you up with essential
                  services to make your transition easier. If you have any
                  questions as you proceed through the checklist, please reach
                  out to your manager.
                </div>
                <div className="pt-2 text-xl text-left">
                  It is essential that you complete all steps of the onboarding
                  checklist.
                </div>
              </div>

              <div className="mx-auto mt-8">
                <div className={activeCard}>
                  <div className="pt-4 text-2xl text-left font-bold text-blue-900">
                    SharePoint
                  </div>
                  <p className="text-base text-left">
                    SharePoint is a document management and storage system. It
                    is part of the Microsoft Office Suite -- if you have a
                    Cervello email account (go-ct) you should have SharePoint
                    access. Click the link below to log into SharePoint. Use
                    your Microsoft (go-ct email) credentials.
                  </p>

                  <p className="text-base text-left">
                    Leave SharePoint open in a separate tab in your browser.
                  </p>
                  <p className="text-base text-left">
                    <span className="font-bold text-red-500">IMPORTANT: </span>
                    If you cannot log into SharePoint for any reason, stop here
                    and contact your manager.
                  </p>
                  <p>
                    <a
                      href="https://cervtech.sharepoint.com/sites/PersonalSites/Shared%20Documents/Forms/AllItems.aspx"
                      target="_blank"
                      rel="noreferrer"
                      className="text-left text-blue-900 text-xl font-bold no-underline"
                    >
                      Click here to go to SharePoint
                    </a>
                  </p>
                  <Form className="text-left">
                    <Form.Check
                      label="I have logged into SharePoint"
                      type="checkbox"
                    />
                  </Form>
                  <div className="flex justify-center">
                    <button
                      onClick={async () => {
                        const original = await DataStore.query(
                          OnBoarding,
                          data[0].OnBoarding.id
                        );

                        await DataStore.save(
                          OnBoarding.copyOf(original, (updated) => {
                            updated.Paychex.status = false;
                          })
                        );
                        getData();
                      }}
                      className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg mb-4 mt-5"
                    >
                      Click when all steps are completed
                    </button>
                  </div>
                </div>
                {data.map((x, i) => {
                  return (
                    <div key={i}>
                      {repeatData(
                        x.OnBoarding.Paychex,
                        x,
                        "MS_Office",
                        "https://myapps.paychex.com/landing_remote/login.do?TYPE=33554433&REALMOID=06-fd3ba6b8-7a2f-1013-ba03-83af2ce30cb3&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=-SM-DcRXd3RBkM%2bIAuUkJhio4qMQPGHXSlwC5NHvGd60RCkP6guTqWS4qLnJtYdJd9Ge&TARGET=-SM-https%3a%2f%2fmyapps%2epaychex%2ecom%2f"
                      )}
                      {repeatData(
                        x.OnBoarding.MS_Office,
                        x,
                        "Ring_Central",
                        "https://www.microsoft.com/en-us/microsoft-365"
                      )}
                      {repeatData(
                        x.OnBoarding.Ring_Central,
                        x,
                        "Security",
                        "https://www.ringcentral.com/"
                      )}
                      {repeatData(x.OnBoarding.Security, x, "Unanet")}
                      {repeatData(
                        x.OnBoarding.Unanet,
                        x,
                        "Benefits_Forms",
                        "https://go-ct.unanet.biz/go-ct/action/login"
                      )}
                      {repeatData(
                        x.OnBoarding.Benefits_Forms,
                        x,
                        "Paychex_Training"
                      )}
                      {repeatData(x.OnBoarding.Paychex_Training, x, null)}
                    </div>
                  );
                })}
              </div>

              <Form className="col-md-10 mb-2 mx-auto mt-6 container grid">
                <Form.Group className="col-md-8 mx-auto">
                  <Form.Label className="text-base">
                    By entering your name below you are certifying you have
                    completed onboarding.
                  </Form.Label>
                  <div className="col-md-6 mx-auto">
                    <Form.Control
                      onChange={handleName}
                      className="text-center"
                      placeholder="Enter your first and last name"
                    ></Form.Control>
                  </div>
                </Form.Group>
              </Form>

              <button
                onClick={completeOnboarding}
                disabled={certButton}
                className={`border-2 bg-blue-700 hover:bg-blue-900 text-white rounded-md h-auto w-auto p-2 mb-2 text-black mt-10 font-bold ${disabledButton} `}
              >
                I certify that I have completed all onboarding tasks.
              </button>

              <div id="confirmationModal" className={modalStyle1}>
                <div className="bg-white rounded-lg mt-52 mx-auto object-center p-20px border-solid border-4 border-black h-auto w-7/12">
                  <h1>Onboarding Completed</h1>
                  <p className="text-center text-2xl text-black mt-8">
                    You're almost there! Please, notify your manager that you
                    have completed your onboarding checklist. Your dashboard
                    will be accessible once your manager has confirmed all steps
                    were completed.
                  </p>
                  <button
                    className="bg-yellow-500 hover:bg-yellow-700 h-9 w-1/4 rounded-lg mt-16 mb-2"
                    onClick={() =>
                      setModalStyle1(
                        "hidden fixed z-1 left-0 top-0 w-full h-full overflow-auto"
                      )
                    }
                  >
                    OK
                  </button>
                </div>
              </div>

              <div id="incompleteModal" className={modalStyle2}>
                <div className="bg-white rounded-lg mt-52 mx-auto object-center p-20px border-solid border-4 border-black h-auto w-7/12">
                  <h1 className="text-red-600">Onboarding Incomplete</h1>
                  <p className="text-center text-2xl text-red-600 mt-8">
                    Please, finish all your onboarding tasks before moving on to
                    the next step
                  </p>
                  <button
                    className="bg-yellow-500 hover:bg-yellow-700 h-9 w-1/4 rounded-lg mt-16 mb-2"
                    onClick={() =>
                      setModalStyle2(
                        "hidden fixed z-1 left-0 top-0 w-full h-full overflow-auto"
                      )
                    }
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
