import hypersonicsPaper from "./Cervello_Hypersonic-C2-CONOPS-COE-Paper_2020-DistD.pdf";
import image from "./wp1-image.png";
export default function WP1() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Hypersonics C2-CONOPS Center of Excellence White Paper
          </div>
        </div>
        <div className="container md:pt-10 row mx-auto sm:mt-5">
          <div className="col-md-6 md:pt-20 mx-auto">
            <div className="text-xl">
              Thank you for your interest in our work. <br /> Click the Download
              button below to download a pdf copy of our white paper.
            </div>
            <a
              className="text-white font-bold no-underline"
              href={hypersonicsPaper}
              rel="noreferrer"
              target="_blank"
              download="cervello_hypersonics_c2-conops_coe_paper_2020.pdf"
            >
              <button className="border-black blueOne text-white font-bold py-2 px-4 my-6 rounded">
                Download
              </button>
            </a>
          </div>
          <div className="col-md-6">
            <img className="border" alt="document" src={image} />
          </div>
        </div>
      </div>
    </div>
  );
}
