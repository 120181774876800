import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import LogisticsShip from "./logisticsShip.jpg";
import Collapse from "react-bootstrap/Collapse";

export default function Logistics() {
  const [logisticsDetails, setLogisticsDetails] = useState(false);

  return (
    <div className="md:-mt-36 text-black">
      <div className="mb-6 bg-white">
        <row>
          <div>
            <Image src={LogisticsShip}></Image>
          </div>
          <div className="col-md-6 mx-auto pb-12">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900 mt-5">
                    Global Logistics
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    Cervello is a global logistics and technology engineering
                    leader who provides innovative solutions. Our streamlined
                    operations model addresses all three supply chain elements:
                    data, automation, and collaboration. With the use of an
                    industry-leading metric assessment model, we can analyze the
                    efficiency and effectiveness of the logistics enterprise. We
                    employ a Performance-to-Plan approach to deliver
                    accountability and measurable performance oversight to our
                    customers and stakeholders.
                  </p>
                  <p className="text-left text-base">
                    As a Logistics Center of Excellence, Cervello's core
                    competencies include deployment, distribution, and
                    transportation logistics management, consulting, and
                    analysis. We specialize in continuous analysis of lifecycle
                    logistics supportability to improve transit times, reduce
                    costs, gain efficiencies, and streamline decision-making
                    processes. We design, build, and run comprehensive global
                    logistics solutions for our customers and have a proven
                    corporate competency working in a multinational environment
                    and direct interaction with the United States Transportation
                    Command, Defense Logistics Agency, and foreign governments.
                  </p>
                  <div className="pb-1">
                    <div
                      onClick={() => setLogisticsDetails(!logisticsDetails)}
                      aria-controls="example-collapse-text"
                      aria-expanded={logisticsDetails}
                      className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                    >
                      <div className="col-10 text-left text-white">
                        Core Capabilities
                      </div>
                      {!logisticsDetails ? (
                        <div className="col-2 text-right text-white">+</div>
                      ) : (
                        <div className="col-2 text-right text-white">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={logisticsDetails}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-black text-left"
                        >
                          <ul className=" list-disc text-base text-white">
                            <li>
                              Supply, distribution and transportation logistics
                              management, consulting, and analysis
                            </li>

                            <li>
                              Continuous analysis of lifecycle logistics
                              supportability to improve transit times, reduce
                              costs, gain efficiencies, and streamline
                              decision-making processes
                            </li>

                            <li>
                              Design, create, and maintain comprehensive global
                              logistics solutions for our customers
                            </li>

                            <li>
                              Customer-centric technology supported by our
                              global workforce, to ensure Total Asset Visibility
                              (24 x 7 x 365) across a global spectrum of the
                              Department of Defense and Private Sector
                              transportation networks
                            </li>

                            <li>
                              Expert logisticians, distribution process
                              advocates, and cargo expediters distributed
                              worldwide that provide physical distribution and
                              transportation operations support
                            </li>

                            <li>
                              Advanced Training Management System designed to
                              enhance the operational performance of all company
                              personnel
                            </li>

                            <li>
                              Integrated Reporting and Performance Management
                            </li>

                            <li>
                              Proven corporate competency working in a
                              multinational environment and direct interaction
                              with the United States Transportation Command,
                              Defense Logistics Agency, and Foreign Governments
                            </li>

                            <li>
                              International Traffic in Arms Regulations
                              compliant (ITAR)
                            </li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </row>
      </div>
    </div>
  );
}
