import { React, useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Current, Future } from '../../../models';
import { Container, Row, Col, Table, Form } from 'react-bootstrap/';
import { useParams } from 'react-router-dom';
import Alert from '../../../Alert/Alert';
export default function Manager_Routes() {
	const [values, setValues] = useState();
	const [Futurevalues, setFutureValues] = useState();
	const [current, setCurrent] = useState([]);
	const [future, setFuture] = useState([]);

	let { slug } = useParams();

	useEffect(() => {
		fetchCurrent();
		fetchFuture();
	}, []);

	const handle = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const fetchCurrent = async () => {
		const currentModel = await DataStore.query(Current);

		try {
			setCurrent(currentModel);
		} catch (error) {
			return;
		}
	};

	const fetchFuture = async () => {
		const futureModel = await DataStore.query(Future);

		try {
			setFuture(futureModel);
		} catch (error) {
			return;
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		let driver = e.target.elements.driver?.value;
		let vehicle_number = e.target.elements.vehicle_number?.value;
		let hazmat = e.target.elements.hazmat?.value;
		let load_description = e.target.elements.load_description?.value;

		await DataStore.save(
			new Current({
				driver: driver,
				vehicle_number: Number(vehicle_number),
				hazmat: hazmat,
				load_description: load_description,
			})
		);

		fetchCurrent();
	};

	const handleFuture = (e) => {
		const { name, value } = e.target;
		setFutureValues({
			...Futurevalues,
			[name]: value,
		});
	};

	const handleFutureFormSubmit = async (e) => {
		e.preventDefault();
		let date_scheduled = e.target.elements.date_scheduled?.value;
		let date_due = e.target.elements.date_due?.value;
		let load_description = e.target.elements.load_description?.value;

		await DataStore.save(
			new Future({
				date_due: date_due,
				load_description: load_description,
				date_scheduled: date_scheduled,
				region: slug,
			})
		);

		fetchFuture();
	};
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 '>
				<Container style={{ display: 'flex', justifyContent: 'center' }}>
					<Row>
						<Alert />
						<Col>
							<h1 className='text-center font-black text-3xl text-white'>
								Current Operations
							</h1>
							<Form onSubmit={handleFormSubmit}>
								<Table className='bg-white' striped bordered hover>
									<thead>
										<tr>
											<th>Driver</th>
											<th>Vehicle Number</th>
											<th>HAZMAT (Y or N)</th>
											<th>Load Description</th>
										</tr>
									</thead>
									<tbody>
										{current.length > 0
											? current.map((x) => {
													return (
														<tr key={x.id}>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.driver}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.vehicle_number}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.hazmat}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.load_description}
															</td>

															<td>
																{' '}
																<a
																	href='# '
																	onClick={async (e) => {
																		const modelToDelete = await DataStore.query(
																			Current,
																			x.id
																		);
																		DataStore.delete(modelToDelete);
																		return fetchCurrent();
																	}}>
																	<i
																		className='fa fa-trash'
																		aria-hidden='true'></i>
																</a>
															</td>
														</tr>
													);
											  })
											: null}
									</tbody>
									<tbody>
										<tr>
											<td
												onChange={handle}
												className='border border-black border-opacity-100 border-solid'>
												<Form.Control type='text' name='driver' />
											</td>
											<td
												onChange={handle}
												className='border border-black border-opacity-100 border-solid'>
												<Form.Control type='text' name='vehicle_number' />
											</td>
											<td
												onChange={handle}
												className='border border-black border-opacity-100 border-solid'>
												<select name='hazmat' onChange={handle}>
													<option name='Y'>Yes</option>
													<option name='N'>No</option>
												</select>
											</td>
											<td
												onChange={handle}
												className='border border-black border-opacity-100 border-solid'>
												<Form.Control
													as='textarea'
													className='text-black resize border rounded-md'
													name='load_description'
												/>
											</td>
											<button>Save</button>
										</tr>
									</tbody>
								</Table>
							</Form>
						</Col>
					</Row>
				</Container>
				<Container style={{ display: 'flex', justifyContent: 'center' }}>
					<Row>
						<Col>
							<Form onSubmit={handleFutureFormSubmit}>
								<h1 className='text-center font-black text-3xl text-white'>
									Future Operations
								</h1>
								<Table className='bg-white' striped bordered hover>
									<thead>
										<tr>
											<th className='border border-black border-opacity-100 border-solid'>
												Date Scheduled (YEAR-MM-DATE)
											</th>

											<th className='border border-black border-opacity-100 border-solid'>
												Date Due (YEAR-MM-DATE)
											</th>

											<th className='border border-black border-opacity-100 border-solid'>
												Load Description
											</th>
										</tr>
									</thead>
									<tbody>
										{future.length > 0
											? future.map((x) => {
													return (
														<tr key={x.id}>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.date_scheduled}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.date_due}
															</td>
															<td className='border border-black border-opacity-100 border-solid'>
																{x.load_description}
															</td>

															<td>
																{' '}
																<a
																	href='# '
																	onClick={async (e) => {
																		const modelToDelete = await DataStore.query(
																			Future,
																			x.id
																		);
																		DataStore.delete(modelToDelete);
																		return fetchFuture();
																	}}>
																	<i
																		className='fa fa-trash'
																		aria-hidden='true'></i>
																</a>
															</td>
														</tr>
													);
											  })
											: null}
									</tbody>
									<tbody>
										<tr>
											<td
												onChange={handleFuture}
												className='border border-black border-opacity-100 border-solid'>
												<Form.Control type='text' name='date_scheduled' />
											</td>
											<td
												onChange={handleFuture}
												className='border border-black border-opacity-100 border-solid'>
												<Form.Control type='text' name='date_due' />
											</td>
											<td
												onChange={handleFuture}
												className='border border-black border-opacity-100 border-solid'>
												<Form.Control
													as='textarea'
													className='text-black resize border rounded-md w-full'
													name='load_description'
												/>
											</td>
											<button>Save</button>
										</tr>
									</tbody>
								</Table>
							</Form>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
