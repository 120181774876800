import React from "react";
import "./Contact.css";

export default function Success() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className=" mb-12 pb-80 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Thank You for Your Message
          </div>
        </div>
        <div className="container mt-5 pt-12">
          <div className="mx-auto row">
            <div>
              <div className="blueText font-bold text-3xl text-center">
                Your message has been received. We will respond to you as soon
                as possible.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
