import React from 'react';
import NikkiPic from './Nikki Bibbs.png';
import { Row, Col, Container, Card } from 'react-bootstrap/';
export default function Nikki() {
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 md:h-auto '>
				<Container className='mt-10'>
					<Row>
						<Col>
							<Card className='mt-6' style={{ width: '18rem' }}>
								<Card.Img variant='top' src={NikkiPic} />
								<Card.Body className='text-black'>
									<Card.Title>Nikisha Bibbs</Card.Title>
									<Card.Text className='text-center'>
										Distribution Management Consultant / Interim DPA for
										Kuwait
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col xs={6} className='mr-24 pt-24'>
							<p className='text-xl text-black'>
								Nikisha Bibbs is the Distribution Management Consultant (DMC).
								As the DMC, she works closely with the Chief Global Workforce
								Development Officer providing quality content of support
								throughout the globe with her Logistical expertise. Nikisha
								stays fully engaged and supports the customers through her
								experience, professionalism, networking, and a positive
								personality. Mrs. Bibbs has 10+ years of working knowledge in
								all four modes of Transportation Logistics - (Air, Ground, Rail,
								and Vessel). Although she is not a veteran, Mrs. Bibbs has been
								immersed in Military Logistics / Contracting experience, working
								at MARCORLOGCOM Logistics Base in United States, Mosul, Iraq,
								Kandahar, Afghanistan, and Kuwait.
							</p>{' '}
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
