import React, { useState, useEffect } from "react";
import { Blog } from "../models";
import { DataStore } from "@aws-amplify/datastore";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";

export default function NewsRoom() {
  const [blog, setBlog] = useState([]);
  const [search, setSearch] = useState("");

  const onchange = (e) => setSearch(e.target.value);

  const filterFunction = (posts) => {
    return (
      posts.tags.indexOf(search.toLowerCase()) !== -1 ||
      posts.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      posts.author.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  };

  useEffect(() => {
    const getBlogData = async () => {
      try {
        const blogModel = await DataStore.query(Blog);
        const sortedBlog = blogModel.sort(
          (a, b) => b._lastChangedAt - a._lastChangedAt
        );
        setBlog(sortedBlog);
      } catch (error) {
        console.error(error);
      }
    };
    getBlogData();
  }, []);

  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Newsroom
          </div>
        </div>
        <div className="container pt-4">
          <div className="row">
            <div className="col-md-4 pb-8">
              <input
                type="text"
                placeholder="Search..."
                className="border-black text-center rounded-lg border-1"
                value={search}
                onChange={onchange}
              />
            </div>
            <div className="col-md-4 text-base text-blue-900 font-bold">
              Thanks for visiting our newsroom! Stay tuned for more...
            </div>
          </div>

          <Row xs={1} md={3}>
            {blog.length > 0 ? (
              blog.filter(filterFunction).map((x) => {
                return (
                  <CardGroup className="pb-4">
                    <Card
                      key={x.id}
                      className="newsCard cursor-pointer"
                      style={{ hover: "scale(1.2)" }}
                    >
                      <a
                        href={"NewsPost/" + x.url}
                        style={{
                          textDecoration: "none",
                          color: "black",
                          scrollTo: "0:0",
                        }}
                      >
                        <Card.Img
                          variant="top"
                          style={{ height: "18rem" }}
                          src={x.image}
                        />
                        <Card.Body>
                          <Card.Title className="text-left text-blue-900">
                            {x.title}
                          </Card.Title>
                          <Card.Text className="text-left text-sm">
                            {x.blurb}
                          </Card.Text>
                        </Card.Body>
                      </a>
                    </Card>
                  </CardGroup>
                );
              })
            ) : (
              <div className="container col-md-12">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
