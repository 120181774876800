import React, { useState } from "react";
import "./Contracts.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Global from "./globalisation.jpg";
import Collapse from "react-bootstrap/Collapse";
import GSA from "./gsaLogo.gif";
import SeaportLogo from "./seaportLogo.png";

export default function Contracts() {
  const [seaPortDets, setSeaPortDets] = useState(false);
  const [seaPortAgencies, setSeaPortAgencies] = useState(false);
  const [seaPortContract, setSeaPortContract] = useState(false);
  const [gsaDets, setGsaDets] = useState(false);
  const [gsaAgencies, setGsaAgencies] = useState(false);
  const [gsaContract, setGsaContract] = useState(false);
  const [naicsCodes, setNaicsCodes] = useState(false);
  const [pscCodes, setPscCodes] = useState(false);

  return (
    <div className="md:-mt-36 text-black">
      <div className="mb-6 bg-white">
        <div>
          <div>
            <Image src={Global}></Image>
          </div>
          <div className="col-md-6 mx-auto">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900 mt-5">
                    Cervello Global Contract Vehicles
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    Cervello Global Corporation is dedicated to providing
                    support to the United States Government and Defense Industry
                    with historic support to Test and Evaluation, Concept
                    Development, Hypersonics, and Global Logistics to maintain
                    readiness and resilience in the near, mid, and far term. We
                    provide a high standard of communication and support to our
                    customers and create cost-effective solutions to meet our
                    customers' specific requirements.
                  </p>
                  <p className="text-left text-base">
                    <span className="font-bold text-blue-900">
                      Unique Entity ID:
                    </span>{" "}
                    FYXEY32QL7K6 |{" "}
                    <span className="font-bold text-blue-900">CAGE:</span> 53PR6
                  </p>
                  <div className="pb-1">
                    <div
                      onClick={() => setNaicsCodes(!naicsCodes)}
                      aria-controls="example-collapse-text"
                      aria-expanded={naicsCodes}
                      className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                    >
                      <div className="col-10 text-left text-white">
                        NAICS Codes{" "}
                      </div>
                      {!naicsCodes ? (
                        <div className="col-2 text-right text-white">+</div>
                      ) : (
                        <div className="col-2 text-right text-white">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={naicsCodes}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-black text-left"
                        >
                          <ul className=" list-disc text-base text-white">
                            <li>541330 - Engineering Services</li>
                            <li>
                              339113 - Surgical Appliance and Supplies
                              Manufacturing
                            </li>
                            <li>488320 - Marine Cargo Handling</li>
                            <li>488510 - Freight Transportation Arrangement</li>
                            <li>493110 - General Warehousing and Storage</li>
                            <li>493190 - Other Warehousing and Storage</li>
                            <li>541380 - Testing Laboratories and Services</li>
                            <li>541420 - Industrial Design Services</li>
                            <li>541430 - Graphic Design Services</li>
                            <li>541490 - Other Specialized Design Services</li>
                            <li>
                              541511 - Custom Computer Programming Services
                            </li>
                            <li>541512 - Computer Systems Design Services</li>
                            <li>
                              541513 - Computer Facilities Management Services
                            </li>
                            <li>541519 - Other Computer Related Services</li>
                            <li>
                              541611 - Administrative Management and General
                              Management Consulting Services
                            </li>
                            <li>
                              541612 - Human Resources Consulting Services
                            </li>
                            <li>
                              541614 - Process, Physical Distribution, And
                              Logistics Consulting Services
                            </li>
                            <li>
                              541618 - Other Management Consulting Services
                            </li>
                            <li>
                              541690 - Other Scientific and Technical Consulting
                              Services
                            </li>
                            <li>
                              541715 - Research and Development in The Physical,
                              Engineering, And Life
                            </li>
                            <li>
                              561210 - Sciences (Except Nanotechnology and
                              Biotechnology){" "}
                            </li>
                            <li>611430 - Facilities Support Services</li>
                            <li>
                              611430 - Professional And Management Development
                              Training
                            </li>
                            <li>611519 - Other Technical and Trade Schools</li>
                            <li>812332 - Industrial Launderers</li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  <div className="pb-1">
                    <div
                      onClick={() => setPscCodes(!pscCodes)}
                      aria-controls="example-collapse-text"
                      aria-expanded={pscCodes}
                      className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                    >
                      <div className="col-10 text-left text-white">
                        Product and Service Codes (PSC)
                      </div>
                      {!pscCodes ? (
                        <div className="col-2 text-right text-white">+</div>
                      ) : (
                        <div className="col-2 text-right text-white">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={pscCodes}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-black text-left"
                        >
                          <ul className=" list-disc text-base text-white">
                            <li>8470 - Armor, Personal</li>
                            <li>B541 - Special Studies / Analysis - Defense</li>
                            <li>
                              DA01 - IT and Telecom - Business Application /
                              Application Development Support Services (Labor)
                            </li>
                            <li>
                              DA10 - IT and Telecom - Business Application /
                              Application Development Software as a Service
                            </li>
                            <li>
                              M1GZ - Operation of Other Warehouse Buildings
                            </li>
                            <li>
                              R408 - Support - Professional: Program Management
                              / Support
                            </li>
                            <li>
                              R410 - Support - Professional: Program Evaluation
                              / Review / Development
                            </li>
                            <li>
                              R425 - Support - Professional: Engineering /
                              Technical
                            </li>
                            <li>
                              R706 - Support - Management: Logistics Support
                            </li>
                            <li>
                              R707 - Support - Management: Contract /
                              Procurement / Acquisition Support
                            </li>
                            <li>
                              V111 - Transportation / Travel / Relocation -
                              Transportation: Air Freight
                            </li>
                            <li>
                              V112 - Transportation / Travel / Relocation -
                              Transportation: Motor Freight
                            </li>
                            <li>
                              V113 - Transportation / Travel / Relocation -
                              Transportation: Rail Freight
                            </li>
                            <li>
                              V114 - Transportation / Travel / Relocation -
                              Transportation: Stevedoring
                            </li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mt-5 pb-5">
                <Row>
                  <Col>
                    <div className="text-left md:text-3xl text-2xl text-blue-900">
                      <Row>
                        <Col md={4}>
                          <Image className="max-h-20" src={SeaportLogo}></Image>
                        </Col>
                        <Col xs={8}>
                          Seaport-Next Generation <br /> (SeaPort-NxG)
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <p className="text-left text-black text-base">
                      SeaPort-NxG is the Navy's electronic platform for
                      acquiring support services in 23 functional areas
                      including Engineering, Financial Management, and Program
                      Management. The Navy Systems Commands (NAVSEA, NAVAIR,
                      NAVWAR, NAVFAC, and NAVSUP), the ONR, MSC, and the USMC
                      compete their service requirements amongst 2400+
                      SeaPort-NxG Indefinite Delivery Indefinite Quantity (IDIQ)
                      multiple award contract holders. Simply stated,
                      SeaPort-NxG provides an efficient and effective means of
                      contracting for professional support services and
                      enhancing small business participation.{" "}
                    </p>
                    <div className="pb-1">
                      <div
                        onClick={() => setSeaPortDets(!seaPortDets)}
                        aria-controls="example-collapse-text"
                        aria-expanded={seaPortDets}
                        className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                      >
                        <div className="col-10 text-left text-white">
                          Contract Services
                        </div>
                        {!seaPortDets ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse in={seaPortDets}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-black text-left"
                          >
                            <ul className=" list-disc text-base text-white">
                              <li>Financial Analysis and Budget Support</li>

                              <li>Quality Assurance (QA) Support</li>

                              <li>
                                Functional and Direct Programmatic
                                Administrative Support
                              </li>

                              <li>
                                Professional Development and Training Support
                              </li>

                              <li>
                                Analytical and Organizational Assessment Support
                              </li>

                              <li>Database Administrators</li>

                              <li>Logistics Support</li>

                              <li>Configuration Management (CM) Support</li>

                              <li>
                                Information System (IS) Development, Information
                                Assurance (IA), and Information Technology (IT)
                                Support
                              </li>

                              <li>Computer Systems Analysts</li>
                            </ul>
                            <br />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    <div className="pb-1">
                      <div
                        onClick={() => setSeaPortAgencies(!seaPortAgencies)}
                        aria-controls="example-collapse-text"
                        aria-expanded={seaPortAgencies}
                        className="mx-auto text-black text-left text-lg blueOne p-3 cursor-pointer row"
                      >
                        <div className="col-10 text-left text-white">
                          Supported Agencies
                        </div>
                        {!seaPortAgencies ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse in={seaPortAgencies}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-black text-left"
                          >
                            <ul className=" list-disc text-base text-white">
                              <li>Naval Sea Systems Command (NAVSEA)</li>

                              <li>
                                Space and Naval Warfare Systems Command (SPAWAR){" "}
                              </li>

                              <li>Naval Supply Systems Command (NAVSUP)</li>

                              <li>Military Sealift Command (MSC)</li>

                              <li>
                                Naval Facilities Engineering Systems Command
                                (NAVFAC)
                              </li>

                              <li>Office of Naval Research (ONR)</li>

                              <li>United States Marine Corps (USMC)</li>
                            </ul>
                            <br />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    <div className="pb-1">
                      <div
                        onClick={() => {
                          setSeaPortContract(!seaPortContract);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={seaPortContract}
                        className="mx-auto text-black text-left text-lg blueOne p-3 cursor-pointer row"
                      >
                        <div className="col-10 text-left text-white">
                          Contract Details
                        </div>
                        {!seaPortContract ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse in={seaPortContract}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-white text-left"
                          >
                            <ul>
                              <li>
                                {" "}
                                <span className="font-bold">Contract: </span>
                                N0017821D8990
                              </li>
                              <li>
                                <span className="font-bold">
                                  Contract Types:{" "}
                                </span>
                                Firm Fixed Price/Cost Plus Fixed Fee
                              </li>
                              <li>
                                <span className="font-bold">Link: </span>
                                <a
                                  href="www.seaport.navy.mil"
                                  rel="noreferrer"
                                  target="_blank"
                                  className="text-white"
                                >
                                  www.seaport.navy.mil
                                </a>
                              </li>
                              <li>
                                <span className="font-bold">
                                  Authorized Users:{" "}
                                </span>
                                SeaPort-NxG authorized users include all
                                warranted contracting officers of NAVSEA,
                                NAVAIR, SPAWAR, NAVSUP, NAVFAC, ONR, and the
                                USMC.
                              </li>
                            </ul>
                            <br />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <Row className="pb-2">
                  <Col>
                    <div className="text-left md:text-3xl text-2xl text-blue-900 pt-5">
                      <Row>
                        <Col md={3}>
                          <Image className="max-h-20" src={GSA}></Image>
                        </Col>
                        <Col xs={9}>
                          U.S. General Services Administration (GSA) Multiple
                          Award Schedule (MAS)
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-left text-black text-base">
                      The Federal Supply Schedule (FSS) program includes the
                      Multiple Award Schedule (MAS) that issues long-term
                      governmentwide contracts that provide federal, state, and
                      local government buyers access to commercial products,
                      services and solutions at pre negotiated pricing. The GSA
                      Schedule is structured to support the 12 large categories
                      established by OMB under the category management approach
                      of smarter buying. The Schedule is further divided into
                      subcategories and Special Item Numbers (SINs) that are
                      aligned to NAICS codes to simplify the process for buyers
                      and sellers to understand the scope of GSA's offerings and
                      find what the products and services they are looking to
                      buy or offer.
                    </p>
                    <div className="pb-1">
                      <div
                        onClick={() => setGsaDets(!gsaDets)}
                        aria-controls="example-collapse-text"
                        aria-expanded={gsaDets}
                        className="mx-auto text-black text-left text-lg blueOne p-3 cursor-pointer row"
                      >
                        <div className="col-10 text-left text-white">
                          Contract Services
                        </div>
                        {!gsaDets ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse in={gsaDets}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-black text-left text-white"
                          >
                            <ul className=" list-disc text-base">
                              <li>
                                Engineering System Design and Integration
                                Services
                              </li>

                              <li>Engineering Services </li>

                              <li>
                                Engineering Research and Development and
                                Strategic Planning
                              </li>
                            </ul>
                            <br />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    <div className="pb-1">
                      <div
                        onClick={() => setGsaAgencies(!gsaAgencies)}
                        aria-controls="example-collapse-text"
                        aria-expanded={gsaAgencies}
                        className="mx-auto text-black text-left text-lg blueOne p-3 cursor-pointer row"
                      >
                        <div className="col-10 text-left text-white">
                          Supported Agencies
                        </div>
                        {!gsaAgencies ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse in={gsaAgencies}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-black text-left"
                          >
                            <ul className=" list-disc text-base text-white">
                              <li>United States Army </li>

                              <li>United States Marine Corps (USMC)</li>

                              <li>Department of Homeland Security</li>

                              <li>Department of Veterans Affairs </li>

                              <li>Department of the Interior</li>

                              <li>
                                Department of Defense (Office of the Secretary
                                of Defense){" "}
                              </li>
                            </ul>
                            <br />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    <div className="pb-1">
                      <div
                        onClick={() => {
                          setGsaContract(!gsaContract);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={gsaContract}
                        className="mx-auto text-black text-left text-lg blueOne p-3 cursor-pointer row"
                      >
                        <div className="col-10 text-left text-white">
                          Contract Details
                        </div>
                        {!gsaContract ? (
                          <div className="col-2 text-right text-white">+</div>
                        ) : (
                          <div className="col-2 text-right text-white">-</div>
                        )}
                      </div>
                      <div>
                        <Collapse in={gsaContract}>
                          <div
                            id="example-collapse-text"
                            className="blueOne text-white text-left"
                          >
                            <ul>
                              <li>
                                {" "}
                                <span className="font-bold">Contract: </span>
                                47QRAA18D003E
                              </li>
                              <li>
                                <span className="font-bold">
                                  Contract Types:{" "}
                                </span>
                                Firm Fixed Price/Cost Plus Fixed Fee
                              </li>
                              <li>
                                <span className="font-bold">Link: </span>
                                <a
                                  href="www.gsa.gov"
                                  rel="noreferrer"
                                  target="_blank"
                                  className="text-white"
                                >
                                  www.gsa.gov{" "}
                                </a>
                              </li>
                              <li>
                                <span className="font-bold">
                                  Authorized Users:{" "}
                                </span>
                                Based on various authorities, GSA can support
                                the procurement needs of eligible state, local,
                                territorial, and tribal governments, including
                                instrumentalities as well as other eligible
                                non-Federal entities.
                              </li>
                            </ul>
                            <br />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
