import React from "react";
import "./ingram.css";
import { Row, Col, Card } from "react-bootstrap/";
import IngramPic from "./ingram_pic.png";
export default function Ingram() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            David Ingram - Director, Global Logistics Center of Excellence
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={IngramPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                David Ingram proudly served 26 years in the United States Marine
                Corps (USMC) retiring as a USMC 3112 (E9/MGySgt) with honors,
                and he is a recognized leader in military materiel distribution
                and workforce management with more than 30 years overall of
                Global Supply Chain, Logistics, and Distribution Management
                experience. While serving in the USMC as a Distribution
                Management Chief (DMC), he developed a highly respected
                reputation.
              </p>
              <p className="text-base text-black">
                David's mission is to build a global family of logisticians and
                other professional disciplines using the company's
                tip-of-the-spear service-minded methodology. He works tirelessly
                in partnership with the Corporate Vice President of Logistics
                and the Global Business and Workforce Development Directors
                running Cervello Global Subsidiary companies in six countries to
                achieve his goals. As one of the company's leading experts in
                global logistics planning, distribution, mobility, and
                warehousing, David continues to provide Distribution Management
                Consulting as a member of the company's Distribution Management
                Support Services.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
