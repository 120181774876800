import { React } from "react";

export default function CapabilityRequest() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className=" mb-12 pb-12 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Cervello Global Capability Statements
          </div>
        </div>
        <div className="container col-md-4 mt-5">
          <div className="mx-auto">
            <div>
              <div className="w-full blueText font-bold text-3xl text-center pb-4">
                Tell us a little about yourself
              </div>
              <div className="text-base pb-4">
                Please fill out the form and click submit to download a
                Capability Statement.
              </div>
              <form action="https://formspree.io/f/mzbwyyzk" method="POST">
                {" "}
                <label className="tracking-wide text-black font-bold mb-2">
                  Select a Capability Statement:
                </label>{" "}
                <select
                  className="text-black text-center block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                  name="capability-statement"
                  required
                >
                  {" "}
                  <option value="hypersonics">
                    Hypersonics Capability Statement
                  </option>
                  <option value="global-logistics">
                    Global Logistics Capability Statement
                  </option>
                  {/* <option value="distribution">
                    Distribution Capability Statement
                  </option> */}
                  <option value="mission-command">
                    Mission Command & Control Capability Statement
                  </option>
                  <option value="future-force">
                    Future Force Capability Statement
                  </option>
                </select>{" "}
                <label className="tracking-wide text-black font-bold mb-2">
                  Your Name:
                </label>{" "}
                <input
                  name="name"
                  type="text"
                  required
                  placeholder="Your name here"
                  className="appearance-none text-center text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                />
                <label className="tracking-wide text-black font-bold mb-2">
                  Your Email:
                </label>
                <input
                  name="email"
                  type="email"
                  required
                  placeholder="admin@example.com"
                  className="appearance-none text-center text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                />
                <label className="tracking-wide text-black font-bold mb-2">
                  Company Name
                </label>
                <input
                  name="company"
                  type="text"
                  required
                  placeholder="My Company, Inc."
                  className="appearance-none text-center text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                />
                <label className="tracking-wide text-black font-bold mb-2">
                  Title
                </label>
                <input
                  name="title"
                  type="text"
                  required
                  placeholder="CEO"
                  className="appearance-none text-center text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                />
                <label className="tracking-wide text-black font-bold mb-2">
                  Questions / Comments (optional):
                </label>
                <textarea
                  name="message"
                  className="appearance-none text-black block w-full bg-gray-200 text-black 
                    border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none 
                    focus:bg-white focus:border-gray-500"
                ></textarea>
                <button className="mt-2 max-w-sm mb-4 border-black blueOne text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
