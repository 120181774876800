import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import { useParams } from "react-router-dom";
import { Blog } from "../models";
import { DataStore } from "@aws-amplify/datastore";

export default function NewsPost() {
  let { slug } = useParams();

  const [blogPost, setBlogPost] = useState([]);
  const [blogContent, setBlogContent] = useState([]);
  const [blogTags, setBlogTags] = useState([]);

  useEffect(() => {
    const getBlogData = async () => {
      try {
        const blogModel = await DataStore.query(Blog, (c) => c.url("eq", slug));
        setBlogPost(blogModel[0]);
        setBlogContent(blogModel[0].content);
        setBlogTags(blogModel[0].tags);
      } catch (error) {
        console.error(error);
      }
    };
    getBlogData();
  }, [slug, blogPost, blogContent]);

  return (
    <div className="mx-auto text-black">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl">
        <div className="container col-md-12 pt-12 pb-24 pl-12 text-left">
          <a href="/NewsRoom">Back to Newsroom</a>
        </div>
        <div className="container col-md-6">
          <div className="text-left text-5xl text-blue-900">
            {blogPost.title}
          </div>
          <Image className="pt-4" src={blogPost.image} />
          <div className="pt-12">
            <div className="row text-left">
              <div className="col-md-12 pb-4">
                {blogContent.map((y) => {
                  return (
                    <div>
                      <div key={blogPost.title} className="text-base">
                        {y}
                      </div>
                      <br />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row text-left text-xs mt-24">
              <div className="text-xs text-left uppercase">Author</div>
              <hr></hr>
              <div className="text-base text-blue-900">{blogPost.author}</div>
              <div className="row">
                <Image
                  className="object-scale-down object-left h-36 col-md-4"
                  src={blogPost.authorPic}
                />
                <div className="col text-sm">{blogPost.authorBio}</div>
              </div>
              <div className="pt-20">
                Posted on {new Date(blogPost.createdAt).toDateString()}
              </div>
              <div className="pt-2">
                <span className="font-bold text-blue-900">Tags: </span>
                {blogTags.map((z) => {
                  return <div key={z}>{z}</div>;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="container col-md-12 pt-12 pl-20 text-left">
          <a href="/NewsRoom">Back to Newsroom</a>
        </div>
      </div>
    </div>
  );
}
