import { React, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavRoutes from "./Routes";
import Main from "../Main/Main";
import newCervelloLogo from "../SplashSideNav/New Cervello Global Logo - No Background.png";
import { DataStore } from "@aws-amplify/datastore";
import { Users, Managers } from "../models";
import { logout } from "../features/user";
import { useDispatch } from "react-redux";
import NavDropdown from "react-bootstrap/NavDropdown";
import HomeNav from "./HomeNav";
import ManagerNav from "./ManagerNav";
import EmployeeNav from "./EmployeeNav";
import TechNav from "./TechNav";
import PubNav from "./PubNav";
import HRNav from "./HRNav";

export default function TailWindNavbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navContent, setnavContent] = useState([]);
  const [notification, setNotification] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [homeRoute] = useState("Home");
  const [userTitle, setUserTitle] = useState("");
  const [userSubsection, setUserSubsection] = useState("");
  const [data] = useState("Internal-Dashboard");
  const dispatch = useDispatch();

  const checkUser = async () => {
    const authenticatedUser = await Auth.currentAuthenticatedUser();

    const userEmail = authenticatedUser.attributes.email;

    const usersModel = await DataStore.query(Users, (c) =>
      c.email("eq", userEmail)
    );

    try {
      if (usersModel[0].title === "Managers") {
        const managersModel = await DataStore.query(Managers, (c) =>
          c.email("eq", userEmail)
        );
        setNotification(managersModel[0].notifications);
      } else {
        setNotification(usersModel[0].notifications);
      }
      setCurrentUser(true);

      setUserTitle(usersModel[0].title);
      setUserSubsection(usersModel[0].subsection);
    } catch (error) {
      setCurrentUser(false);
      return;
    }
  };

  useEffect(() => {
    checkUser();

    if (
      (userSubsection === "Technologies" &&
        window.location.href.includes("/Internal")) ||
      window.location.href.includes("/Manager") ||
      window.location.href.includes("NewsManage")
    ) {
      setnavContent(TechNav);
    } else if (
      (userSubsection === "Publications and Media" &&
        window.location.href.includes("/Internal")) ||
      window.location.href.includes("/Manager") ||
      window.location.href.includes("NewsManage")
    ) {
      setnavContent(PubNav);
    } else if (
      (userSubsection === "Human Resources" &&
        window.location.href.includes("/Internal")) ||
      window.location.href.includes("/Manager")
    ) {
      setnavContent(HRNav);
    } else if (
      (userTitle === "Managers" &&
        window.location.href.includes("/Internal")) ||
      window.location.href.includes("/Manager")
    ) {
      setnavContent(ManagerNav);
    } else if (
      userTitle === "Users" &&
      window.location.href.includes("/Internal")
    ) {
      setnavContent(EmployeeNav);
    } else setnavContent(HomeNav);
  }, [data, userTitle, userSubsection]);

  return (
    <Router>
      <div className="mx-auto">
        <div className="relative mx-auto z-40">
          <Nav
            variant="tabs"
            defaultActiveKey="/Home"
            className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white shadow-2xl w-auto"
          >
            <div className="container md:px-4 md:mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <a href={`/${homeRoute}`}>
                  <img className="w-32" src={newCervelloLogo} alt="" />
                </a>
                <button
                  className="cursor-pointer text-xl leading-none px-3 py-1 block lg:hidden"
                  type="button"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <i className="fas fa-bars"></i>
                </button>
              </div>
              <div
                className={
                  "lg:flex   flex-grow items-center" +
                  (navbarOpen ? " flex" : " hidden")
                }
                id="example-navbar-danger"
              >
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                  {navContent}

                  {!currentUser ? null : (
                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                      <NavDropdown
                        className=" flex items-center text-sm uppercase font-bold no-underline"
                        title="Intranet"
                        id="nav-dropdown"
                      >
                        <NavDropdown.Item
                          href={`/Internal-Dashboard`}
                          eventKey="4.1"
                        >
                          Dashboard
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2">
                          <Link
                            className="text-black no-underline"
                            to={`/Internal-Notifications`}
                            color="gray"
                            ripple="light"
                          >
                            Notifications
                            {notification !== null &&
                            notification.length > 0 ? (
                              <i
                                className="fas fa-bell fa-1x"
                                data-count={`${notification.length}`}
                              ></i>
                            ) : null}
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => {
                            Auth.signOut()
                              .then((data) => {
                                setCurrentUser(false);
                                dispatch(logout());
                                checkUser();
                                return (window.location.href = "Login");
                              })
                              .catch((err) => console.log(err));
                          }}
                          eventKey="4.3"
                        >
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </ul>
                  )}
                  {!currentUser ? (
                    <Nav.Item>
                      <Nav.Link eventKey="/Login">
                        <NavLink
                          to="/Login"
                          className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
                        >
                          Login
                        </NavLink>
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                </ul>
              </div>
            </div>
          </Nav>
        </div>
      </div>
      <Switch>
        <Route exact path="/" component={Main} />
        {NavRoutes.map((x, i) => {
          return <Route key={i} path={x.path} component={x.component} />;
        })}
      </Switch>
    </Router>
  );
}
