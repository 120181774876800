import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { BrowserRouter as Route, NavLink } from "react-router-dom";

export default function HomeNav() {
  return (
    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
      <li key="Capabilities" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Capabilities">
            <NavLink
              to="/Capabilities"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Capabilities</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
      <li key="Contracts" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Contracts">
            <NavLink
              to="/Contracts"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Contracts</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
      <li key="Global" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Global">
            <NavLink
              to="/Global"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Global</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
      <li key="Leadership" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Leadership">
            <NavLink
              to="/Leadership"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Leadership</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
      <NavDropdown
        className=" flex items-center text-sm uppercase font-bold no-underline"
        title="Insights"
        id="nav-dropdown"
      >
        <NavDropdown.Item href={`/NewsRoom`} eventKey="4.1">
          NewsRoom
        </NavDropdown.Item>
        <NavDropdown.Item href={`/White-Papers`} eventKey="4.2">
          White Papers
        </NavDropdown.Item>
        <NavDropdown.Item href={`/Capability-Request`} eventKey="4.2">
          Capability Statements
        </NavDropdown.Item>
      </NavDropdown>
      <li key="Careers" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Careers">
            <NavLink
              to="/Careers"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Careers</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
      <li key="Contact" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Contact">
            <NavLink
              to="/Contact"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Contact</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
    </ul>
  );
}
