import React from "react";
import "./daniel.css";
import { Row, Col, Card } from "react-bootstrap/";
import GrenierPic from "./grenier.png";
export default function Daniel() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Daniel Grenier - Vice President, Engineering & Applied Sciences
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={GrenierPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                Daniel P. Grenier, Ed.D. has 41 years of government and civilian
                leadership experience. A retired Air Force Colonel, he was a
                crewmember on the B-52 and B-1 bombers and has an extensive Test
                & Evaluation and project management experience from time spent
                in the following roles: Air Force Operational Test & Evaluation
                Center (AFOTEC) Detachment Commander responsible for Operational
                T&E for all USAF weapons, Deputy Director Air & Space Mission
                Directorate HQ AFOTEC, Chief of Combat Readiness Assessment 7th
                Air Force Republic of Korea, U.S. Air Warfare Center B-1 Test
                Flight Commander, HQ Air Combat Command B-1 Requirements
                Division Chief, and B-1 Senior Evaluator Weapon System Officer.
              </p>
              <p className="text-base text-black">
                During his time with Cervello, Daniel authored and published an
                innovative assessment methodology entitled System & Task
                Operational Assessment for Risk Management (STORM) designed for
                rapid, rigorous, and affordable assessments on emerging
                capabilities and Tactics, Techniques, and Procedures (TTP).
                STORM has been used for twelve assessments across all DoD
                Services and the Department of Homeland Security. He received an
                M.S. in Space Studies from the University of North Dakota Center
                for Aerospace Studies, an M.A. in Diplomacy from Norwich
                University VT, and a Doctorate in Education from Liberty
                University VA. Dr. Grenier is currently an Adjunct Professor of
                Political Science at Virginia Peninsula Community College and is
                completing his Ph.D. in Public Policy-National Security at
                Liberty University VA.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
