import { React, useState } from "react";
import "./Login.css";
import Form from "react-bootstrap/Form";
import { Auth } from "aws-amplify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export default function NewPass() {
  const [values, setValues] = useState();
  const [emailVisibility, setEmailVisibility] = useState("visible");
  const [codeVisibility, setCodeVisibility] = useState("invisible");
  const [passwordVisibility, setPasswordVisibility] = useState("invisible");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handle = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let username = e.target.elements.email?.value;

    try {
      await Auth.forgotPassword(username);
      setEmailVisibility("invisible");
      setCodeVisibility("visible");
    } catch (error) {
      alert(error.message);
      return;
    }
  };

  const handleCode = async (e) => {
    e.preventDefault();

    try {
      setCodeVisibility("invisible");
      setPasswordVisibility("visible");
    } catch (error) {
      alert(error.message);
      return;
    }
  };

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(
        values.email,
        values.confirmCode,
        values.confirmPassword
      );

      setPasswordVisibility("invisible");
	  handleShow();
    } catch (error) {
      alert(error.message);
      return;
    }
  };
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-6 bg-white border-2 border-solid rounded-lg pt-32">
        <div className="w-full max-w-md m-auto bg-gray-100 rounded-lg border border-primaryBorder py-10 px-12">
          <h1 className="text-2xl font-medium text-primary mt-4 mb-12 text-center font-bold">
            Reset Password
          </h1>

          <Form className={emailVisibility} onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="font-bold text-primary">
                Email address
              </Form.Label>
              <Form.Control
                name="email"
                type="email"
                required
                className="text-center"
                placeholder="Enter email"
                onChange={handle}
              />
            </Form.Group>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
              type="submit"
            >
              Submit
            </button>
          </Form>

          <Form onSubmit={handleCode} className={codeVisibility}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="font-bold text-primary">
                Confirmation Code
              </Form.Label>
              <Form.Control
                name="confirmCode"
                required
                className="text-center"
                type="text"
                placeholder="Enter confirmation code"
                onChange={handle}
              />
            </Form.Group>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
              type="submit"
            >
              Submit
            </button>
          </Form>
          <Form onSubmit={handleConfirmPassword} className={passwordVisibility}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="font-bold text-primary">
                New Password
              </Form.Label>
              <Form.Control
                name="confirmPassword"
                required
                className="text-center"
                type="password"
                placeholder="Enter New Password"
                onChange={handle}
              />
            </Form.Group>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Submit
            </button>
          </Form>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Password reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Your password was successfully changed. Return to the login screen
              to log in with your new password.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
			  <Link to="/SignIn">
              <Button variant="primary">
                Return to Login
              </Button></Link>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
