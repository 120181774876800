import React, { useState, useEffect } from "react";
import { Users, Managers, OnBoarding } from "../models";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import subList from "../SignUp/subsections";
import roleList from "../SignUp/ranks";

export default function HR() {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userManager, setUserManager] = useState([]);
  const [showEditDept, setShowEditDept] = useState(false);
  const [showEditName, setShowEditName] = useState(false);
  const [showEditRole, setShowEditRole] = useState(false);
  const [showEditManager, setShowEditManager] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deptSelect, setDeptSelect] = useState("");
  const [roleSelect, setRoleSelect] = useState("");
  const [newManagerSelect, setNewManagerSelect] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [search, setSearch] = useState("");

  const onchange = (event) => setSearch(event.target.value);

  const handleCloseEditDept = () => setShowEditDept(false);
  const handleShowEditDept = () => setShowEditDept(true);

  const handleCloseEditName = () => setShowEditName(false);
  const handleShowEditName = () => setShowEditName(true);

  const handleCloseEditRole = () => setShowEditRole(false);
  const handleShowEditRole = () => setShowEditRole(true);

  const handleCloseEditManager = () => setShowEditManager(false);
  const handleShowEditManager = () => {
    setShowEditManager(true);
    const managerList = async () => {
      const managers = await DataStore.query(Managers);
      setManagerList(managers);
    };
    managerList();
  };

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const filterFunction = (userList) =>
    userList.first_name.toUpperCase().indexOf(search.toUpperCase()) > -1 ||
    userList.last_name.toUpperCase().indexOf(search.toUpperCase()) > -1 ||
    userList.subsection.toUpperCase().indexOf(search.toUpperCase()) > -1 ||
    userList.rank.toUpperCase().indexOf(search.toUpperCase()) > -1;

  useEffect(() => {
    const getUser = async () => {
      try {
        const retrieveUsers = await DataStore.query(Users);
        setUserList(retrieveUsers);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  });

  const handleNameFormSubmit = async (e) => {
    e.preventDefault();
    let first_name = e.target.elements.first_name.value.trim();
    let last_name = e.target.elements.last_name.value.trim();
    const getUser = await DataStore.query(Users, (c) =>
      c.id("eq", selectedUser.id)
    );
    const specificUser = getUser[0];
    await DataStore.save(
      Users.copyOf(specificUser, (x) => {
        x.first_name = first_name;
        x.last_name = last_name;
      })
    );
    handleCloseEditName();
  };

  const handleDeptFormSubmit = async (e) => {
    e.preventDefault();
    let department = deptSelect;
    const getUser = await DataStore.query(Users, (c) =>
      c.id("eq", selectedUser.id)
    );
    const specificUser = getUser[0];
    await DataStore.save(
      Users.copyOf(specificUser, (x) => {
        x.subsection = department;
      })
    );
    handleCloseEditDept();
  };

  const handleManagerFormSubmit = async (e) => {
    e.preventDefault();
    let newManager = newManagerSelect;
    const getUser = await DataStore.query(Users, (c) =>
      c.id("eq", selectedUser.id)
    );
    const specificUser = getUser[0];
    await DataStore.save(
      Users.copyOf(specificUser, (x) => {
        x.managersID = newManager.id;
      })
    );
    handleCloseEditManager();
  };

  const handleRoleFormSubmit = async (e) => {
    e.preventDefault();
    let role = roleSelect;
    const getUser = await DataStore.query(Users, (c) =>
      c.id("eq", selectedUser.id)
    );
    const specificUser = getUser[0];
    await DataStore.save(
      Users.copyOf(specificUser, (x) => {
        x.rank = role;
      })
    );
    handleCloseEditRole();
  };

  const handleDeleteFormSubmit = async (e) => {
    e.preventDefault();
    const getUser = await DataStore.query(Users, (c) =>
      c.id("eq", selectedUser.id)
    );
    const deleteUser = getUser[0];
    const queryUserOnboarding = await DataStore.query(OnBoarding, (c) =>
      c.id("eq", deleteUser.OnBoarding.id)
    );
    const deleteOnboarding = queryUserOnboarding[0];

    if (getUser[0].title === "Managers") {
      const queryManager = await DataStore.query(Managers, (c) =>
        c.email("eq", deleteUser.email)
      );
      const specificManager = queryManager[0];
      await DataStore.delete(specificManager);
      await DataStore.delete(deleteUser);
      await DataStore.delete(deleteOnboarding);
    } else {
      await DataStore.delete(deleteUser);
      await DataStore.delete(deleteOnboarding);
    }

    handleCloseDelete();
  };

  const pickUser = (e) => {
    let userID = e.target.value;
    console.log(userList);
    const queryUser = async () => {
      try {
        const tryUser = await DataStore.query(Users, (c) => c.id("eq", userID));

        const usersManager = tryUser[0].managersID;

        const searchManager = await DataStore.query(Managers, (x) =>
          x.id("eq", usersManager)
        );
        setSelectedUser(tryUser[0]);
        setUserManager(searchManager[0]);
        setDeptSelect(tryUser[0].subsection);
        setRoleSelect(tryUser[0].rank);
        setNewManagerSelect(searchManager[0]);
      } catch (error) {
        console.log(error);
      }
    };
    queryUser();
  };
  return (
    <div className="mb-6 bg-white border-2 border-solid pb-24">
      <div className="blueOne p-16 text-white">
        <div className="text-center uppercase text-4xl">
          <div className="container text-left text-3xl text-white font-bold">
            Human Resources Dashboard
          </div>
        </div>
      </div>
      <Container>
        <div className="pt-4">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={onchange}
            className="border-2 rounded-lg border-blue-900 text-center"
          />
        </div>
        <Row xs={1} md={2} className="pt-8">
          <Col>
            <div className="text-blue-900 font-bold text-xl">
              Cervello Employee Database
            </div>
            <div className="h-80 border-2 border-primary overflow-auto">
              {userList.filter(filterFunction).map((x, i) => {
                return (
                  <div key={i} className="hover:bg-blue-900 hover:text-white">
                    <button onClick={pickUser} value={x.id}>
                      {x.first_name} {x.last_name} - {x.subsection}
                    </button>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col>
            <div className="text-blue-900 font-bold text-xl">Employee Info</div>
            {selectedUser === "" ? (
              <div>Select a user</div>
            ) : (
              <div className="align-center text-left">
                <Card className="h-80 border-2 border-primary">
                  <Card.Body>
                    <Card.Title className="text-blue-900">
                      {selectedUser.first_name} {selectedUser.last_name}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Department: {selectedUser.subsection}
                    </Card.Subtitle>
                    <div>
                      Email:{" "}
                      <a href={`mailto:${selectedUser.email}`}>
                        {selectedUser.email}
                      </a>
                    </div>
                    <div>Role: {selectedUser.rank}</div>
                    <div>Access Level: {selectedUser.title}</div>
                    <div>
                      Onboarding:{" "}
                      {!selectedUser.OnBoarding.CheckList_Completion ? (
                        <span className="font-bold text-red-500">
                          Incomplete
                        </span>
                      ) : (
                        <span className="font-bold text-green-500">
                          Complete
                        </span>
                      )}
                    </div>
                    <div>
                      Manager:{" "}
                      {!userManager ? (
                        <span>No manager listed.</span>
                      ) : (
                        <span>
                          {userManager.first_name} {userManager.last_name}
                        </span>
                      )}
                    </div>
                    <div>
                      Manager Email:{" "}
                      {!userManager ? (
                        <span>No manager listed.</span>
                      ) : (
                        <span>
                          <a href={`mailto:${userManager.email}`}>
                            {userManager.email}
                          </a>
                        </span>
                      )}
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        Edit User
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleShowEditName}>
                          Edit Name
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleShowEditDept}>
                          Edit Department
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleShowEditRole}>
                          Edit Role
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleShowEditManager}>
                          Edit Manager
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleShowDelete}>
                          Delete User
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Card.Footer>
                </Card>
              </div>
            )}
          </Col>
        </Row>

        {/* MODALS FOR EDITING USER INFO */}

        <Modal show={showEditName} onHide={handleCloseEditName}>
          <Modal.Header closeButton>
            <Modal.Title className="text-blue-900 ">Edit Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              You are currently editing {selectedUser.first_name}{" "}
              {selectedUser.last_name}'s name.
            </div>
            <div className="font-bold text-red-500">
              Please note that changes may take several minutes to process.
            </div>
            <div>
              {" "}
              <Form onSubmit={handleNameFormSubmit}>
                <Form.Group className="my-3" controlId="formBasicEmail">
                  <Form.Label className="font-bold text-blue-900">
                    First Name
                  </Form.Label>
                  <Form.Control
                    name="first_name"
                    required
                    type="text"
                    placeholder="Enter first name"
                  />
                </Form.Group>

                <Form.Group
                  className="font-bold text-blue-900 mb-6"
                  controlId="formBasicPassword"
                >
                  <Form.Label className="font-bold text-blue-900">
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="last_name"
                    placeholder="Enter last name"
                  />
                </Form.Group>
                <Button
                  variant="secondary"
                  className="mr-3"
                  onClick={handleCloseEditName}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showEditDept} onHide={handleCloseEditDept}>
          <Modal.Header closeButton>
            <Modal.Title className="text-blue-900 ">
              Edit Department
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are currently editing {selectedUser.first_name}{" "}
            {selectedUser.last_name}'s department.
            <div className="font-bold text-red-500">
              Please note that changes may take several minutes to process.
            </div>
            <Form onSubmit={handleDeptFormSubmit}>
              <Form.Group className="my-3" controlId="formBasicDept">
                <Form.Label className="font-bold text-blue-900">
                  Department
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic department"
                  >
                    {deptSelect}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {subList.map((data) => {
                      return (
                        <Dropdown.Item
                          key={data}
                          onClick={() => setDeptSelect(data)}
                        >
                          {data}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Button
                variant="secondary"
                className="mr-3"
                onClick={handleCloseEditDept}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showEditRole} onHide={handleCloseEditRole}>
          <Modal.Header closeButton>
            <Modal.Title className="text-blue-900 ">Edit Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are currently editing {selectedUser.first_name}{" "}
            {selectedUser.last_name}'s role.
            <div className="font-bold text-red-500">
              Please note that changes may take several minutes to process.
            </div>
            <Form onSubmit={handleRoleFormSubmit}>
              <Form.Group className="my-3" controlId="formBasicDept">
                <Form.Label className="font-bold text-blue-900">
                  Roles
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic department"
                  >
                    {roleSelect}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {roleList.map((data) => {
                      return (
                        <Dropdown.Item
                          key={data}
                          onClick={() => setRoleSelect(data)}
                        >
                          {data}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Button
                variant="secondary"
                className="mr-3"
                onClick={handleCloseEditRole}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showEditManager} onHide={handleCloseEditManager}>
          <Modal.Header closeButton>
            <Modal.Title className="text-blue-900 ">
              Edit User's Manager
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are currently editing {selectedUser.first_name}{" "}
            {selectedUser.last_name}'s manager.
            <div className="font-bold text-red-500">
              Please note that changes may take several minutes to process.
            </div>
            <Form onSubmit={handleManagerFormSubmit}>
              <Form.Group className="my-3" controlId="formBasicDept">
                <Form.Label className="font-bold text-blue-900">
                  Managers
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic department"
                  >
                    {newManagerSelect.first_name} {newManagerSelect.last_name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {managerList.map((data) => {
                      return (
                        <Dropdown.Item
                          key={data.id}
                          onClick={() => setNewManagerSelect(data)}
                        >
                          {data.first_name} {data.last_name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Button
                variant="secondary"
                className="mr-3"
                onClick={handleCloseEditManager}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title className="text-blue-900 ">Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="text-red-500">
              <span className="font-bold">WARNING:</span> You are about to
              delete user {selectedUser.first_name} {selectedUser.last_name}{" "}
              from the database. This action is irreversible. Deleting the user
              will remove their info from the database, and the user will lose
              all access to the portal. Proceed with caution.
            </span>
            <Form className="pt-3" onSubmit={handleDeleteFormSubmit}>
              <Button
                variant="secondary"
                className="mr-3"
                onClick={handleCloseDelete}
              >
                Cancel
              </Button>
              <Button variant="danger" type="submit">
                Delete User
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* END MODALS FOR EDITING USER INFO */}
      </Container>
    </div>
  );
}
