import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import TailWindNavbar from "./Navbar/TailWindNavbar";
import { Provider } from "react-redux";
import userReducer from "./features/user";
import { configureStore } from "@reduxjs/toolkit";
import Footer from "./Footer/Footer";

Amplify.configure(awsconfig);
const store = configureStore({
  reducer: {
    user: userReducer,
  },
  devTools: true,
});
function App() {
  let paths = [
    {
      path: "/",
    },
    {
      path: "Home",
    },
    {
      path: "Capabilities",
    },
    {
      path: "Logistics",
    },
    {
      path: "Mission",
    },
    {
      path: "Hypersonics",
    },
    {
      path: "CentersOfExcellence",
    },
    {
      path: "Contracts",
    },
    {
      path: "Careers",
    },
    {
      path: "Contact",
    },
    { path: "Success" },
    { path: "Leadership" },
    { path: "NewsRoom" },
    { path: "NewsPost/:slug" },
    { path: "NewsManage-Edit" },
    { path: "NewsManage-Delete" },
    { path: "Distribution" },
    { path: "Transportation" },
    { path: "Request" },
    { path: "Human-Resources" },
    { path: "White-Papers" },
    { path: "WP1" },
    { path: "WP2" },
    { path: "Capability-Request" },
    { path: "HyperSonics-Statement" },
    { path: "Logistics-Statement" },
    { path: "Distribution-Statement" },
    { path: "Future-Force-Statement" },
    { path: "Mission-Command-Statement" },
    { path: "Construction" },
    { path: "TechManagement" },
    { path: "Global" },
    { path: "Jorge-Aspiazu" },
    { path: "Kim-Parker" },
    {
      path: "Login",
    },
    {
      path: "Internal",
    },
    {
      path: "Internal-Calculator",
    },
    {
      path: "Internal-Reference",
    },
    {
      path: "Internal-Routes",
    },
    {
      path: "Internal-Managers",
    },
    {
      path: "Internal-Dashboard",
    },
    {
      path: "Internal-Notifications",
    },
    {
      path: "Manager-Routes",
    },
    {
      path: "Manager-Library",
    },
    {
      path: "Manager-Announcements",
    },
    {
      path: "Customer",
    },
    {
      path: "Customer-Capabilities",
    },
    {
      path: "Customer-Calculator",
    },
    {
      path: "Customer-Logistics",
    },
    {
      path: "PasswordReset",
    },
    {
      path: "Perform",
    },
    {
      path: "newsAlerts",
    },
    {
      path: "Manager-News",
    },
    {
      path: "John-Mahan",
    },
    {path: "Jennifer-King"},
    {
      path: "Karen-McMillan",
    },
    {
      path: "David-Ingram",
    },
    {
      path: "Thomas-Desiderio",
    },
    {
      path: "Valerie-Mahan",
    },
    {
      path: "Elline-Binner",
    },
    {
      path: "Boyd-McMurtrey",
    },
    {
      path: "Daniel-Grenier",
    },
    {
      path: "Hunter-Haltom",
    },
    {
      path: "John-Gerlach",
    },
    {
      path: "MissionCommand",
    },
    {
      path: "Future",
    },
    {
      path: "HyperCOE",
    },
    {
      path: "GlobalCOE",
    },
    {
      path: "Manager-SignUp",
    },
    {
      path: "Internal-Checklist",
    },
    {
      path: "Manager-EmployeeView",
    },
    {
      path: "Manager-Checklist",
    },
    {
      path: "ExecutiveTeam",
    },
    {
      path: "Nikisha-Bibbs",
    },
    { path: "NewsManage" },
  ];

  return (
    <div className="App h-auto">
      <Router>
        <Switch>
          <>
            <div>
              {paths.map((x, i) => {
                return (
                  <Route key={i} path={`/${x.path}`}>
                    <Provider store={store}>
                      <TailWindNavbar path="/Home" />
                    </Provider>
                  </Route>
                );
              })}
            </div>
          </>
        </Switch>
        <div className="md:container md:mx-auto md:px-28 md:h-auto">
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
