import React from 'react';
import FutureForceLogo from './FutureForceLogo.png';

export default function FutureForce() {
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-48'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl md:pt-48 h-auto'>
				<h1 className='font-black text-3xl text-black'>
					Future Force Modernization
				</h1>

				<img
					src={FutureForceLogo}
					className='content-center h-72 w-auto mx-auto'
					alt="Future Force Logo"
				/>

				<div className='md:container md:mx-auto md:px-28'>
					<p>
						Mission Command & Future Force Modernization- test and enhance
						operating concepts, utilize and draw on emerging technologies, and
						anticipate fluctuations in the operating environment. Joint Force
						Employment (Operate), Joint Force Development (Adapt) and Joint
						Force Design (Innovate) will balance current and future risk to
						enhance Global Integrated Operations.
					</p>

					<p>
						With Future Force Modernization, we can test and enhance operating
						concepts, utilize and draw on emerging technologies, and anticipate
						fluctuations in the operating environment. Joint Force Employment
						(Operate), Joint Force Deployment (Adapt), and Joint Force Design
						(innovate) will balance current and future risk to enhance Global
						Integrated Operations.
					</p>

					<p>Past Experience includes, but is not limited to :</p>
					<ul className='text-left list-disc'>
						<li>
							Strategic Defense Concepts and Capabilities (near, mid, and far
							term)
						</li>
						<li>
							Tactical Defense Concepts and Capabilities (near, mid, and far
							term).
						</li>
						<li>
							Overall Service centric Organizational Development and Change.
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
