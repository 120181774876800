import { React, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Clearwater from "./Clearwater-beach-florida-oceanview.jpg";
import "./Careers.css";
import Collapse from "react-bootstrap/Collapse";

export default function Careers() {
  const [openHealth, setOpenHealth] = useState(false);
  const [openRetirement, setOpenRetirement] = useState(false);
  const [openTuition, setOpenTuition] = useState(false);
  const [openHolidays, setOpenHolidays] = useState(false);

  return (
    <div className="mx-auto text-black ">
      <div className="mb-6 bg-white mb-5">
        <row>
          <div className="mx-auto">
            <Image src={Clearwater}></Image>
          </div>
          <div className="text-right text-black text-xs">
            <a
              href="https://creativecommons.org/licenses/by-sa/4.0/"
              rel="noreferrer"
              target="_blank"
            >
              CC BY-SA 4.0
            </a>
          </div>
          <div className="col-md-6 mx-auto">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900">
                    Careers
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mt-5">
                    <div className="text-left text-2xl text-blue-900">
                      Why Work For Us?
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    At Cervello Global, every single employee is a valued team
                    member and essential to our success. We are a growing
                    veteran-owned small business serving the military,
                    government and private industry. If you are high
                    performance, mission driven and committed to customer
                    excellence, Cervello might be a fit for you. Check out our
                    current openings.
                  </p>
                  <div
                    className="container mb-5 mt-5"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="d-grid gap-2">
                      <button
                        className="py-2 px-4 rounded blueOne"
                      >
                        <a
                          rel="noreferrer"
                          className="text-white no-underline"
                          target="_blank"
                          href="https://www2.appone.com/Search/Search.aspx?ServerVar=cervellotechnologiesllc.appone.com"
                        >
                          Career Opportunities
                        </a>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container>
              <Row>
                <Col>
                  <div className="text-left text-2xl text-blue-900">
                    Employee Benefits Program
                  </div>
                  <p className="text-left text-black text-base">
                    The below list of benefits are available through Cervello
                    Global. We offer a complete package of benefits, which
                    include employer-paid plans and voluntary options.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5">
                  <div className="pb-1">
                    <div
                      onClick={() => setOpenRetirement(!openRetirement)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openRetirement}
                      className="mx-auto text-white text-left text-xl blueOne p-4 cursor-pointer row"
                    >
                      <div className="col-10 text-left">Retirement Plan</div>
                      {!openRetirement ? (
                        <div className="col-2 text-right"> +</div>
                      ) : (
                        <div className="col-2 text-right">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={openRetirement}>
                        <div
                          id="example-collapse-text"
                          className="container blueOne text-white text-left"
                        >
                          <p className="text-base">
                            The Cervello Retirement Plan provides an essential
                            measure of retirement security for our employees'
                            futures.
                          </p>
                          <ul className=" list-disc text-base">
                            <li className="text-left">
                              Ability to contribute to company 401(k) plan after
                              90 days of service
                            </li>
                            <li className="text-left">
                              Pre-tax contributions and/or ROTH plans available
                            </li>
                            <li className="text-left">
                              Company Match – Cervello will match 1:1 up to 4%
                              of the employee’s annual base salary.
                            </li>
                            <li className="text-left">
                              Profit Sharing
                            </li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  <div className="pb-1">
                    <div
                      onClick={() => setOpenHealth(!openHealth)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openHealth}
                      className="mx-auto text-white text-left text-xl blueOne p-4 cursor-pointer row"
                    >
                      <div className="col-10 text-left">
                        Health & Benefits Program
                      </div>
                      {!openHealth ? (
                        <div className="col-2 text-right">+</div>
                      ) : (
                        <div className="col-2 text-right">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={openHealth}>
                        <div
                          id="example-collapse-text"
                          className="container blueOne text-white text-left"
                        >
                          <ul className="list-disc">
                            <li className="text-left text-base">
                              Health Insurance (Subsidized by Company - Group)
                            </li>
                            <li className="text-left">
                              Company pays 50% of the employee only insurance
                              plan premium cost, for full-time employees.
                            </li>
                            <li className="text-left">
                              Dental Insurance (Subsidized by Company - Group)
                            </li>
                            <li className="text-left">
                              Vision Insurance (Subsidized by Company - Group)
                            </li>
                            <li className="text-left">
                              Group Term Life Insurance & AD&D program
                            </li>
                            <li className="text-left">
                              Flexible Dependent Spending Account
                            </li>
                            <li className="text-left">
                              Flexible Medical Spending Account
                            </li>
                            <li className="text-left">
                              Short-Term Disability
                            </li>
                            <li className="text-left">
                              Balance Works (Company Paid)
                            </li>
                            <li className="text-left">
                              Working Advantage (Company Paid)
                            </li>
                            <li className="text-left">
                              Financial Fitness – FinFit (Company Paid)
                            </li>
                            <li className="text-left">
                              Cervello Technologies On-Line Training (Company
                              Paid)
                            </li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  <div className="pb-1">
                    <div
                      onClick={() => setOpenTuition(!openTuition)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openTuition}
                      className="mx-auto text-white text-left text-xl blueOne p-4 cursor-pointer row"
                    >
                      <div className="col-10 text-left">Tuition Assistance</div>
                      {!openTuition ? (
                        <div className="col-2 text-right">+</div>
                      ) : (
                        <div className="col-2 text-right">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={openTuition}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-white text-left"
                        >
                          <div className="container text-left text-base">
                            Cervello Global offers a tuition assistance program
                            to eligible employees who complete job-related
                            courses. The company will reimburse a portion of
                            tuition and books up to $3,000.00 per calendar year.
                          </div>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  <div className="pb-1">
                    <div
                      onClick={() => setOpenHolidays(!openHolidays)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openHolidays}
                      className="mx-auto text-white text-left text-xl blueOne p-4 cursor-pointer row"
                    >
                      <div className="col-10 text-left">Observed Holidays</div>
                      {!openHolidays ? (
                        <div className="col-2 text-right">+</div>
                      ) : (
                        <div className="col-2 text-right">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={openHolidays}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-white"
                        >
                          <div className="container text-left text-base">
                            Cervello Global generally aligns our approved list
                            of company paid holidays with the approved list of
                            federal holidays offered to employees working within
                            our Government customer accounts.
                          </div>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </row>
      </div>
    </div>
  );
}
