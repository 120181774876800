import React from "react";
import "./jennifer.css";
import { Row, Col, Card } from "react-bootstrap/";
import KingPic from "./king_pic.png";
export default function Jennifer() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Jennifer King - Director, Technical Writing & Publications
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={KingPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                Jennifer King is a former Project Editor and Writer for a metro
                newspaper who transitioned to Technical Writer for the United
                States Marine Corps. She has been held in high regard for her
                dedication and quality of work throughout her entire career. She
                prides herself on attention to detail and production of
                first-rate material.
              </p>
              <p className="text-base text-black">
                Jennifer's role with Cervello Global is to produce high-quality
                documents and publications that represent and elevate Cervello's
                capabilities, goals, and accomplishments. She assists all of her
                Cervello teammates with any of their writing or publication
                endeavors.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
