import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

export default function Transportation() {
  return (
    <div className="mx-auto">
      <div className="mb-6 bg-white border-2 border-opacity-300 border-solid shadow-inner-2xl pb-64">
        <div className="text-2xl font-bold text-blue-900 mt-12 mb-12 text-center">
          Transportation Services
        </div>
        <div className="container col-md-6">
          <p className="text-center font-bold text-blue-900">
            Important: You must make arrangements with Cervello Global to
            purchase transportation services. DO NOT pay in advance of
            arrangements. Payment for transportation is due at time of services
            rendered.
          </p>
          {/* <div className="pb-4">
            <Link to="Request"> Request Transportation Services</Link>
          </div> */}
          <DropdownButton id="dropdown-basic-button" title="Select a Route">
            <Dropdown.Item
              href="https://buy.stripe.com/fZe4iYcyM5gqbGEcMN"
              rel="noreferrer"
              target="_blank"
            >
              USAG Stuttgart - Local Transportation Only
            </Dropdown.Item>
            <Dropdown.Item
              href="https://buy.stripe.com/3csdTy8iw9wGbGE147"
              rel="noreferrer"
              target="_blank"
            >
              Frankfurt International Airport &#8646; USAG Stuttgart
            </Dropdown.Item>
            <Dropdown.Item
              href="https://buy.stripe.com/aEUaHmfKY10acKIcMO"
              rel="noreferrer"
              target="_blank"
            >
              Ramstein AB &#8646; USAG Stuttgart
            </Dropdown.Item>
            <Dropdown.Item
              href="https://buy.stripe.com/8wMeXCgP224eh0Y9AE"
              rel="noreferrer"
              target="_blank"
            >
              Munich &#8646; USAG Stuttgart
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
}
