import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Soldier from "./soldier.jpg";

export default function Future() {
  const [logisticsDetails, setLogisticsDetails] = useState(false);

  return (
    <div className="md:-mt-36 text-black">
      <div className="mb-6 bg-white">
        <row>
          <div>
            <Image src={Soldier}></Image>
          </div>
          <div className="col-md-6 mx-auto pb-12">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900 mt-5">
                    Future Force Modernization
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    Future Force Modernization is a newly created capability
                    center within Cervello Global Corporation. Cervello Global
                    Corporation prides itself in being efficient, relevant, and
                    instrumental in supporting our customers with the expertise
                    of our specialized experts within different ranges of
                    fields. Future Force Modernization is focused on being the
                    forefront in supporting and preparing the future fighting
                    force and their capabilities for mission success.
                  </p>
                  <p className="text-left text-base">
                    Future Force Modernization endeavors to provide enhancement
                    to operating concepts to assure efficiency and effectiveness
                    for all future warfighters. Our center development is
                    improved by utilizing and introducing future emerging
                    technologies from around the globe. Our staff's overarching
                    experience within their specialized fields enables us to
                    anticipate any changes to operational requirements
                    efficiently and effectively.
                  </p>
                  <p className="text-left text-base">
                    Our primary focus within Future Force Modernization is to
                    enhance our future forces to be adaptive, innovative, and
                    train as they fight with the utilization of emerging
                    technologies for successful mission outcomes.
                  </p>
                  <div className="pb-1">
                    <div
                      onClick={() => setLogisticsDetails(!logisticsDetails)}
                      aria-controls="example-collapse-text"
                      aria-expanded={logisticsDetails}
                      className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                    >
                      <div className="col-10 text-left text-white">
                        Core Capabilities
                      </div>
                      {!logisticsDetails ? (
                        <div className="col-2 text-right text-white">+</div>
                      ) : (
                        <div className="col-2 text-right text-white">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={logisticsDetails}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-black text-left"
                        >
                          <ul className=" list-disc text-base text-white">
                            <li>Provide enhancement to operating concepts </li>
                            <li>
                              Assure efficiency and effectiveness for all
                              operating forces
                            </li>
                            <li>
                              Utilize and introduce emerging technologies from
                              around the globe
                            </li>
                            <li>
                              Operate, Adapt, and Innovate balances current and
                              future risk
                            </li>
                            <li>
                              Prepare for the future fighting force mission
                              success
                            </li>
                            <li>
                              Develop a force planning construct to size and
                              shape the U.S. military to defeat an adversary
                            </li>
                            <li>
                              Assess force structure and redundant capabilities
                              utilizing a cost-per-effect approach
                            </li>
                            <li>
                              Public-private partnerships to adapt initiative
                              approaches that lead to modernization efforts
                            </li>
                            <li>
                              Employ the Adaptive Acquisition approach to enable
                              the workforce to tailor strategies to deliver
                              better solutions faster
                            </li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </row>
      </div>
    </div>
  );
}
