import { React, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Blog } from "../models";
import { Form } from "react-bootstrap/";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export default function NewsManage() {
  const [values, setValues] = useState([]);
  const [tagValues, setTagValues] = useState([]);
  const [show, setShow] = useState(false);
  const [toUrl, setToUrl] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handle = (e) => {
    const value = e.target.value.replace(/\r\n/g, "\n").split("\n");
    const filtered = value.filter((x) => {
      return x !== "";
    });
    setValues(filtered);
  };

  const handleTags = (e) => {
    const userTags = e.target.value
      .toLowerCase()
      .replace(/\r\n/g, "\n")
      .split(", ");
    const filteredTags = userTags.filter((y) => {
      return y !== "";
    });
    setTagValues(filteredTags);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let title = e.target.elements.title?.value.trim();
    let blurb = e.target.elements.blurb?.value.trim();
    let splash = e.target.elements.splash?.value.trim();
    let content = values;
    let author = e.target.elements.author?.value.trim();
    let authorPic = e.target.elements.authorPic?.value.trim();
    let authorBio = e.target.elements.bio?.value.trim();
    let tags = tagValues;
    let url = title
      .toLowerCase()
      .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "")
      .replace(/\s+/g, "-");
    setToUrl(url);

    try {
      await DataStore.save(
        new Blog({
          author: author,
          title: title,
          content: content,
          image: splash,
          tags: tags,
          blurb: blurb,
          url: url,
          authorBio: authorBio,
          authorPic: authorPic,
        })
      );
      handleShow();
    } catch (error) {
      console.log(error);
      return;
    }
  };

  return (
    <div className="mx-auto">
      <div className="mb-6 bg-white border-2 border-opacity-300 border-solid shadow-inner-2xl shadow-2xl">
        <div className="text-2xl font-bold text-blue-900 mt-12 mb-12 text-center ">
          Delete a News Post
        </div>
        <div className="container md:pt-10 row mx-auto sm:mt-5">
          <div className="col-md-6 md:pt-20 mx-auto">
            <Form onSubmit={handleFormSubmit} className="container col-md-6">
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Post Title
                </Form.Label>
                <Form.Control
                  name="title"
                  required
                  type="text"
                  placeholder="Enter title of your post (ex. Florida Man Wrestles with Alligator, Loses)"
                />
              </Form.Group>
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Post Blurb
                </Form.Label>
                <Form.Control
                  name="blurb"
                  required
                  type="text"
                  placeholder="A short blurb introducing the subject in 2 or 3 lines"
                />
              </Form.Group>
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Splash image
                </Form.Label>
                <Form.Control
                  name="splash"
                  required
                  type="text"
                  placeholder="Must be image url (ex. https://cdn.pixabay.com/photo/2017/11/14/13/06/kitty-2948404_1280.jpg)"
                />
              </Form.Group>
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Article Content
                </Form.Label>
                <Form.Control
                  onChange={handle}
                  name="content"
                  required
                  type="text"
                  as="textarea"
                  placeholder="The meat and potatoes. Please click the + button for each new paragraph."
                />
              </Form.Group>
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Author Name
                </Form.Label>
                <Form.Control
                  name="author"
                  required
                  type="text"
                  placeholder="Your name, first and last please"
                />
              </Form.Group>
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Author image
                </Form.Label>
                <Form.Control
                  name="authorPic"
                  required
                  type="text"
                  placeholder="Must be image url (ex. https://cdn.pixabay.com/photo/2017/11/14/13/06/kitty-2948404_1280.jpg)"
                />
              </Form.Group>
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Author Bio
                </Form.Label>
                <Form.Control
                  name="bio"
                  required
                  type="text"
                  placeholder="A short author bio, 3 - 5 lines"
                />
              </Form.Group>
              <Form.Group className="mb-3 text-left">
                <Form.Label className="text-lg  text-blue-900 font-bold">
                  Tags
                </Form.Label>
                <Form.Control
                  onChange={handleTags}
                  name="tags"
                  required
                  type="text"
                  placeholder="A few keywords, separated by commas"
                />
              </Form.Group>
              <button
                className="bg-blue-500 rounded hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 mb-12"
                type="submit"
              >
                Submit
              </button>
            </Form>
          </div>
        </div>
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Success!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your news post was successfully created!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Link to={"NewsPost/" + toUrl}>
                <Button variant="primary">See Post</Button>
              </Link>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    </div>
  );
}
