import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap/';
import ThomasPic from './Thomas.jpg';

export default function Thomas() {
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 md:h-auto'>
				<Container className='mt-10'>
					<Row>
						<Col>
							<Card className='mt-6' style={{ width: '18rem' }}>
								<Card.Img variant='top' src={ThomasPic} />
								<Card.Body className='text-black'>
									<Card.Title>Thomas Desiderio</Card.Title>
									<Card.Text className='text-center'>
										Distribution Process Advocate for Australia
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col xs={6} className='mr-24 pt-24'>
							<p className='text-xl text-black'>
								Thomas Desiderio is a Distribution Process Advocate providing
								assistance to the forces through supply chain management,
								customs and agriculture entry procedures, strategic movement
								planning, and transportation management.
							</p>{' '}
							<p className='text-xl text-black'>
								Thomas is a USMC veteran with 37 years’ service with extensive
								knowledge of the global transportation network. He possesses
								extensive knowledge of Asia Pacific and Australia with an
								emphasis on international transportation and customs
								requirements for Asia Pacific countries. He was a Logistics
								Officer with an emphasis on worldwide strategic movement
								planning and has knowledge of both tactical and operational
								functions on the multi service level.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
