import React from "react";
import "./binner.css";
import { Row, Col, Card } from "react-bootstrap/";
export default function Binner() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Elline Binner - Vice President, Contracts, Pricing, & Quality
            Assurance
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            {/* <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" />
              </Card>
            </Col> */}
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                Elline Binner began her career in the defense industry nearly 30
                years ago at Redstone Arsenal Alabama. She has held a variety of
                positions including Senior Logistics Engineer, Performance Based
                Logistics Subject Matter Expert, Program Planning and Budget
                Analyst, as well as several leadership roles. Her vast knowledge
                lends itself perfectly to finding and obtaining pertinent
                defense contracts.
              </p>
              <p className="text-base text-black">
                As a member of Team Cervello, Elline brings a high level of
                knowledge, expertise, dedication, and enthusiasm for the
                organization. She specializes in finding and obtaining new lines
                of effort to engage our Capability Centers of Excellence to
                support customers from DoD, commercial, and other industry
                partners. In addition to this task, she is an expert in Supply
                Chain Management, Performance Based Logistics Implementation,
                Streamlined Acquisition Processes, contract administration, and
                Planning, Programming, Budgeting, and Execution (PPBE) process
                methodology. Elline is a multi-faceted team member who assists
                whenever and wherever it is needed.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
