import React from "react";
import "./aspiazu.css";
import { Row, Col, Card } from "react-bootstrap/";
import AspiazuPic from "./aspiazu_pic.png";
export default function Aspiazu() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            Jorge Aspiazu - Vice President, Government & Business Alliances
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={AspiazuPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                Jorge Aspiazu's mission as Cervello's Vice President of
                Government & Business Alliances is to meet the principal demands
                and challenges facing the Department of Defense. Jorge oversees
                a diverse portfolio of contracts and provides defense
                partnership technical services and integrated program management
                solutions to the U.S. and its allies. He provides strategic
                planning, business development, and delivery management for
                Cervello.
              </p>
              <p className="text-base text-black">
                Prior to joining Cervello, Jorge served in the United States
                Marine Corps, retiring at the rank of Master Gunnery Sergeant
                after 27 years of service. He served as an Aviation Logistician
                in every aviation platform including Marine Helicopter Squadron
                One (HMX-1). His operational experience spans across North
                America, the Middle East, and Southeast Asia. Jorge also served
                as a Drill Instructor, Senior Drill instructor, Series Gunnery
                Sergeant, 3rd Recruit Training Battalion Drill Master, Officer
                Candidate School Drill Instructor, and OCS Battalion Drill
                Master where he was instrumental in the transformation of
                civilians into basically trained enlisted and officer members of
                the Marine Corps. His success as a leader contributed to his
                selection to serve as the Senior Executive Advisor to the 19th
                Sergeant Major of the Marine Corps, Sergeant Major Troy E.
                Black, from 2019 to 2022.
              </p>
              <p className="text-base text-black">
                Jorge holds a bachelor's and a master's degree in Organizational
                Management from Ashford University. He is also a graduate of
                multiple Marine Corps professional military courses, Senior
                Enlisted Professional Military Academies, Supply Chain
                Management/Lean Six Sigma certified, Resilience-Building
                Leadership Professional Trainer, and a graduate of the Penn
                State Senior Executive Logistics Course.{" "}
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
