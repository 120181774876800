import React from 'react';
import { Row, Col, Container } from 'react-bootstrap/';
import truck from './Assets/custtomersitehome2.jpg';
export default function Customer_Home() {
	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 h-screen'>
				<h1 className='font-black text-3xl text-black mt-10 mb-10'>
					Welcome to the Cervello Global of Australia Customer Site
				</h1>
				<Container>
					<Row>
						<Col>
							<img className='w-full' src={truck} alt='' />
						</Col>

						<Col className='ml-10 text-black'>
							<h4 className='text-center mb-10'>
								At Cervello Global of Australia we use our worldwide experience
								and expertise to ensure your cargo is transported securely,
								effectively, and efficiently. Cervello Global works on your
								schedule to meet your needs, ensures cargo frustration is
								mitigated, and customers receive what they need when they need
								it.
							</h4>

							<h4>
								Our customer portal supports constant contact and allows
								consistent real-time updates to facilitate our ability to
								maintain our highest standards of service to you.
							</h4>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
