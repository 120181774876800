import { React, useEffect } from "react";
import { USMap } from "../../Map/Map";
import { Col, Row, Container } from "react-bootstrap/";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import { DataStore } from "@aws-amplify/datastore";
import { Users } from "../../models";
import checkUser from "../../Auth/auth";
export default function Routing() {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: `${process.env.REACT_APP_WEATHER_API}`,
    lat: "-25",
    lon: "135",
    lang: "en",
    unit: "metric",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const models = await DataStore.query(Users);
      checkUser(models);
    };
    fetchUsers();
  }, []);

  const LocalMap = () => {
    return <USMap />;
  };

  return (
    <div className="md:container mx-auto  md:px-28 text-black -mt-32">
      <div className="md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 ">
        <h1 className="font-black text-3xl text-white pb-5">
          Routing Planning
        </h1>
        <Container>
          <Row>
            <Col>
              <div>
                <div className="bg-white text-black rounded-sm border-black border-opacity-100 border-solid border-4">
                  <p className=" text-center"> Weather Report</p>
                  <Col>
                    <ReactWeather
                      isLoading={isLoading}
                      errorMessage={errorMessage}
                      data={data}
                      lang="en"
                      locationLabel="Australia"
                      unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
                      showForecast
                    />
                  </Col>
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <div className="bg-white text-black rounded-sm border-black border-opacity-100 border-solid border-4">
                  <p className=" text-center">Route Advisories</p>
                </div>
              </div>
            </Col>
            <Row className="pt-10">
              <Col>
                <LocalMap />
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </div>
  );
}
