import React from "react";
import "./About.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Blackhawk from "./blackhawk.jpg";

export default function AboutUs() {
  return (
    <div className="md:-mt-56 text-black">
      <div className="mb-6 bg-white">
        <div>
          <Image src={Blackhawk}></Image>
        </div>

        <div className="col-md-6 mx-auto">
          <Container>
            <Row>
              <Col>
                <div className="text-left text-4xl text-blue-900 mt-5">
                  About Us
                </div>
              </Col>
            </Row>
            <div className="mt-5 pb-5">
              <Row>
                <Col>
                  <div className="text-left text-2xl text-blue-900">
                    Our Company
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-left text-base mt-2">
                    Cervello Global Corporation is a Defense Contractor with
                    more than 15 years of experience developing a global
                    workforce of professionals committed to the operating
                    forces. More than 90 percent of Cervello's team are former
                    military leaders with expertise in specific strategic,
                    operational, and tactical operations. Employees are located
                    throughout the United States, Guam, and Kuwait with
                    subsidiaries in Australia and Germany and branch offices in
                    Japan and Singapore.
                  </div>
                </Col>
              </Row>
            </div>
            <hr></hr>
            <div className="mt-5 pb-5">
              <Row>
                <Col>
                  <div className="text-left text-2xl text-blue-900">
                    Our Vision
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-left text-base mt-2">
                    Cervello Global Corporation's mission is to exceed our
                    customers' expectations by providing quality
                    solution-focused consulting and technology engineering
                    services; on time and within scope and budget. We are High
                    Performance, Mission Driven.
                  </div>
                  <br />
                  <div className="text-left text-base">
                    Our vision is to position the company as a global business
                    consulting and solution engineering firm, with the proven
                    knowledge, skills, and abilities to develop
                    strategic-oriented architectures, in support of dynamic
                    information sharing and data interoperability.
                  </div>
                </Col>
              </Row>
            </div>
            <hr></hr>
            <div className="mt-5 pb-5">
              <Row>
                <Col>
                  <div className="text-left text-2xl text-blue-900">
                    Our Customers
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-left text-base mt-2">
                    Cervello is currently providing or has provided valuable
                    contract support to the Defense Information Systems Agency
                    (DISA), Office Secretary of Defense Joint Staff (OSD), US
                    Navy Naval Air Systems Command (NAVAIR), US Central Command
                    (USCENTCOM), US European Command (USEUCOM); US Special
                    Operations Command (USSOCOM), US Northern Command
                    (USNORTHCOM), US Strategic Command (USSTRATCOM), National
                    Reconnaissance Office (NRO), United States Marine Corps
                    (USMC), and the Department of Veterans Affairs (VA);
                    Cervello and its leadership's supports many stakeholders
                    worldwide.
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
