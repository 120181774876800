import React from "react";
import "./Excellence.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Humvee from "./humvee.jpg";
import ContainerShip from "./container.jpg";
import Aerial from "./mc2-block.png";
import Missile from "./missile.jpg";
import Future from "./drone.jpg";
import Transport from "./transportation.jpg";
export default function Excellence() {
  return (
    <div className="md:-mt-24 text-black">
      <div className="mb-6 bg-white">
        <div>
          <div>
            <Image src={Humvee}></Image>
          </div>

          <div className="col-md-12 mx-auto">
            <Container>
              <Row>
                <Col>
                  <div className="text-center text-4xl text-blue-900 mt-5">
                    Our Capabilities
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-black">
                  <p className="pt-3 text-center text-xl">
                    Cervello Global Corporation utilizes decades of experience
                    to provide critical thinking and streamlined management
                    across our Centers of Excellence (COE) to provide customers
                    with effective and efficient techniques and procedures to
                    ensure success.
                  </p>
                </Col>
              </Row>
              <Row className="g-4">
                <Col>
                  <Card className="md:mx-60">
                    <Card.Img variant="top" src={Transport} />
                    <Card.Body className="bg-gray-200">
                      <Card.Title className="text-blue-900">
                        Distribution and Transportation Services
                      </Card.Title>
                      <Card.Text className="text-base">
                        Cervello Global Distributors focuses on the
                        transportation and distribution of cargo with an
                        emphasis on speed of service. With an integrated
                        platform to respond to customer requests, process
                        electronic purchase orders, manage invoices, and deliver
                        on time, we are able to meet client needs efficiently.
                        Our highly trained workforce comes with years of
                        distribution experience in providing effective
                        strategies to address last tactical mile delivery
                        challenges.
                      </Card.Text>
                      <Link to="/Distribution">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row xs={1} md={2} className="g-4 pt-10">
                <Col>
                  <Card>
                    <Card.Img variant="top" src={ContainerShip} />
                    <Card.Body className="bg-gray-200 -mt-20">
                      <Card.Title className="text-blue-900">
                        Global Logistics Center of Excellence
                      </Card.Title>
                      <Card.Text className="text-base">
                        The Global Logistics Center of Excellence specializes in
                        deployment, distribution, and transportation logistics
                        management. We focus on analyzing efficiency and
                        effectiveness of the logistics enterprise and visibility
                        across the supply chain. We design, build, and run
                        comprehensive global logistics solutions for our
                        customers in a multinational environment with direct
                        interaction with United States Transportation Command,
                        Defense Logistics Agency, and foreign governments.
                      </Card.Text>
                      <Link to="/Logistics">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Img variant="top" src={Aerial} />
                    <Card.Body className="bg-gray-200 -mt-20">
                      <Card.Title className="text-blue-900">
                        Mission Command & Control
                      </Card.Title>
                      <Card.Text className="text-base">
                        Mission Command and Control (MC2) provides systems
                        analysis, engineering, integration, and architecture
                        Subject Matter Expert (SME) support for the DoD
                        including the Office of the Secretary of Defense (OSD),
                        Services, and US Special Operations Command (USSOCOM).
                        MC2 SMEs employ Cervello's patented test methodology to
                        ensure the best equipment, knowledge, and tactics,
                        techniques, and procedures (TTPs) are in place to
                        support the DoD.
                      </Card.Text>
                      <Link to="/Mission">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row xs={1} md={2} className="g-4 pt-10 pb-20">
                <Col>
                  <Card>
                    <Card.Img variant="top" src={Future} />
                    <Card.Body className="bg-gray-200 -mt-12">
                      <Card.Title className="text-blue-900">
                        Future Force Modernization
                      </Card.Title>
                      <Card.Text className="text-base">
                        Future Force Modernization tests and enhances operating
                        concepts, utilizes and draws on emerging technologies,
                        anticipates fluctuations in the operating environment,
                        and provides expertise for rapidly and effectively
                        developing and delivering the future force.
                      </Card.Text>
                      <Link to="/Future">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Img variant="top" src={Missile} />
                    <Card.Body className="bg-gray-200 -mt-20">
                      <Card.Title className="text-blue-900">
                        Hypersonics Center of Excellence
                      </Card.Title>
                      <Card.Text className="text-base">
                        Hypersonics and Emerging Technologies provides
                        engineering, test, and analysis services to USSTRATCOM
                        and DoD in the areas of Hypersonic Strike Weapon,
                        Command and Control, Hypersonic Concepts of Operation,
                        and Hypersonic Modeling and Simulation.
                      </Card.Text>
                      <Link to="/Hypersonics">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
