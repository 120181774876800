import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Transport from "./transportation.jpg";
export default function Distribution() {
  const [distributionDetails, setDistributionDetails] = useState(false);
  return (
    <div className="md:-mt-24 text-black">
      <div className="mb-6 bg-white">
        <row>
          <div>
            <Image src={Transport}></Image>
          </div>
          <div className="col-md-6 mx-auto pb-12">
            <Container>
              <Row>
                <Col>
                  <div className="text-left text-4xl text-blue-900 mt-5">
                    Transportation & Distribution
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left text-black">
                  <p className="text-left text-base">
                    Cervello Global Distributors focuses on the transportation
                    and distribution of cargo with an emphasis on speed of
                    service. With an integrated platform to respond to customer
                    requests, process electronic purchase orders, manage
                    invoices, and deliver on time, we are able to meet client
                    needs efficiently. Our highly trained workforce comes with
                    years of distribution experience in providing effective
                    strategies to address last tactical mile delivery
                    challenges.
                  </p>
                  <p className="text-left text-base">
                    One of the greatest assets of Cervello Global Distributors
                    is our global reach. Distribution subsidiaries have been
                    established in Germany, Australia, and Italy with several
                    more locations on the horizon. We have the ability to
                    provide our core competency services to DoD and civilian
                    commercial entities on multiple continents.
                  </p>
                  <p className="text-left text-base">
                    Our international locations offer comprehensive support and
                    extensive experience to commercial and government
                    organizations across Europe, Africa, and Australia. As
                    subsidiaries of Cervello Global Corporation, our
                    distribution services include material transportation,
                    acquisition, and logistics process improvements. Together
                    with our global partners, we work tirelessly to innovate,
                    communicate, and deliver cost-effective solutions to meet
                    our customers' unique logistics needs.
                  </p>
                  <div className="pb-1">
                    <div
                      onClick={() =>
                        setDistributionDetails(!distributionDetails)
                      }
                      aria-controls="example-collapse-text"
                      aria-expanded={distributionDetails}
                      className="mx-auto text-black text-left text-lg p-3 cursor-pointer row blueOne"
                    >
                      <div className="col-10 text-left text-white">
                        Core Capabilities
                      </div>
                      {!distributionDetails ? (
                        <div className="col-2 text-right text-white">+</div>
                      ) : (
                        <div className="col-2 text-right text-white">-</div>
                      )}
                    </div>
                    <div>
                      <Collapse in={distributionDetails}>
                        <div
                          id="example-collapse-text"
                          className="blueOne text-black text-left"
                        >
                          <ul className=" list-disc text-base text-white">
                            <li>
                              Supply, distribution and transportation logistics
                              management, consulting, and analysis
                            </li>
                            <li>
                              Continuous analysis of lifecycle logistics
                              supportability to improve transit times, reduce
                              costs, gain efficiencies, and streamline
                              decision-making processes
                            </li>
                            <li>
                              Design, create, and maintain comprehensive global
                              logistics solutions for our customers
                            </li>
                            <li>
                              Expert logisticians, distribution process
                              advocates, and cargo expediters distributed
                              worldwide that provide physical distribution and
                              transportation operations support
                            </li>
                            <li>
                              Proven corporate competency working in a
                              multinational environment and direct interaction
                              with the United States Transportation Command,
                              Defense Logistics Agency, and Foreign Governments{" "}
                            </li>
                            <li>
                              International Traffic in Arms Regulations
                              compliant (ITAR).
                            </li>
                            <li>
                              Material transportation within Germany,
                              specifically within the Ramstein Air Base,
                              Frankfurt, Munich, and Stuttgart regions.
                            </li>
                          </ul>
                          <br />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </row>
      </div>
    </div>
  );
}
