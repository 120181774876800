import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import "firebase/auth";

export const app = firebase.initializeApp({
  projectId: "cervello-australia-6d843",
  appId: "1:824940030678:web:737f5f7f7e71bb10b0b777",
  storageBucket: "cervello-australia-6d843.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyBI_nQnsaSC6PHyDr03grvFMPXhlvbYJn0",
  authDomain: "cervello-australia-6d843.firebaseapp.com",
  messagingSenderId: "824940030678",
  measurementId: "G-CE9ZZZWJCG",
});