import React, { useState, useEffect } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import { app } from "../base";
import DropdownItem from "react-bootstrap/DropdownItem";
const db = app.firestore();

export default function BenefitsDrop({ store }) {
  const [healthForms, setHealthForms] = useState([]);
  const [dentalForms, setDentalForms] = useState([]);
  const [visionForms, setVisionForms] = useState([]);
  const [retirementForms, setRetirementForms] = useState([]);
  const [lifeForms, setLifeForms] = useState([]);

  useEffect(() => {
    const getForms = async () => {
      const healthCollection = await db.collection("Health Insurance").get();
      const dentalCollection = await db.collection("Dental Insurance").get();
      const visionCollection = await db.collection("Vision Insurance").get();
      const retirementCollection = await db.collection("Retirement").get();
      const lifeCollection = await db.collection("Life Insurance").get();

      const returnHealth = healthCollection.docs.map((doc) => {
        return doc.data();
      });
      const returnDental = dentalCollection.docs.map((doc) => {
        return doc.data();
      });
      const returnVision = visionCollection.docs.map((doc) => {
        return doc.data();
      });
      const returnRetirement = retirementCollection.docs.map((doc) => {
        return doc.data();
      });
      const returnLife = lifeCollection.docs.map((doc) => {
        return doc.data();
      });

      setHealthForms(returnHealth);
      setDentalForms(returnDental);
      setVisionForms(returnVision);
      setRetirementForms(returnRetirement);
      setLifeForms(returnLife);
    };
    getForms();
  }, [store]);

  return (
    <div>
      <div className="m-6">
        <DropdownButton
          id="dropdown-basic-button"
          title="Benefits Documents"
        >
          <div className="container">
            <DropdownButton
              title="Health"
              variant="white"
              className="text-left"
            >
              {healthForms.map((x) => {
                return (
                  <DropdownItem
                    target="_blank"
                    rel="noreferrer"
                    href={x.link}
                    key={x.name}
                  >
                    {x.name}
                  </DropdownItem>
                );
              })}
            </DropdownButton>

            <DropdownButton
              title="Dental"
              variant="white"
              className="text-left"
            >
              {dentalForms.map((x) => {
                return (
                  <DropdownItem
                    target="_blank"
                    rel="noreferrer"
                    href={x.link}
                    key={x.name}
                  >
                    {x.name}
                  </DropdownItem>
                );
              })}
            </DropdownButton>

            <DropdownButton
              title="Vision"
              variant="white"
              className="text-left"
            >
              {visionForms.map((x) => {
                return (
                  <DropdownItem
                    target="_blank"
                    rel="noreferrer"
                    href={x.link}
                    key={x.name}
                  >
                    {x.name}
                  </DropdownItem>
                );
              })}
            </DropdownButton>

            <DropdownButton
              title="Retirement"
              variant="white"
              className="text-left"
            >
              {retirementForms.map((x) => {
                return (
                  <DropdownItem
                    target="_blank"
                    rel="noreferrer"
                    href={x.link}
                    key={x.name}
                  >
                    {x.name}
                  </DropdownItem>
                );
              })}
            </DropdownButton>

            <DropdownButton title="Life" variant="white" className="text-left">
              {lifeForms.map((x) => {
                return (
                  <DropdownItem
                    target="_blank"
                    rel="noreferrer"
                    href={x.link}
                    key={x.name}
                  >
                    {x.name}
                  </DropdownItem>
                );
              })}
            </DropdownButton>
          </div>
        </DropdownButton>
      </div>

      {/* <div className="max-w-sm w-72 h-96 rounded overflow-hidden shadow-lg h-auto">
        <p>Links</p>
        <ul className="text-left">
          <li key="paychexPersonnel">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://myapps.paychex.com/"
              className="cursor-pointer"
            >
              Paychex - Payroll Personnel
            </a>
          </li>
          <li key="paychexLearning">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://00jd49w7ig8202wzc0uv-paychex.bridgeapp.com/learner/library"
              className="cursor-pointer"
            >
              Paychex - Learning and Development
            </a>
          </li>
          <li key="landsEnd">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://business.landsend.com/store/cervello/"
              className="cursor-pointer"
            >
              Land's End - Employee Merchandise
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
