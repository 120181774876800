import React from "react";
import dotenv from "dotenv";
dotenv.config();

export default function GoogleMap() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="Google Map"
          width="600-lg"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?q=535%20Philippe%20Parkway%20Safety%20Harbor%2C%20FL%2035695&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}

export function USMap() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="US Map"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJCzYy5IS16lQRQrfeQ5K5Oxw&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}

export function GermanyMap() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="Germany Map"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJa76xwh5ymkcRW-WRjmtd6HU&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}

export function GermanyHQ() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="Germany HQ"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?q=place_id:EiRBbHN0ZXJ3aWVzZW4sIDIyMzk3IEhhbWJ1cmcsIEdlcm1hbnkiLiosChQKEgmD8T2XyiCyRxEhv67m5tcOORIUChIJuRMYfoNhsUcRoDrWe_I9JgQ&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}

export function KuwaitMap() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="Kuwait Office"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJfYIuKnP7zj8RfEaDdpP3wC4&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}

export function AustraliaMap() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="Australia Map"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/directions?origin=place_id:ChIJW0ODR7xiDWsRbRt-V1qepQ8&destination=place_id:ChIJPwRZn_ygwCwRIHwkKqgXAgM&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}

export function MelbourneHQ() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="Melbourne HQ"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJMwUuXWpd1moR8mKE6iX8Tf8&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}

export function DarwinHQ() {
  return (
    <div>
      <div className="flex">
        <iframe
          title="Darwin HQ"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?q=place_id:EipQcm9ncmVzcyBEciwgTmlnaHRjbGlmZiBOVCAwODEwLCBBdXN0cmFsaWEiLiosChQKEgnPASfAEZPALBH4PlfSAc-CjhIUChIJK0v-MheTwCwRIIEkKqgXAgU&key=" +
            process.env.REACT_APP_GOOGLE_MAP_API
          }
        ></iframe>
      </div>
    </div>
  );
}
