import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BrowserRouter as Route, NavLink } from "react-router-dom";

export default function PubNav() {
  return (
    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
      <li key="Internal-Reference" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Internal-Reference">
            <NavLink
              to="/Internal-Reference"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Library</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
      <li key="Internal-Managers" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Internal-Managers">
            <NavLink
              to="/Internal-Managers"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Managers Portal</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
      <NavDropdown
        className=" flex items-center text-sm uppercase font-bold no-underline"
        title="News"
        id="nav-dropdown"
      >
        <NavDropdown.Item href={`/NewsManage`} eventKey="4.1">
          Create a News Post
        </NavDropdown.Item>
        <NavDropdown.Item href={`/NewsManage-Edit`} eventKey="4.2">
          Edit a News Post
        </NavDropdown.Item>
      </NavDropdown>
    </ul>
  );
}
