import missionCommandStatement from "./statementFiles/mission-command-statement.pdf";
import image from "./statementFiles/mission-command-logo.png";
export default function MissionCommandStatement() {
    return (
      <div className="mx-auto text-black -mt-32">
        <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
          <div className="blueOne py-8">
            <div className="text-center uppercase text-white text-4xl">
              Mission Command & Control Capability Statement
            </div>
          </div>
          <div className="container md:pt-10 row mx-auto sm:mt-5">
            <div className="col-md-6 md:pt-20 mx-auto">
              <div className="text-xl">
                Thank you for your interest in our work. <br /> Click the Download
                button below to download a pdf copy of our Hypersonics Capability Statement.
              </div>
              <a
                className="text-white font-bold no-underline"
                href={missionCommandStatement}
                rel="noreferrer"
                target="_blank"
                download="cervello-mission-command-control-statement.pdf"
              >
                <button className="border-black blueOne text-white font-bold py-2 px-4 my-6 rounded">
                  Download
                </button>
              </a>
            </div>
            <div className="col-md-6">
              <img src={image} alt="mission command logo" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  