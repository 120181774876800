import React from "react";
import MissionCommandLogo from "./MissionCommandLogo.png";

export default function MissionCommand() {
  return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 h-auto'>
				<h1 className='font-black text-3xl text-black'>
					Mission Command and Control{' '}
				</h1>

				<img
					src={MissionCommandLogo}
					className='content-center h-72 w-auto mx-auto'
					alt="Mission Command Logo"
				/>

				<div className='md:container md:mx-auto md:px-28'>
					<p>
						The Mission Command & Control (MC2) Division provides systems
						analysis, engineering, integration, and architecture Subject Matter
						Expert (SME) support for the DOD including the Office of the
						Secretary of Defense (OSD), Services, and US Special Operations
						Command (USSOCOM).
					</p>

					<p>
						The MC2 Division also provides a Joint Interface Control Officer
						(JICO)/Tactical Data Link (TDL) expertise in support of the OSD,
						Services, and USSOCOM ensuring interoperability at the strategic,
						operational, and tactical levels in support of the DOD’s Joint
						All-Domain C2 (JADC2) strategy. The MC2 Division SMEs have
						additional expertise in supporting the organizations navigating the
						DOD’s process for development, testing, and joint interoperable
						certification of C2 and non-C2 systems.
					</p>

					<p>
						MC2 works at the cutting edge of DOD’s Command and Control programs
						to support Warfighters in the near, mid, and far term using
						coordinated test events both in the lab and in live events that
						include data collection and analysis. MC2 SMEs employ Cervello’s
						patented test methodology to ensure the best equipment, knowledge,
						and tactics, techniques, and procedures (TTPs) are in place to help
						the DOD fight and win.
					</p>
				</div>
			</div>
		</div>
	);
}
