import Nav from "react-bootstrap/Nav";
import { BrowserRouter as Route, NavLink } from "react-router-dom";

export default function EmployeeNav() {
  return (
    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
      <li key="Internal-Reference" className="nav-item">
        <Nav.Item>
          <Nav.Link eventKey="/Internal-Reference">
            <NavLink
              to="/Internal-Reference"
              className="md:px-3 no-underline py-3 flex items-center text-sm uppercase font-bold leading-snug"
            >
              <span className={"ml-2"}>Library</span>
            </NavLink>
          </Nav.Link>
        </Nav.Item>
      </li>
    </ul>
  );
}
