import { React, useEffect, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Blog } from "../models";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

export default function Tech() {
  return (
    <div className="mx-auto">
      <div className="mb-6 bg-white border-2 border-opacity-300 border-solid shadow-inner-2xl shadow-2xl">
        <div className="text-2xl font-bold text-blue-900 mt-12 mb-12 text-center ">
          Tech Management
        </div>
        <Container>
          <Row xs={1} md={2} className="pt-8">
            <Col>
              <div className="text-blue-900 font-bold text-xl">
                Onboarding Tables
              </div>
            </Col>
            <Col>
              <div className="text-blue-900 font-bold text-xl">Data</div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
