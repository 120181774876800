import { React, useEffect, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Blog } from "../models";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

export default function NewsManage() {
  const [newsList, setNewsList] = useState([]);
  const [oneStory, setOneStory] = useState("");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [dbItem, setDbItem] = useState([]);
  const [fieldChanges, setFieldChanges] = useState("");
  const [changeField, setChangeField] = useState("");
  const [dbTag, setDbTag] = useState();
  const [newContent, setNewContent] = useState([]);
  const [newTags, setNewTags] = useState([]);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleCloseContent = () => setShowContent(false);
  const handleShowContent = () => setShowContent(true);

  const handleCloseTags = () => setShowTags(false);
  const handleShowTags = () => setShowTags(true);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    let selectedField = e.target.id;
    setChangeField(selectedField);
    if (selectedField === "Title") {
      setDbItem(oneStory.title);
      setDbTag("title");
    } else if (selectedField === "Image URL") {
      setDbItem(oneStory.image);
      setDbTag("image");
    } else if (selectedField === "Blurb") {
      setDbItem(oneStory.blurb);
      setDbTag("blurb");
    } else if (selectedField === "Author Name") {
      setDbItem(oneStory.author);
      setDbTag("author");
    } else if (selectedField === "Author Bio") {
      setDbItem(oneStory.authorBio);
      setDbTag("authorBio");
    } else if (selectedField === "Author Image URL") {
      setDbItem(oneStory.authorPic);
      setDbTag("authorPic");
    } else {
      console.log("That didn't work...");
    }
    setShow(true);
  };

  const change = (event) => {
    setFieldChanges(event.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    let identify = oneStory.id;
    const queryOne = async () => {
      try {
        const original = await DataStore.query(Blog, (c) =>
          c.id("eq", identify)
        );
        const manipulateOriginal = original[0];
        if (!fieldChanges) {
          return;
        } else {
          await DataStore.save(
            Blog.copyOf(manipulateOriginal, (updated) => {
              updated[dbTag] = fieldChanges;
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    queryOne();
    handleClose();
  };
  const handleContent = (e) => {
    const value = e.target.value.replace(/\r\n/g, "\n").split("\n");
    const filtered = value.filter((x) => {
      return x !== "";
    });
    setNewContent(filtered);
  };

  const handleTags = (e) => {
    const userTags = e.target.value
      .toLowerCase()
      .replace(/\r\n/g, "\n")
      .split(", ");
    const filteredTags = userTags.filter((y) => {
      return y !== "";
    });
    setNewTags(filteredTags);
  };

  const submitContent = async (e) => {
    e.preventDefault();
    let identify = oneStory.id;
    console.log(newContent);
    try {
      const originalContent = await DataStore.query(Blog, (c) =>
        c.id("eq", identify)
      );
      const manipulateContent = originalContent[0];
      if (!newContent) {
        return;
      } else {
        await DataStore.save(
          Blog.copyOf(manipulateContent, (updated) => {
            updated.content = newContent;
          })
        );
      }
      handleCloseContent();
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const submitTags = async (e) => {
    e.preventDefault();
    let identify = oneStory.id;
    try {
      const originalTags = await DataStore.query(Blog, (c) =>
        c.id("eq", identify)
      );
      const manipulateTags = originalTags[0];
      if (!newTags) {
        return;
      } else {
        await DataStore.save(
          Blog.copyOf(manipulateTags, (updated) => {
            updated.tags = newTags;
          })
        );
      }
    } catch (error) {
      console.log(error);
      return;
    }
    handleCloseTags();
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    let storyId = oneStory.id;
    const toDelete = await DataStore.query(Blog, storyId);
    DataStore.delete(toDelete);
    handleCloseDelete();
  };

  useEffect(() => {
    const justQuery = async (e) => {
      try {
        const query = await DataStore.query(Blog);
        setNewsList(query);
      } catch (error) {
        console.log(error);
        return;
      }
    };
    justQuery();
  });

  const pickStory = (e) => {
    let storyId = e.target.value;
    const queryStory = async () => {
      try {
        const tryStory = await DataStore.query(Blog, (c) =>
          c.id("eq", storyId)
        );
        setOneStory(tryStory[0]);
      } catch (error) {
        console.log(error);
      }
    };
    queryStory();
  };

  return (
    <div className="mx-auto">
      <div className="mb-6 pb-24 bg-white border-2 border-opacity-300 border-solid shadow-inner-2xl shadow-2xl">
        <div className="text-2xl font-bold text-blue-900 mt-12 mb-12 text-center ">
          Edit a News Post
        </div>
        <Container>
          <Row xs={1} md={2} className="pt-8">
            <Col>
              <div className="text-blue-900 font-bold text-xl">
                Active News Posts
              </div>
              <div className="h-80 border-2 rounded border-primary overflow-auto">
                {newsList.map((x, i) => {
                  return (
                    <div key={i} className="hover:bg-blue-900 hover:text-white">
                      <button onClick={pickStory} value={x.id}>
                        {x.title}
                      </button>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col>
              <div className="text-blue-900 font-bold text-xl">
                News Post Data
              </div>
              {oneStory === "" ? (
                <div>Select a story</div>
              ) : (
                <div className="align-center text-left">
                  <Card className="h-80 border-2 border-primary">
                    <Card.Body>
                      <Card.Title className="text-left text-blue-900">
                        {oneStory.title}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted text-left">
                        {oneStory.author}
                      </Card.Subtitle>
                      <div>
                        <span className="font-bold text-blue-900">Blurb: </span>
                        {oneStory.blurb}
                      </div>
                      <div>
                        <span className="font-bold text-blue-900">
                          Created:{" "}
                        </span>{" "}
                        {new Date(oneStory.createdAt).toDateString()}
                      </div>
                      <div>
                        <span className="font-bold text-blue-900">
                          Last Updated:{" "}
                        </span>{" "}
                        {new Date(oneStory.updatedAt).toDateString()}
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          Edit Story
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item id="Title" onClick={handleShow}>
                            Edit Title
                          </Dropdown.Item>
                          <Dropdown.Item id="Image URL" onClick={handleShow}>
                            Edit Image URL
                          </Dropdown.Item>
                          <Dropdown.Item id="Blurb" onClick={handleShow}>
                            Edit Blurb
                          </Dropdown.Item>
                          <Dropdown.Item id="Author Name" onClick={handleShow}>
                            Edit Author Name
                          </Dropdown.Item>
                          <Dropdown.Item id="Author Bio" onClick={handleShow}>
                            Edit Author Bio
                          </Dropdown.Item>
                          <Dropdown.Item
                            id="Author Image URL"
                            onClick={handleShow}
                          >
                            Edit Author Image URL
                          </Dropdown.Item>
                          <Dropdown.Item
                            id="Content"
                            onClick={handleShowContent}
                          >
                            Edit Content
                          </Dropdown.Item>
                          <Dropdown.Item id="Tags" onClick={handleShowTags}>
                            Edit Story Tags
                          </Dropdown.Item>
                          <Dropdown.Item id="Delete" onClick={handleShowDelete}>
                            Delete Story
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Card.Footer>
                  </Card>
                </div>
              )}
            </Col>
          </Row>
        </Container>

        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Currently editing: {changeField}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="font-bold text-blue-900">
                Current {changeField}:
              </div>
              <div>{dbItem}</div>
              <Form onSubmit={submitForm}>
                <Form.Group className="my-3">
                  <Form.Label className="font-bold text-blue-900">
                    Enter new {changeField}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={dbItem}
                    onChange={change}
                  ></Form.Control>
                </Form.Group>
                <Button
                  variant="secondary"
                  className="mr-3"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </>

        <>
          <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title className="text-blue-900">Delete Story</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span className="text-red-500">
                <span className="font-bold">WARNING:</span> You are about to
                delete this story from the database. This action is
                irreversible. Deleting the story will remove it from the
                database and the live website. Proceed with caution.
              </span>
              <Form className="pt-3" onSubmit={handleDeleteSubmit}>
                <Button
                  className="mr-3"
                  variant="secondary"
                  onClick={handleCloseDelete}
                >
                  Cancel
                </Button>
                <Button variant="danger" type="submit">
                  Delete Story
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </>

        <>
          <Modal show={showContent} onHide={handleShowContent}>
            <Modal.Header closeButton>
              <Modal.Title className="text-blue-900">
                Edit Story Content
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={submitContent}>
                <Form.Group className="mb-3 text-left">
                  <Form.Label>Enter new story content</Form.Label>
                  <Form.Control
                    name="content"
                    required
                    type="text"
                    as="textarea"
                    onChange={handleContent}
                  />
                </Form.Group>
                <Button
                  className="mr-3"
                  variant="secondary"
                  onClick={handleCloseContent}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </>

        <>
          <Modal show={showTags} onHide={handleShowTags}>
            <Modal.Header closeButton>
              <Modal.Title className="text-blue-900">
                Edit Story Tags
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="pb-4 font-bold text-red-500">
                IMPORTANT: Separate each tag with a comma
              </div>
              <Form onSubmit={submitTags}>
                <Form.Group className="mb-3 text-left">
                  <Form.Label>Enter new story tags</Form.Label>
                  <Form.Control
                    name="content"
                    required
                    type="text"
                    onChange={handleTags}
                    placeholder="first tag, second tag, third tag"
                  />
                </Form.Group>
                <Button
                  className="mr-3"
                  variant="secondary"
                  onClick={handleCloseTags}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </div>
  );
}
